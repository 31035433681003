import assets from "assets";
import { ChainType } from "types";

export function getChainTypeLogo(chainType: ChainType) {
    switch (chainType) {
        case ChainType.polygon:
            return assets.logos.polygonLogoIcon;
        case ChainType.sol:
            return assets.logos.solanaLogo;
    }
}
