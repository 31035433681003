import { RowBetween } from "components/Row";
import { Button } from "rebass";
import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  z-index: 40;
`;
export const Wrapper = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius.button};
  border: 1px solid ${({ theme }) => theme.borderToggleBtn};
  background-color: ${({ theme }) => theme.bgTop};
  filter: drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.05));
  :hover {
    cursor: pointer;
    opacity: 0.7;
  }
  height: fit-content;
  ${({ theme }) => theme.mediaWidth.upToMedium`
  `}
`;

export const LogoWrapper = styled.img`
  width: 20px;
  height: 20px;
`;
export const FlyOut = styled.div`
  background: ${({ theme }) => theme.bgModal};
  border: 0.5px solid ${({ theme }) => theme.border};
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
    0px 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  position: absolute;
  top: 50px;
  left: 0;
  z-index: 100;
  padding: 10px 12px;
  width: 210px;
  max-height: 410px;
  overflow: auto;
  ${({ theme }) => theme.mediaWidth.upToMedium`
     max-height: 280px;
     overflow:auto;
  `}
`;
export const Row = styled(RowBetween) <{ active?: boolean; disabled?: boolean }>`
  padding: 6px 0px;
  border-radius: 8px;
  opacity: ${({ disabled }) => (disabled ? "0.5" : 1)};
  :hover {
    cursor: ${({ disabled }) => (disabled ? "initial" : "pointer")};
    opacity: ${({ disabled }) => (disabled ? 0.5 : 0.7)};
  }
`;

export const VerticalDivider = styled.div`
  height: 40px;
  width: 12px;
  border-right: 1px solid ${({ theme }) => theme.borderToggleBtn};
  ${({ theme }) => theme.mediaWidth.upToMedium`
   height: 32px;
  `}
`;

export const StyledDropdownIcon = styled.img`
  padding: 0.8rem 0.6rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
   padding: 0.4rem 0.3rem;
  `}
`;
export const MenuWrapper = styled.div`
  width: 100%;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  padding: 10px 0px 10px 16px;
`;

export const StyledLabel = styled.label`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const StyledSelectedMainOption = styled(Button) <{ selected: boolean }>`
  width: 100%;
  padding: 10px 10px !important;
  background-color: ${({ theme, selected }) =>
    selected ? theme.secondary : "transparent"};
  border: 1px solid
    ${({ theme, selected }) => (selected ? theme.border : "transparent")};
  border-radius: 12px;
`;
