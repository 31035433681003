import { useQuery } from "@apollo/client";
import { totalNFTMintClient, totalNFTVolumeAndSales } from "apollo/client";
import gql from "graphql-tag";
import { useMemo } from "react";
import { notEmpty } from "utils";

export const QUERY_TOTAL_NFT_VOLUME_SALES = gql`
  query records {
    records {
      date_time
      project
      sales
      volume_usd
      volume_in_matic
      volume_in_weth
    }
  }
`;

interface TotalNFTVolumeAndSalesResponse {
  records: {
    date_time: string;
    project: string;
    sales: number;
    volume_usd: number;
    volume_in_matic: number;
    volume_in_weth: number;
  }[];
}

/**
 * Fetch total and daily NFT mint
 */

export function useTotalNFTVolumeAndSales(): {
  loading: boolean;
  error: boolean;
  records:
    | {
        dateTime: string;
        project: string;
        salesCount: number;
        volumeUSD: number;
        volumeInMatic: number;
        volumeInWETH: number;
      }[]
    | undefined;
} {
  const { loading, error, data } = useQuery<TotalNFTVolumeAndSalesResponse>(
    QUERY_TOTAL_NFT_VOLUME_SALES,
    {
      client: totalNFTVolumeAndSales,
      fetchPolicy: "cache-first",
    }
  );

  const formattedData = useMemo(() => {
    if (data) {
      return data.records
        .map((p) => {
          return {
            dateTime: p.date_time,
            project: p.project,
            salesCount: p.sales,
            volumeUSD: p.volume_usd,
            volumeInMatic: p.volume_in_matic,
            volumeInWETH: p.volume_in_weth,
          };
        })
        .filter(notEmpty);
    } else {
      return undefined;
    }
  }, [data]);

  return {
    loading: loading,
    error: Boolean(error),
    records: formattedData,
  };
}
