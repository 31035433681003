import React from "react";
import { Text, TextProps } from "rebass";
import { Colors } from "./styled";
import styled from "styled-components";

const BaseTextStyle = styled(Text)<{ color: keyof Colors }>`
  color: ${({ color, theme }) => (theme as any)[color]};
  font-family: ${({ fontFamily }) => fontFamily ?? "inherit"};
`;

// white : 20px , 16px , 14px
// 36px
// #E9E9E9: 28px

export const TEXT = {
  Primary(props: TextProps) {
    return <BaseTextStyle fontWeight={500} color={`primary`} {...props} />;
  },
  Secondary(props: TextProps) {
    return <BaseTextStyle fontWeight={500} color={`secondary`} {...props} />;
  },

  Link(props: TextProps) {
    return <BaseTextStyle fontWeight={500} color={"aTag"} {...props} />;
  },

  Black(props: TextProps) {
    return <BaseTextStyle fontWeight={500} color={"black"} {...props} />;
  },
  White(props: TextProps) {
    return <BaseTextStyle fontWeight={500} color={"white"} {...props} />;
  },

  LargeHeader(props: TextProps) {
    return (
      <BaseTextStyle
        fontFamily="var(--ff-title)"
        fontWeight={700}
        fontSize={36}
        color={"header"}
        {...props}
      />
    );
  },
  MediumHeader(props: TextProps) {
    return (
      <BaseTextStyle
        fontFamily="var(--ff-subtitle)"
        fontWeight={600}
        fontSize={24}
        color={"subHeader"}
        {...props}
      />
    );
  },

  SmallHeader(props: TextProps) {
    return (
      <BaseTextStyle
        fontFamily="var(--ff-subtitle)"
        fontWeight={400}
        fontSize={18}
        color={"header"}
        {...props}
      />
    );
  },
  SubHeader(props: TextProps) {
    return (
      <BaseTextStyle
        fontWeight={400}
        fontSize={20}
        color={"subHeader"}
        {...props}
      />
    );
  },

  Body(props: TextProps) {
    return (
      <BaseTextStyle fontWeight={400} fontSize={16} color={"body"} {...props} />
    );
  },

  SmallBody(props: TextProps) {
    return (
      <BaseTextStyle fontWeight={400} fontSize={14} color={"body"} {...props} />
    );
  },
  SubBody(props: TextProps) {
    return (
      <BaseTextStyle
        fontWeight={400}
        fontSize={14}
        color={"subBody"}
        {...props}
      />
    );
  },

  Blue(props: TextProps) {
    return <BaseTextStyle fontWeight={500} color={"blue"} {...props} />;
  },
  Gray(props: TextProps) {
    return <BaseTextStyle fontWeight={500} color={"gray"} {...props} />;
  },

  Disabled(props: TextProps) {
    return <BaseTextStyle fontWeight={500} color={"disabled"} {...props} />;
  },
  Success(props: TextProps) {
    return <BaseTextStyle fontWeight={500} color={"success"} {...props} />;
  },
  Warning(props: TextProps) {
    return <BaseTextStyle fontWeight={500} color={"warning"} {...props} />;
  },

  Italic(props: TextProps) {
    return (
      <BaseTextStyle
        fontWeight={500}
        fontSize={12}
        fontStyle={"italic"}
        color={"body"}
        {...props}
      />
    );
  },

  Error({ error, ...props }: { error: boolean } & TextProps) {
    return (
      <BaseTextStyle
        fontWeight={500}
        color={error ? "warning" : "body"}
        {...props}
      />
    );
  },
};
