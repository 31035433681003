import styled from "styled-components";

const ContentCardCtr = styled.div`
  border: 1px solid ${({ theme }) => theme.disabled};
  ${({ theme }) => theme.flexColumnNoWrap}
  background: ${({ theme }) => theme.disabledVar2};
  position: relative;
  overflow: hidden;
  &::before {
    content: "";
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    opacity: 0;
    -webkit-transition: all 500ms ease-out;
    -moz-transition: all 500ms ease-out;
    -o-transition: all 500ms ease-out;
    transition: all 500ms ease-out;
    z-index: -1;
  }
  &:hover {
     background: ${({ theme }) => theme.activeRadialGradient};
  }
  &:hover::before {
    opacity: ${(props) => (props?.hideHover ? 0 : 1)};
  }
`;

export default ContentCardCtr;
