import assets from "assets";
import { SquareIconButton } from "components/Button";
import { HorizontalDivider } from "components/Divider/inde";
import {
    SnapshotBrandLogoImageWrapper,
    GenericImageWrapper,
} from "components/Logo";
import QuestionHelper from "components/QuestionHelper";
import Row, { RowBetween } from "components/Row";
import { ResponsiveGraphHeader } from "pages/MarketOverview";
import React from "react";
import TopHeaderDyanmicRightComponent from "./TopHeaderDynamicRightComponent";

export default function GraphTableTopHeader({
    title,
    isMobileView,
    tooltip,
    showBarGraph,
    setShowBarGraph,
    downloadScreenshot,
    snapshotBrandLogoId,
    snapshotDownloadIconId,
    tweetText,
}: {
    title: string;
    isMobileView: boolean;
    tooltip?: string;
    showBarGraph?: boolean;
    setShowBarGraph?: React.Dispatch<React.SetStateAction<boolean>>;
    downloadScreenshot?: () => void;
    snapshotBrandLogoId?: string;
    snapshotDownloadIconId?: string;
    tweetText?: string;
}) {
    return (
        <>
            <TopHeaderDyanmicRightComponent
                title={title}
                tooltip={tooltip}
                rightComponent={
                    <>
                        {snapshotBrandLogoId ? (
                            <SnapshotBrandLogoImageWrapper
                                id={snapshotBrandLogoId}
                                src={assets.logos.surfaceboardXYZLogo}
                            />
                        ) : (
                            <></>
                        )}
                        <Row
                            id={snapshotDownloadIconId}
                            gap="12px"
                            justify={"right"}
                        >
                            {showBarGraph !== undefined && setShowBarGraph ? (
                                <SquareIconButton
                                    onClick={() => setShowBarGraph(!showBarGraph)}
                                    active={true}
                                >
                                    <GenericImageWrapper
                                        src={
                                            showBarGraph
                                                ? assets.icons.bargraphIcon
                                                : assets.icons.linegraphIcon
                                        }
                                        size={isMobileView ? "12px" : "15px"}
                                    />
                                </SquareIconButton>
                            ) : (
                                <></>
                            )}
                            {downloadScreenshot ? (
                                <SquareIconButton onClick={() => downloadScreenshot()}>
                                    <GenericImageWrapper
                                        src={assets.icons.downloadIcon}
                                        size={isMobileView ? "12px" : "15px"}
                                    />
                                </SquareIconButton>
                            ) : (
                                <></>
                            )}
                            {tweetText ? (
                                <SquareIconButton onClick={() => window.open(tweetText)}>
                                    <GenericImageWrapper
                                        src={assets.logos.twitterLogo}
                                        size={isMobileView ? "12px" : "15px"}
                                    />
                                </SquareIconButton>
                            ) : (
                                <></>
                            )}
                        </Row>
                    </>
                } />
            <HorizontalDivider />
        </>

    );
}