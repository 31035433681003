import { SmallOptionButton } from "components/Button";
import { GenericImageWrapper } from "components/Logo";
import Row from "components/Row";
import { CurrencyList, TypeCurrency } from "constant/currency";
import useMatchBreakpoints from "hooks/useMatchBreakpoints";
import { ListButtonWrapper } from "pages/MarketOverview/styled";
import React from "react";
import { useCurrencyUnit } from "store/user/hooks";
import { TEXT } from "theme/texts";

function CurrencyMenu() {
  const { currency, setCurrencyUnit } = useCurrencyUnit();
  const { isMobile } = useMatchBreakpoints();

  const handleChange = (option: TypeCurrency) => {
    setCurrencyUnit(option.id);
  };

  return (
    <div>
      <ListButtonWrapper withoutDivider={true} style={{ overflow: "hidden" }}>
        {CurrencyList.map((option, index) => {
          return (
            <SmallOptionButton
              key={index}
              active={option.id === currency.id}
              onClick={() => handleChange(option)}
              style={{
                height: "auto",
                borderRadius: ".15rem",
                padding: ".5rem .75rem",
              }}
            >
              <Row gap="5px">
                <GenericImageWrapper src={option.logoIcon} size={".7rem"} />
                <TEXT.Body
                  fontWeight={600}
                  fontSize={".7rem"}
                  style={isMobile ? { display: "none" } : {}}
                >
                  {option.symbol}
                </TEXT.Body>
              </Row>
            </SmallOptionButton>
          );
        })}
      </ListButtonWrapper>
    </div>
  );
}

export default CurrencyMenu;
