import { useQuery } from '@apollo/client'
import { topTrendingCategoriesClient } from 'apollo/client'
import gql from 'graphql-tag'
import { useMemo } from 'react'
import { notEmpty } from 'utils'

export const QUERY_TOTAL_NFT_WALLETS = gql`
  query {
    records { trending_rank, date_time,category,sale_count,grain}
  }
  
`

interface TopTrendingCategories {
    records: {
        trending_rank: number
        date_time: string
        category: string
        sale_count: number
        grain: number
    }[]
}

/**
 * Fetch daily NFT buyer
 */

export function useTopTrendingCategories(): {
    loading: boolean
    error: boolean,
    records: { trending_rank: number
        date_time: string
        category: string
        sale_count: number
        grain: number }[] | undefined
} {
    const { loading, error, data } = useQuery<TopTrendingCategories>(QUERY_TOTAL_NFT_WALLETS, {
        client: topTrendingCategoriesClient,
        fetchPolicy: 'cache-first',
    })
    const formattedData = useMemo(() => {
        if (data) {
            return data.records
                .map((p) => {
                    return {
                        trending_rank: p.trending_rank,
                        date_time: p.date_time,
                        category: p.category,
                        sale_count: p.sale_count,
                        grain: p.grain
                    }
                })
                .filter(notEmpty)
        } else {
            return undefined
        }
    }, [data])

    return {
        loading: loading,
        error: Boolean(error),
        records: formattedData,
    }
}
