import { useQuery } from '@apollo/client'
import { y00tsActivity } from 'apollo/client'
import gql from 'graphql-tag'
import { useMemo } from 'react'
import { notEmpty } from 'utils'

export const QUERY_Y00TS_STATS = gql`
  query records {
    records {
        activity_count,
  data_creation_date
    }
  }
`

interface y00tsStatsData {
    records: {
        activity_count: Number,
    data_creation_date: String,
    }[]
}

/**
 * Fetch matic current price
 */

export function useZettaActivityCount(): {
    loading: boolean
    error: boolean,
    records: {
        activity_count: Number,
        data_creation_date: String,
    }[] | undefined
} {
    const { loading, error, data } = useQuery<y00tsStatsData>(QUERY_Y00TS_STATS, {
        client: y00tsActivity,
        fetchPolicy: 'cache-first',
    })
    const formattedData = useMemo(() => {
        if (data) {
            return data.records
                .map((p) => {
                    return {
                        activity_count: p.activity_count,
                        data_creation_date: p.data_creation_date,
                    }
                })
                .filter(notEmpty)
        } else {
            return undefined
        }
    }, [data])

    return {
        loading: loading,
        error: Boolean(error),
        records: formattedData,
    }
}
