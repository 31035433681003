import React from "react";
import styled from "styled-components";
import { formatAmount } from "utils/numbers";
import { TEXT } from "../../theme/texts";

const Wrapper = styled(TEXT.MediumHeader) <{
  fontWeight: number;
  fontSize: string;
  negative: boolean;
  neutral: boolean;
  transparentBorder?: boolean;
  noDecoration?: boolean;
}>`
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  color: ${({ theme, negative }) => (negative ? theme.warning : theme.success)};
  background: ${({ theme, noDecoration }) => noDecoration ? "transparent" : theme.bgTransparent01};
  width: fit-content;
  height: fit-content;
  padding: 4px 8px;
  border-radius: ${({ theme }) => theme.borderRadius.card};
  border: 0.6px solid
    ${({ theme, transparentBorder, negative, noDecoration }) =>
    noDecoration ? "transparent" :
      transparentBorder
        ? "#30215D"
        : negative
          ? theme.warning
          : theme.borderSuccess};
  backdrop-filter: blur(2px);
`;

export interface Props {
  value: number | undefined;
  decimals?: number;
  fontSize?: string;
  fontWeight?: number;
  wrap?: boolean;
  simple?: boolean;
  formatFig?: boolean;
  transparentBorder?: boolean;
  upDownIcon?: boolean;
  noDecoration?: boolean;
}

export default function GainLossPercentComponent({
  value,
  decimals = 2,
  fontSize = "16px",
  fontWeight = 500,
  wrap = false,
  simple = false,
  formatFig = false,
  transparentBorder = false,
  upDownIcon = true,
  noDecoration = false,
  ...rest
}: Props) {
  if (value === undefined || value === null) {
    return (
      <TEXT.SmallHeader fontWeight={fontWeight} fontSize={fontSize}>
        -
      </TEXT.SmallHeader>
    );
  }

  const truncated = parseFloat(value.toFixed(decimals));

  if (simple) {
    return (
      <Wrapper
        {...rest}
        fontWeight={fontWeight}
        fontSize={fontSize}
        negative={false}
        neutral={true}
      >
        {formatFig ? formatAmount(value) : Math.abs(value).toFixed(decimals)}%
      </Wrapper>
    );
  }

  return (
    <Wrapper
      {...rest}
      fontWeight={fontWeight}
      fontSize={fontSize}
      negative={truncated < 0}
      neutral={truncated === 0}
      transparentBorder={transparentBorder}
      noDecoration={noDecoration}
    >
      {wrap && "("}
      {truncated < 0 && (upDownIcon ? "↓" : "+")}
      {truncated > 0 && (upDownIcon ? "↑" : "-")}&nbsp;
      {formatFig ? formatAmount(value) : Math.abs(value).toFixed(decimals)}%
      {wrap && ")"}
    </Wrapper>
  );
}
