import { useQuery } from '@apollo/client'
import { totalNFTBuyerClient, totalDailyNFTSellerClient } from 'apollo/client'
import gql from 'graphql-tag'
import { useMemo } from 'react'
import { notEmpty } from 'utils'

export const QUERY_TOTAL_NFT_SELLER = gql`
  query records { 
    records {
        date_time
    nft_sellers_count
     cumulative_nft_sellers_count
    }
  }
  
`

interface TOTALNFTSellerResponse {
    records: {
        date_time: string
        nft_sellers_count: number
        cumulative_nft_sellers_count: number
    }[]
}

/**
 * Fetch daily NFT buyer
 */

export function useTotalNFTSeller(): {
    loading: boolean
    error: boolean,
    records: { dateTime: string, nftSellerCount: number, cumulativeNFTSellerCount: number }[] | undefined
} {
    const { loading, error, data } = useQuery<TOTALNFTSellerResponse>(QUERY_TOTAL_NFT_SELLER, {
        client: totalDailyNFTSellerClient,
        fetchPolicy: 'cache-first',
    })
    const formattedData = useMemo(() => {
        if (data) {
            return data.records
                .map((p) => {
                    return {
                        dateTime: p.date_time,
                        nftSellerCount: p.nft_sellers_count,
                        cumulativeNFTSellerCount: p.cumulative_nft_sellers_count
                    }
                })
                .filter(notEmpty)
        } else {
            return undefined
        }
    }, [data])

    return {
        loading: loading,
        error: Boolean(error),
        records: formattedData,
    }
}
