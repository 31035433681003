import axios from "axios";
import { useMemo, useState } from "react";



interface api {
    loading: boolean;
    error: boolean;
    data: any;
}

const getData = async () => {
    try {
        const { data } = await axios.get(
          "https://nftsurfaceboard.up.railway.app/api/aggregations/nfts/nftDistinctOwners?collectionId=9b4d3907d615294c5d4fdc4edf1e9b1e&timeRangeFilter=1-week"
        )
        return data;
    }
    catch (error) {
        return error;
    }
}

export function useUniqueWallets() {
    const [api, setAPIData] = useState<api>({
        loading: true,
        error: false,
        data: [] || undefined
    });



    useMemo(async () => {
        const data = await getData();
        setAPIData({
            loading: false,
            error: false,
            data: data
        })
    }, [])
    return api;
}
