import { useQuery } from '@apollo/client'
import { y00tsBriding } from 'apollo/client'
import gql from 'graphql-tag'
import { useMemo } from 'react'
import { notEmpty } from 'utils'

export const QUERY_Y00TS_STATS = gql`
  query records {
    records(orderBy: date_time, orderDirection:desc) {
        date_time,
        num_transactions,
        total_transactions
    }
  }
`

interface y00tsStatsData {
    records: {
        date_time: String,
        num_transactions: Number,
        total_transactions: Number,
    }[]
}

/**
 * Fetch matic current price
 */

export function useZettaBridging(): {
    loading: boolean
    error: boolean,
    records: {
        date_time: String,
        num_transactions: Number,
        total_transactions: Number,
    }[] | undefined
} {
    const { loading, error, data } = useQuery<y00tsStatsData>(QUERY_Y00TS_STATS, {
        client: y00tsBriding,
        fetchPolicy: 'cache-first',
    })
    const formattedData = useMemo(() => {
        if (data) {
            return data.records
                .map((p) => {
                    return {
                        date_time: p.date_time,
                        num_transactions: p.num_transactions,
                        total_transactions: p.total_transactions,
                    }
                })
                .filter(notEmpty)
        } else {
            return undefined
        }
    }, [data])

    return {
        loading: loading,
        error: Boolean(error),
        records: formattedData,
    }
}
