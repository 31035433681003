import { DefaultTheme, useTheme } from "styled-components";
import { ChartTooltipWrapper } from "./styled";
import { TEXT } from "theme/texts";
import { getOnlyDate } from "utils/date";
import dayjs from "dayjs";
import { formatAmount } from "utils/numbers";
import {
  JSXElementConstructor,
  ReactElement,
  ReactFragment,
  ReactPortal,
} from "react";
import Row, { AutoRow } from "components/Row";
import { Rectangle } from "recharts";

export const ChartCustomTooltip = ({
  active,
  payload,
  label,
}: {
  active: boolean | undefined;
  payload: any;
  label: string;
}) => {

  const theme = useTheme();

  if (active && payload && payload.length) {
    const formattedTime = 
    typeof label === "string" && label.match(/[a-zA-Z]/) ? label :
    (label ?? dayjs(label).format("MMM D, YYYY"))
    return (
      <ChartTooltipWrapper>
        <TEXT.SmallBody pb={10} fontSize={12} fontWeight={500}>{`${
          typeof formattedTime === "string" && formattedTime.match(/[a-zA-Z]/) ? formattedTime :
          getOnlyDate(formattedTime)}`}</TEXT.SmallBody>
        {payload?.map((data: any) => {
          return (
            <TEXT.Primary pb={1} color={theme.primaryLight} fontSize={12} fontWeight={500}>
              {`${data?.name}:   ${formatAmount(
                data?.value,
                2
              ).toUpperCase()} `}
            </TEXT.Primary>
          );
        })}
      </ChartTooltipWrapper>
    );
  }

  return null;
};

export function StyledChartFillGradient({
  id,
  color,
  isGradient,
}: {
  id: string;
  color: string;
  isGradient?: boolean;
}): any {
  return (
    <defs>
      <linearGradient id={id} x1="0" y1="0" x2="0" y2="1" d="180deg">
        <stop offset="0%" stopColor={color} />
        <stop
          offset="100%"
          stopColor={isGradient ? "rgba(81, 61, 121, 0.25)" : color}
        />
      </linearGradient>
    </defs>
  );
}

export function StyledChartFillColor({
  id,
  color,
}: {
  id: string;
  color: string;
}): any {
  return (
    <defs>
      <linearGradient id={id} x1="0" y1="0" x2="0" y2="1" d="180deg">
        <stop offset="0%" stopColor={color} />
        <stop offset="100%" stopColor={color} />
      </linearGradient>
    </defs>
  );
}

export const ChartRenderLegend = ({
  payload,
  isMobile,
}: {
  payload: any;
  isMobile: boolean;
}) => {
  return (
    <AutoRow
      gap={isMobile ? "2px" : "8px"}
      style={{
        paddingTop: isMobile ? "20px" : "70px",
        paddingBottom: isMobile ? "6px" : "2px",
        paddingLeft: isMobile ? "2px" : "28px",
      }}
    >
      {payload.map(
        (
          entry: {
            color: any;
            value:
            | string
            | number
            | boolean
            | ReactElement<any, string | JSXElementConstructor<any>>
            | ReactFragment
            | ReactPortal
            | null
            | undefined;
          },
          index: any
        ) => (
          <Row
            width="fit-content"
            key={`item-${index}`}
            style={{
              paddingRight: "32px",
            }}
          >
            <div
              style={{
                display: "inline-block",
                width: isMobile ? "10px" : "12px",
                height: isMobile ? "10px" : "12px",
                marginRight: "8px",
                backgroundColor: entry.color,
                borderRadius: "50%",
              }}
            ></div>
            <TEXT.SmallBody fontSize={isMobile ? 10 : 12}>
              {entry.value}
            </TEXT.SmallBody>
          </Row>
        )
      )}
    </AutoRow>
  );
};


export const CustomCursor = (props: any) => {
  const { x, y, width, height, stroke } = props;
  const theme = useTheme();
  return <Rectangle fill={theme.graphAxisElements}
    x={x + (width / 2.3)} y={y} width={1} height={height} />;

  // // For dash line cursor
  //  <Rectangle fill="transparent"
  //   stroke={theme.graphAxisElements}
  //   strokeDasharray={12}
  //   strokeDashoffset={8.8}
  //   x={x + (width / 2.3)} y={y} width={0.1} height={height} />;
};