import { client } from "apollo/client";
import { POOL_HIDE } from "constant";
import { fetchPoolChartData } from "data/pools/chartData";
import { useFetchPoolsData } from "data/pools/poolData";
import { useTopPoolAddresses } from "data/pools/topPools";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "store";
// import { updatePoolChartData } from 'store/pools/actions'
import { PoolChartEntry, PoolData } from "store/pools/reducer";
import { ChartDayData } from "types";

/**
 * Calculates offset amount to avoid inaccurate USD data for global TVL.
 * @returns TVL value in USD
 */
export function useTVLOffset() {
  const { data } = useFetchPoolsData(POOL_HIDE);

  const tvlOffset = useMemo(() => {
    if (!data) return undefined;

    return Object.keys(data).reduce((accum: number, poolAddress) => {
      const poolData: PoolData = data[poolAddress];
      return accum + poolData.tvlUSD;
    }, 0);
  }, [data]);

  return tvlOffset;
}

/**
 * Fecthes and formats data for pools that result in incorrect USD TVL.
 *
 * Note: not used currently but useful for debugging.
 *
 * @returns Chart data by day for values to offset accurate USD.
 */
export function useDerivedOffsetTVLHistory() {
  const [chartData, setChartData] = useState<
    { [key: number]: ChartDayData } | undefined
  >(undefined);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    async function fetchAll() {
      // fetch all data for each pool
      const data = await POOL_HIDE.reduce(
        async (accumP: Promise<{ [key: number]: ChartDayData }>, address) => {
          const accum = await accumP;
          const { data } = await fetchPoolChartData(address, client);
          if (!data) return accum;
          // dispatch(updatePoolChartData({ poolAddress: address, chartData: data }))
          data.map((poolDayData: PoolChartEntry) => {
            const { date, totalValueLockedUSD, volumeUSD } = poolDayData;
            const roundedDate = date;
            if (!accum[roundedDate]) {
              accum[roundedDate] = {
                tvlUSD: 0,
                date: roundedDate,
                volumeUSD: 0,
              };
            }
            accum[roundedDate].tvlUSD =
              accum[roundedDate].tvlUSD + totalValueLockedUSD;
            accum[roundedDate].volumeUSD =
              accum[roundedDate].volumeUSD + volumeUSD;
          });
          return accum;
        },
        Promise.resolve({} as { [key: number]: ChartDayData })
      );

      // Format as array
      setChartData(data);
    }

    if (!chartData) {
      fetchAll();
    }
  }, [chartData, dispatch]);

  return chartData;
}

// # of pools to include in historical chart volume and TVL data
const POOL_COUNT_FOR_AGGREGATE = 20;

/**
 * Derives historical TVL data for top 50 pools.
 * @returns Chart data for aggregate TVL over time.
 */
export function useDerivedProtocolTVLHistory() {
  const { addresses } = useTopPoolAddresses();
  const dispatch = useDispatch<AppDispatch>();

  const [chartData, setChartData] = useState<ChartDayData[] | undefined>(
    undefined
  );

  useEffect(() => {
    async function fetchAll() {
      if (!addresses) {
        return;
      }
      // fetch all data for each pool
      const data = await addresses
        .slice(0, POOL_COUNT_FOR_AGGREGATE) // @TODO: must be replaced with aggregate with subgraph data fixed.
        .reduce(
          async (accumP: Promise<{ [key: number]: ChartDayData }>, address) => {
            const accum = await accumP;
            if (POOL_HIDE.includes(address)) {
              return accum;
            }
            const { data } = await fetchPoolChartData(address, client);

            if (!data) return accum;
            // dispatch(updatePoolChartData({ poolAddress: address, chartData: data }))
            data.map((poolDayData: PoolChartEntry) => {
              const { date, totalValueLockedUSD, volumeUSD } = poolDayData;
              const roundedDate = date;
              if (!accum[roundedDate]) {
                accum[roundedDate] = {
                  tvlUSD: 0,
                  date: roundedDate,
                  volumeUSD: 0,
                };
              }
              accum[roundedDate].tvlUSD =
                accum[roundedDate].tvlUSD + totalValueLockedUSD;
              accum[roundedDate].volumeUSD =
                accum[roundedDate].volumeUSD + volumeUSD;
            });
            return accum;
          },
          Promise.resolve({} as { [key: number]: ChartDayData })
        );

      // Format as array
      let updatedList = chartData && [...chartData];
      Object.values(data).map((value) => {
        updatedList?.push(value);
      });
      setChartData(updatedList);
    }

    if (!chartData) {
      fetchAll();
    }
  }, [addresses, chartData, client, dispatch]);

  return chartData;
}
