import React from "react";
import Skeleton from "react-loading-skeleton";
import styled from "styled-components";

const SKLTCollectionCard = ({ cards }) => {
  return Array(cards)
    .fill(0)
    .map((item, idx) => (
      <SKLTCollectionCardCtr key={idx}>
        <div className="_leftCol">
          <Skeleton
            style={{ borderRadius: ".5rem" }}
            width={"50px"}
            height={"50px"}
          />
          <Skeleton
            width={"100px"}
            count={2}
            style={{ marginBottom: ".5rem" }}
          />
        </div>
        <div className="_rightCol">
          <Skeleton />
        </div>
      </SKLTCollectionCardCtr>
    ));
};
const SKLTCollectionCardCtr = styled.div`
  display: flex;
  gap: 0.5rem;
  justify-content: space-between;
  ._leftCol {
    display: flex;
    gap: 0.5rem;
  }
  ._rightCol {
    width: 30%;
  }
`;

export default SKLTCollectionCard;
