import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

interface ScrollToTopProps {
  children: React.ReactNode;
  // Add any additional props here
}

function ScrollToTop(props: ScrollToTopProps) {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return <>{props.children}</>;
}

export default ScrollToTop;
