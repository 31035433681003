import styled from 'styled-components'

export const Break = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.bgBase};
  width: 100%;
`

export const FixedSpan = styled.span<{ width?: string | null }>`
  width: ${({ width }) => width ?? ''};
`

export const TabularNumsText = styled.span`
  font-variant-numeric: tabular-nums;
`
