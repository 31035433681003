import { toast } from "react-hot-toast";

export async function copyTextToClipboard(text: string) {
  if ("clipboard" in navigator) {
    toast.success("Copied to clipboard.");
    return await navigator.clipboard.writeText(text);
  } else {
    toast.success("Copied to clipboard.");
    return document.execCommand("copy", true, text);
  }
}
