import { createReducer } from "@reduxjs/toolkit";
import { addPoolsData, updatePoolsData } from "./actions";
import { Transaction } from "types";
import { currentTimestamp } from "constant";
import JSBI from "jsbi";

export interface PoolsStore {
  // analytics data from
  byAddress: {
    [address: string]: {
      data: PoolData | undefined;
      chartData: PoolChartEntry[] | undefined;
      transactions: Transaction[] | undefined;
      lastUpdated: number | undefined;
      tickData: PoolTickData | undefined;
    };
  };
}

export const initialState: PoolsStore = {
  byAddress: {},
};

export default createReducer(initialState, (builder) => {
  builder.addCase(
    addPoolsData,
    (state, { payload: { pools, poolAddresses } }) => {
      poolAddresses.map((address) => {
        if (!state.byAddress[address]) {
          return (state.byAddress[address] = {
            data: undefined,
            chartData: undefined,
            transactions: undefined,
            lastUpdated: undefined,
            tickData: undefined,
          });
        }
        return address;
      });
    }
  );
  builder.addCase(updatePoolsData, (state, { payload: { pools } }) => {
    pools.map(
      (poolData) =>
        (state.byAddress[poolData.address] = {
          ...state.byAddress[poolData.address],
          data: poolData,
          lastUpdated: currentTimestamp(),
        })
    );
  });
});

// Tick with fields parsed to JSBIs, and active liquidity computed.
export interface TickProcessed {
  liquidityGross: JSBI;
  liquidityNet: JSBI;
  tickIdx: number;
  liquidityActive: JSBI;
  price0: string;
  price1: string;
}
export interface PoolTickData {
  ticksProcessed: TickProcessed[];
  feeTier: string;
  tickSpacing: number;
  activeTickIdx: number;
}

export interface PoolData {
  // basic token info
  address: string;
  feeTier: number;

  token0: {
    name: string;
    symbol: string;
    address: string;
    decimals: number;
    derivedETH: number;
  };

  token1: {
    name: string;
    symbol: string;
    address: string;
    decimals: number;
    derivedETH: number;
  };

  // for tick math
  liquidity: number;
  sqrtPrice: number;
  tick: number;

  // volume
  volumeUSD: number;
  volumeUSDChange: number;
  volumeUSDWeek: number;

  // liquidity
  tvlUSD: number;
  tvlUSDChange: number;

  // prices
  token0Price: number;
  token1Price: number;

  // token amounts
  tvlToken0: number;
  tvlToken1: number;
}

export type PoolChartEntry = {
  date: number;
  volumeUSD: number;
  totalValueLockedUSD: number;
  feesUSD: number;
};
