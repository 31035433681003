import { useQuery } from '@apollo/client'
import { totalNFTBuyerClient } from 'apollo/client'
import gql from 'graphql-tag'
import { useMemo } from 'react'
import { notEmpty } from 'utils'

export const QUERY_TOTAL_NFT_BUYER = gql`
  query records { 
    records {
        date_time
    nft_buyers_count
     cumulative_nft_buyers_count
    }
  }
  
  
  `
interface TotalNFTBuyerResponse {
    records: {
        date_time: string
        nft_buyers_count: number
        cumulative_nft_buyers_count: number
    }[]
}

/**
 * Fetch daily NFT buyer
 */

export function useTotalNFTBuyer(): {
    loading: boolean
    error: boolean,
    records: { dateTime: string, nftBuyerCount: number, cumulativeNFTBuyerCount: number }[] | undefined
} {
    const { loading, error, data } = useQuery<TotalNFTBuyerResponse>(QUERY_TOTAL_NFT_BUYER, {
        client: totalNFTBuyerClient,
        fetchPolicy: 'cache-first',
    })
    const formattedData = useMemo(() => {
        if (data) {
            return data.records
                .map((p) => {
                    return {
                        dateTime: p.date_time,
                        nftBuyerCount: p.nft_buyers_count,
                        cumulativeNFTBuyerCount: p.cumulative_nft_buyers_count
                    }
                })
                .filter(notEmpty)
        } else {
            return undefined
        }
    }, [data])

    return {
        loading: loading,
        error: Boolean(error),
        records: formattedData,
    }
}
