import { SmallOptionButton } from "components/Button";
import BarChart from "components/Chart/BarChart";
import LineAreaChart from "components/Chart/LineAreaChart";
import { AutoColumn } from "components/Column";
import YearMonthPickerComponent from "components/DateTime/YearMonthPickerComponent";
import { HorizontalDivider } from "components/Divider/inde";
import GraphTableTopHeader from "components/HeaderComponent/GraphTableTopHeader";
import { RowBetween } from "components/Row";
import { TabularNumsText } from "components/shared";
import { useTotalNFTWallets } from "data/nft/totalNFTWallets";
import dayjs from "dayjs";
import useMatchBreakpoints from "hooks/useMatchBreakpoints";
import useTakeScreenshot from "hooks/useTakeSnapshot";
import useTheme from "hooks/useTheme";
import React, { useEffect, useMemo, useState } from "react";
import { TEXT } from "theme/texts";
import { GenericChartSeries, TimeWindow } from "types";
import getTimewindowFilteredData from "utils/chart";
import { formatAmount } from "utils/numbers";
import {
  appRoutes,
  getScrollToElementPath,
  marketOverviewPageElementIDs,
} from "utils/path";
import {
  ChartTopRightSectionWrapper,
  ChartWrapper,
  ListButtonWrapper,
} from "./styled";

const twitterShareScrollPath = getScrollToElementPath(
  appRoutes.marketOverview,
  marketOverviewPageElementIDs.nftWallets
);
const snapshotDownloadIconId = "snapshot-nft-wallets-download-icon";
const snapshotBrandLogoId = "snapshot-nft-wallets-brand-logo";

const TopRightComponent = ({
  timeWindow,
  setTimeWindow,
  showBarGraph,
  setShowBarGraph,
  isMobileView,
  downloadScreenshot,
  count,
  selectedCustomTime,
  onCalendarChange,
}: {
  timeWindow: TimeWindow;
  setTimeWindow: React.Dispatch<React.SetStateAction<TimeWindow>>;
  showBarGraph: boolean;
  setShowBarGraph: React.Dispatch<React.SetStateAction<boolean>>;
  isMobileView: boolean;
  downloadScreenshot: () => void;
  count: number;
  selectedCustomTime: { year: number; month: number } | undefined;
  onCalendarChange: (selectedYear: number, selectedMonth: number) => void;
}) => {
  return (
    <ChartTopRightSectionWrapper>
      <ListButtonWrapper>
        {[
          TimeWindow.threeMonth,
          TimeWindow.sixMonth,
          TimeWindow.oneYear,
          TimeWindow.all,
          TimeWindow.custom,
        ].map((option, index) => {
          return (
            <SmallOptionButton
              key={index}
              active={timeWindow === option}
              onClick={() => setTimeWindow(option)}
            >
              {option === "custom" ? (
                <YearMonthPickerComponent
                  year={
                    selectedCustomTime
                      ? selectedCustomTime.year
                      : dayjs().year()
                  }
                  month={
                    selectedCustomTime
                      ? selectedCustomTime.month
                      : dayjs().month()
                  }
                  onChange={onCalendarChange}
                  isMobileView={isMobileView}
                />
              ) : (
                option
              )}
            </SmallOptionButton>
          );
        })}
      </ListButtonWrapper>
    </ChartTopRightSectionWrapper>
  );
};

const TopLeftComponent = ({
  count,
  showBarGraph,
  setShowBarGraph,
  isMobileView,
  downloadScreenshot,
  timeWindow,
}: {
  count: number;
  showBarGraph: boolean;
  setShowBarGraph: React.Dispatch<React.SetStateAction<boolean>>;
  isMobileView: boolean;
  downloadScreenshot: () => void;
  timeWindow: TimeWindow;
}) => {
  return (
    <RowBetween key="top-left" width={isMobileView ? "100%" : "auto"}>
      <AutoColumn gap="4px">
        {isMobileView ? (
          <TEXT.SmallHeader fontSize={24}>
            <TabularNumsText>
              {`${formatAmount(count).toLocaleUpperCase()} Wallets`}
            </TabularNumsText>
          </TEXT.SmallHeader>
        ) : (
          <TEXT.LargeHeader>
            <TabularNumsText>
              {`${formatAmount(count).toLocaleUpperCase()} Wallets`}
            </TabularNumsText>
          </TEXT.LargeHeader>
        )}
      </AutoColumn>
    </RowBetween>
  );
};

function TotalNFTWalletsGraph({
  refTotalNFTWalletsGraph,
}: {
  refTotalNFTWalletsGraph: React.RefObject<HTMLElement>;
}) {
  const theme = useTheme();
  const [volumeHover, setVolumeHover] = useState<number | undefined>();
  const [liquidityHover, setLiquidityHover] = useState<number | undefined>();
  const [leftLabel, setLeftLabel] = useState<string | undefined>();
  const [rightLabel, setRightLabel] = useState<string | undefined>();
  const [timeWindow, setTimeWindow] = useState(TimeWindow.all);
  const [showBarGraph, setShowBarGraph] = useState(false);
  const totalNFTWallets = useTotalNFTWallets();
  const reverseTotalNFTWallets = [...(totalNFTWallets.records ?? [])].reverse();

  const { isMobile } = useMatchBreakpoints();
  const downloadScreenshot = useTakeScreenshot({
    ref: refTotalNFTWalletsGraph,
    elementIdsTohide: [snapshotDownloadIconId],
    elementIdsToShow: [snapshotBrandLogoId],
  });

  // Calendar actions
  const [selectedCustomTime, setSelectedCustomTime] = useState<
    { year: number; month: number } | undefined
  >(undefined);

  useEffect(() => {
    console.log("selectedCustomTime", selectedCustomTime);
  }, [selectedCustomTime]);

  const onCalendarChange = (year: number, month: number) => {
    setSelectedCustomTime({ year: year, month: month });
  };
  //

  useEffect(() => {
    setLiquidityHover(undefined);
    setVolumeHover(undefined);
  }, []);

  /**
   * @notice
   * @dev 'formattedVolumeData' function format data according to graph plot format
   * @dev 'getTransformedVolumeData' function filter chart data according to selected timewindow
   * @dev 'formattedChartSeries' change according to requirement
   * @param timewindow is selected option out of provided time options to user for filtering chart data
   * @param time will be for x-axis
   * @param value... must be same as @param datakey of config variable 'formattedChartSeries'
   */
  const formattedChartData = useMemo(() => {
    if (totalNFTWallets && totalNFTWallets.records) {
      return getTimewindowFilteredData(
        reverseTotalNFTWallets,
        timeWindow,
        selectedCustomTime
      ).map((record) => {
        return {
          time: record.dateTime,
          valueWallets: record.nftCumulativeWalletsCount,
        };
      });
    } else {
      return [];
    }
  }, [totalNFTWallets, timeWindow, selectedCustomTime]);

  /**
   * @notice
   * @dev 'formattedChartSeries'  must change according to requirement
   * Add according requirement (This will plot line graph with provided color and data)
   */
  const formattedChartSeries: GenericChartSeries[] = [
    { dataKey: "valueWallets", name: "Wallets", color: theme.graphLines },
  ];

  const filteredTotalCount: number = useMemo(() => {
    if (
      totalNFTWallets &&
      totalNFTWallets.records &&
      totalNFTWallets.records.length !== 0
    ) {
      switch (timeWindow) {
        case TimeWindow.threeMonth: {
          const beforeThreeMonthData = totalNFTWallets.records.find((element) =>
            dayjs(element.dateTime).isBefore(
              dayjs().subtract(3, "month"),
              "month"
            )
          );
          return (
            totalNFTWallets.records[0].nftCumulativeWalletsCount -
            (beforeThreeMonthData?.nftCumulativeWalletsCount ?? 0)
          );
        }
        case TimeWindow.sixMonth: {
          const beforeSixMonthData = totalNFTWallets.records.find((element) =>
            dayjs(element.dateTime).isBefore(
              dayjs().subtract(6, "month"),
              "month"
            )
          );
          return (
            totalNFTWallets.records[0].nftCumulativeWalletsCount -
            (beforeSixMonthData?.nftCumulativeWalletsCount ?? 0)
          );
        }
        case TimeWindow.oneYear: {
          const beforeOneYearData = totalNFTWallets.records.find((element) =>
            dayjs(element.dateTime).isBefore(
              dayjs().subtract(1, "year"),
              "month"
            )
          );
          return (
            totalNFTWallets.records[0].nftCumulativeWalletsCount -
            (beforeOneYearData?.nftCumulativeWalletsCount ?? 0)
          );
        }
        case TimeWindow.all:
          return totalNFTWallets.records[0].nftCumulativeWalletsCount;
        default:
          return totalNFTWallets.records[0].nftCumulativeWalletsCount;
      }
    } else {
      return 0;
    }
  }, [totalNFTWallets, timeWindow]);

  return (
    <>
      <GraphTableTopHeader
        title={"Total NFT Wallets"}
        tooltip="Wallets holding over 1 Polygon NFT"
        showBarGraph={showBarGraph}
        setShowBarGraph={setShowBarGraph}
        downloadScreenshot={downloadScreenshot}
        isMobileView={isMobile}
        snapshotBrandLogoId={snapshotBrandLogoId}
        snapshotDownloadIconId={snapshotDownloadIconId}
        tweetText={`https://twitter.com/intent/tweet?text=💜 Polygon NFT insights: NFT Wallets%0A👛 Wallets holding more than 1 NFT: ${formatAmount(
          filteredTotalCount
        ).toLocaleUpperCase()}%0A📆 for: ${timeWindow}%0A📊 increased by: 20﹪ %0A%0A🔗Check out the detailed chart in Surfaceboard by @LayerEhq here: ${twitterShareScrollPath.toString()} `} />
      <HorizontalDivider />
      {showBarGraph ? (
        <ChartWrapper id="TotalNFTWalletGraph">
          <BarChart
            data={formattedChartData}
            series={formattedChartSeries}
            yAxisLabel="NFT Wallets"
            setValue={setVolumeHover}
            setLabel={setRightLabel}
            value={volumeHover}
            label={rightLabel}
            activeWindow={timeWindow}
            topRight={
              <TopRightComponent
                count={filteredTotalCount}
                timeWindow={timeWindow}
                setTimeWindow={setTimeWindow}
                showBarGraph={showBarGraph}
                setShowBarGraph={setShowBarGraph}
                isMobileView={isMobile}
                downloadScreenshot={downloadScreenshot}
                selectedCustomTime={selectedCustomTime}
                onCalendarChange={onCalendarChange}
              />
            }
            topLeft={
              <TopLeftComponent
                timeWindow={timeWindow}
                count={filteredTotalCount}
                showBarGraph={showBarGraph}
                setShowBarGraph={setShowBarGraph}
                isMobileView={isMobile}
                downloadScreenshot={downloadScreenshot}
              />
            }
          />
        </ChartWrapper>
      ) : (
        <ChartWrapper id="TotalNFTWalletGraph">
          <LineAreaChart
            data={formattedChartData}
            series={formattedChartSeries}
            yAxisLabel="NFT Wallets"
            setValue={setVolumeHover}
            setLabel={setRightLabel}
            value={volumeHover}
            label={rightLabel}
            activeWindow={timeWindow}
            topRight={
              <TopRightComponent
                count={filteredTotalCount}
                timeWindow={timeWindow}
                setTimeWindow={setTimeWindow}
                showBarGraph={showBarGraph}
                setShowBarGraph={setShowBarGraph}
                isMobileView={isMobile}
                downloadScreenshot={downloadScreenshot}
                selectedCustomTime={selectedCustomTime}
                onCalendarChange={onCalendarChange}
              />
            }
            topLeft={
              <TopLeftComponent
                timeWindow={timeWindow}
                count={filteredTotalCount}
                showBarGraph={showBarGraph}
                setShowBarGraph={setShowBarGraph}
                isMobileView={isMobile}
                downloadScreenshot={downloadScreenshot}
              />
            }
          />
        </ChartWrapper>
      )}
    </>
  );
}

export default TotalNFTWalletsGraph;
