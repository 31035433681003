import { useCallback, useState } from "react";
import { updateCurrency, updateUserDarkMode,updateUserToken } from "./actions";
import { AppDispatch, AppState } from "../index";
import {
  shallowEqual,
  TypedUseSelectorHook,
  useDispatch,
  useSelector,
} from "react-redux";
import { dropdownOptions } from "components/DropDown/BasicDropdown";
import { CurrencyList, TypeCurrency } from "constant/currency";

export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;
export function useIsDarkMode(): boolean {
  const dispatch = useDispatch<AppDispatch>();
  const { userDarkMode } = useSelector<
    AppState,
    { userDarkMode: boolean | null }
  >(
    ({ user: { userDarkMode } }) => ({
      userDarkMode,
    }),
    shallowEqual
  );

  console.log("userDarkMode", userDarkMode);

  if (userDarkMode === null) {
    const match = window?.matchMedia("(prefers-color-scheme: dark)");
    dispatch(updateUserDarkMode({ userDarkMode: match.matches }));
    return match.matches;
  }

  return userDarkMode;
}

export function useDarkMode(): [boolean, () => void] {
  const dispatch = useDispatch<AppDispatch>();
  const darkMode = useIsDarkMode();
  const toggleSetDarkMode = useCallback(() => {
    dispatch(updateUserDarkMode({ userDarkMode: !darkMode }));
  }, [darkMode, dispatch]);

  return [darkMode, toggleSetDarkMode];
}

export function useToGetUserInfo() {
  const { wallet, holdsNFT, JWT } = useSelector<
    AppState,
    {
      wallet: any;
      holdsNFT: boolean;
      JWT: any;
    }
  >(({ user: { wallet, holdsNFT, JWT } }) => ({
    wallet,
    holdsNFT,
    JWT,
  }));
  return { wallet, holdsNFT, JWT };
}
export const useCurrencyUnit = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { currency } = useSelector<AppState, { currency: TypeCurrency }>(
    ({ user: { currency } }) => ({
      currency,
    }),
    shallowEqual
  );
  const defaultCurrencyUnit = CurrencyList[1];
  const handleSetCurrency = (value: string) => {
    const safeValue =
      CurrencyList.find((option) => option.id === value) ?? defaultCurrencyUnit;
    dispatch(updateCurrency({ currency: safeValue }));
  };
  return { currency, setCurrencyUnit: handleSetCurrency };
};

export const useUserJWT = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { JWT } = useSelector<AppState, { JWT: any }>(
    ({ user: { JWT } }) => ({
      JWT,
    }),
    shallowEqual
  );
  const handleSetJWT = (value: any) => {
    dispatch(updateUserToken({ JWT: value }));
  }
  return { JWT, setJWT: handleSetJWT };
}

