export const chartTestData = [
    {
        "tvlUSD": 0,
        "date": 1676612593,
        "volumeUSD": 0
    },
    {
        "tvlUSD": 69860062.42073771,
        "date": 1620172826,
        "volumeUSD": 8074267.26071557
    },
    {
        "tvlUSD": 180518155.8070201,
        "date": 1620259226,
        "volumeUSD": 133924627.3614405
    },
    {
        "tvlUSD": 245049048.50960904,
        "date": 1620345626,
        "volumeUSD": 221554049.7061538
    },
    {
        "tvlUSD": 257422697.21526146,
        "date": 1620432026,
        "volumeUSD": 305727794.7712251
    },
    {
        "tvlUSD": 288518739.2849689,
        "date": 1620518426,
        "volumeUSD": 271869678.0436013
    },
    {
        "tvlUSD": 190901477.91386408,
        "date": 1620604826,
        "volumeUSD": 499640678.8883962
    },
    {
        "tvlUSD": 320368500.0875804,
        "date": 1620691226,
        "volumeUSD": 398745374.7832075
    },
    {
        "tvlUSD": 279917208.4477716,
        "date": 1620777626,
        "volumeUSD": 814731978.157229
    },
    {
        "tvlUSD": 227515446.3901058,
        "date": 1620864026,
        "volumeUSD": 1194185373.845488
    },
    {
        "tvlUSD": 400917180.40570706,
        "date": 1620950426,
        "volumeUSD": 589617247.7375797
    },
    {
        "tvlUSD": 381604775.7335265,
        "date": 1621036826,
        "volumeUSD": 605419340.4750544
    },
    {
        "tvlUSD": 345094072.5637692,
        "date": 1621123226,
        "volumeUSD": 830969778.6085272
    },
    {
        "tvlUSD": 288121239.23909587,
        "date": 1621209626,
        "volumeUSD": 1045460974.0396155
    },
    {
        "tvlUSD": 362141851.2678092,
        "date": 1621296026,
        "volumeUSD": 828603337.5939587
    },
    {
        "tvlUSD": -77646922.03042339,
        "date": 1621382426,
        "volumeUSD": 1835340924.297661
    },
    {
        "tvlUSD": 199623252.85136926,
        "date": 1621468826,
        "volumeUSD": 1236233206.0192423
    },
    {
        "tvlUSD": 174333961.20348167,
        "date": 1621555226,
        "volumeUSD": 1089751109.3025997
    },
    {
        "tvlUSD": 258250154.3012646,
        "date": 1621641626,
        "volumeUSD": 834055979.302122
    },
    {
        "tvlUSD": 117894722.81314966,
        "date": 1621728026,
        "volumeUSD": 1357790087.896356
    },
    {
        "tvlUSD": 331833351.61717415,
        "date": 1621814426,
        "volumeUSD": 1253873018.0420356
    },
    {
        "tvlUSD": 470965291.37266374,
        "date": 1621900826,
        "volumeUSD": 1155883915.0101702
    },
    {
        "tvlUSD": 599485844.3714883,
        "date": 1621987226,
        "volumeUSD": 1113199569.479656
    },
    {
        "tvlUSD": 730707238.0239798,
        "date": 1622073626,
        "volumeUSD": 858497028.9021965
    },
    {
        "tvlUSD": 651805389.8250313,
        "date": 1622160026,
        "volumeUSD": 1066537011.0282286
    },
    {
        "tvlUSD": 621256593.6872233,
        "date": 1622246426,
        "volumeUSD": 1100053202.9111826
    },
    {
        "tvlUSD": 748504193.4315468,
        "date": 1622332826,
        "volumeUSD": 902797412.5687765
    },
    {
        "tvlUSD": 895678269.3342339,
        "date": 1622419226,
        "volumeUSD": 800403637.320704
    },
    {
        "tvlUSD": 883678761.1014745,
        "date": 1622505626,
        "volumeUSD": 709240112.9518683
    },
    {
        "tvlUSD": 972062940.7794508,
        "date": 1622592026,
        "volumeUSD": 536172981.0648371
    },
    {
        "tvlUSD": 1011972478.2194709,
        "date": 1622678426,
        "volumeUSD": 623072841.2217114
    },
    {
        "tvlUSD": 922246633.6336131,
        "date": 1622764826,
        "volumeUSD": 825499861.5511562
    },
    {
        "tvlUSD": 970225279.7380619,
        "date": 1622851226,
        "volumeUSD": 618192191.9475055
    },
    {
        "tvlUSD": 1082272356.7201312,
        "date": 1622937626,
        "volumeUSD": 368205886.9555058
    },
    {
        "tvlUSD": 903714362.0964593,
        "date": 1623024026,
        "volumeUSD": 682584606.3527863
    },
    {
        "tvlUSD": 859080728.251466,
        "date": 1623110426,
        "volumeUSD": 1104213049.7757304
    },
    {
        "tvlUSD": 899489299.7101438,
        "date": 1623196826,
        "volumeUSD": 1031605147.6957442
    },
    {
        "tvlUSD": 960246296.3718545,
        "date": 1623283226,
        "volumeUSD": 718019151.0018429
    },
    {
        "tvlUSD": 899731816.3673279,
        "date": 1623369626,
        "volumeUSD": 657691364.8430009
    },
    {
        "tvlUSD": 908535788.781977,
        "date": 1623456026,
        "volumeUSD": 610324965.6374601
    },
    {
        "tvlUSD": 962393140.2665658,
        "date": 1623542426,
        "volumeUSD": 626243360.9329466
    },
    {
        "tvlUSD": 987350146.1193665,
        "date": 1623628826,
        "volumeUSD": 598511136.7610521
    },
    {
        "tvlUSD": 1005101432.6162273,
        "date": 1623715226,
        "volumeUSD": 606065595.5803566
    },
    {
        "tvlUSD": 951951342.7546796,
        "date": 1623801626,
        "volumeUSD": 561968824.7196847
    },
    {
        "tvlUSD": 970952059.6472981,
        "date": 1623888026,
        "volumeUSD": 534590733.5449349
    },
    {
        "tvlUSD": 914278021.4101826,
        "date": 1623974426,
        "volumeUSD": 697331358.8251948
    },
    {
        "tvlUSD": 923481509.5194373,
        "date": 1624060826,
        "volumeUSD": 529171667.9261979
    },
    {
        "tvlUSD": 925171522.7978032,
        "date": 1624147226,
        "volumeUSD": 721254034.491204
    },
    {
        "tvlUSD": 626564603.353778,
        "date": 1624233626,
        "volumeUSD": 1311401049.1857176
    },
    {
        "tvlUSD": 673508574.4800532,
        "date": 1624320026,
        "volumeUSD": 1081852298.4878042
    },
    {
        "tvlUSD": 830755322.3341199,
        "date": 1624406426,
        "volumeUSD": 744053661.7706063
    },
    {
        "tvlUSD": 822990794.5565697,
        "date": 1624492826,
        "volumeUSD": 557648609.5482044
    },
    {
        "tvlUSD": 745240662.8518547,
        "date": 1624579226,
        "volumeUSD": 719299505.8547122
    },
    {
        "tvlUSD": 735636922.8222289,
        "date": 1624665626,
        "volumeUSD": 649337447.5836544
    },
    {
        "tvlUSD": 841211269.4512146,
        "date": 1624752026,
        "volumeUSD": 651025888.3924723
    },
    {
        "tvlUSD": 888115499.7571334,
        "date": 1624838426,
        "volumeUSD": 779792041.2413727
    },
    {
        "tvlUSD": 903599268.4537531,
        "date": 1624924826,
        "volumeUSD": 686015292.374112
    },
    {
        "tvlUSD": 890668490.1467289,
        "date": 1625011226,
        "volumeUSD": 751271465.428636
    },
    {
        "tvlUSD": 868272012.255481,
        "date": 1625097626,
        "volumeUSD": 692698369.3611833
    },
    {
        "tvlUSD": 915657522.0414375,
        "date": 1625184026,
        "volumeUSD": 536192255.58084005
    },
    {
        "tvlUSD": 925984029.283496,
        "date": 1625270426,
        "volumeUSD": 459583080.68217856
    },
    {
        "tvlUSD": 931385205.5535518,
        "date": 1625356826,
        "volumeUSD": 503398083.5352945
    },
    {
        "tvlUSD": 895958195.0243211,
        "date": 1625443226,
        "volumeUSD": 622950791.7647071
    },
    {
        "tvlUSD": 876571899.1542296,
        "date": 1625529626,
        "volumeUSD": 774284897.7447132
    },
    {
        "tvlUSD": 899272998.9257448,
        "date": 1625616026,
        "volumeUSD": 549352537.5497773
    },
    {
        "tvlUSD": 845431510.0331261,
        "date": 1625702426,
        "volumeUSD": 741339284.1937232
    },
    {
        "tvlUSD": 901023097.9332882,
        "date": 1625788826,
        "volumeUSD": 582493195.9907358
    },
    {
        "tvlUSD": 930942629.0805851,
        "date": 1625875226,
        "volumeUSD": 552979926.6563302
    },
    {
        "tvlUSD": 971411294.1344982,
        "date": 1625961626,
        "volumeUSD": 305840697.668742
    },
    {
        "tvlUSD": 909683310.9816563,
        "date": 1626048026,
        "volumeUSD": 440198334.89798146
    },
    {
        "tvlUSD": 901046186.1746588,
        "date": 1626134426,
        "volumeUSD": 587197539.3341978
    },
    {
        "tvlUSD": 844904617.5360413,
        "date": 1626220826,
        "volumeUSD": 604985539.9578271
    },
    {
        "tvlUSD": 818388556.4343045,
        "date": 1626307226,
        "volumeUSD": 616076777.6200212
    },
    {
        "tvlUSD": 829625598.0649407,
        "date": 1626393626,
        "volumeUSD": 452507684.0958979
    },
    {
        "tvlUSD": 857626222.2676125,
        "date": 1626480026,
        "volumeUSD": 404339059.9986853
    },
    {
        "tvlUSD": 858647245.8773351,
        "date": 1626566426,
        "volumeUSD": 440613399.29494786
    },
    {
        "tvlUSD": 834210336.5049617,
        "date": 1626652826,
        "volumeUSD": 425956355.409847
    },
    {
        "tvlUSD": 786849305.9702028,
        "date": 1626739226,
        "volumeUSD": 606094927.3490202
    },
    {
        "tvlUSD": 840697190.9915613,
        "date": 1626825626,
        "volumeUSD": 840110081.4439641
    },
    {
        "tvlUSD": 877943346.0987,
        "date": 1626912026,
        "volumeUSD": 713076733.917514
    },
    {
        "tvlUSD": 963350235.908405,
        "date": 1626998426,
        "volumeUSD": 533583030.9967075
    },
    {
        "tvlUSD": 988992370.885037,
        "date": 1627084826,
        "volumeUSD": 531977775.6171059
    },
    {
        "tvlUSD": 1007859490.9239388,
        "date": 1627171226,
        "volumeUSD": 477713722.14879835
    },
    {
        "tvlUSD": 880817357.0455276,
        "date": 1627257626,
        "volumeUSD": 1538904638.7826052
    },
    {
        "tvlUSD": 969823470.3895317,
        "date": 1627344026,
        "volumeUSD": 1017273908.2324653
    },
    {
        "tvlUSD": 1015311476.6566236,
        "date": 1627430426,
        "volumeUSD": 953115253.0441238
    },
    {
        "tvlUSD": 1094039501.678637,
        "date": 1627516826,
        "volumeUSD": 523644431.21642154
    },
    {
        "tvlUSD": 1083625954.6927786,
        "date": 1627603226,
        "volumeUSD": 805744663.5807704
    },
    {
        "tvlUSD": 1113682438.0225356,
        "date": 1627689626,
        "volumeUSD": 544260440.1998705
    },
    {
        "tvlUSD": 1053944152.8260638,
        "date": 1627776026,
        "volumeUSD": 824018292.8338755
    },
    {
        "tvlUSD": 1154265243.109422,
        "date": 1627862426,
        "volumeUSD": 858070735.6796612
    },
    {
        "tvlUSD": 1095449078.6839347,
        "date": 1627948826,
        "volumeUSD": 1062065358.562721
    },
    {
        "tvlUSD": 1118386851.4016335,
        "date": 1628035226,
        "volumeUSD": 1210239117.490096
    },
    {
        "tvlUSD": 1077512028.0513887,
        "date": 1628121626,
        "volumeUSD": 1347166558.51096
    },
    {
        "tvlUSD": 1230825507.391525,
        "date": 1628208026,
        "volumeUSD": 1032934085.7547699
    },
    {
        "tvlUSD": 1200068709.7339535,
        "date": 1628294426,
        "volumeUSD": 1457357131.5540607
    },
    {
        "tvlUSD": 1248309429.0695107,
        "date": 1628380826,
        "volumeUSD": 1254877850.9744062
    },
    {
        "tvlUSD": 1082688747.3659723,
        "date": 1628467226,
        "volumeUSD": 1723471956.7949321
    },
    {
        "tvlUSD": 1196499319.6915329,
        "date": 1628553626,
        "volumeUSD": 1119265351.852446
    },
    {
        "tvlUSD": 1253018109.2377837,
        "date": 1628640026,
        "volumeUSD": 871270488.2220929
    },
    {
        "tvlUSD": 1173010304.2113206,
        "date": 1628726426,
        "volumeUSD": 1135517211.4305816
    },
    {
        "tvlUSD": 1276500882.90339,
        "date": 1628812826,
        "volumeUSD": 814576246.1548026
    },
    {
        "tvlUSD": 1426504243.6075134,
        "date": 1628899226,
        "volumeUSD": 587925664.2309115
    },
    {
        "tvlUSD": 1409618791.8427057,
        "date": 1628985626,
        "volumeUSD": 828869930.8322806
    },
    {
        "tvlUSD": 1369804992.1375546,
        "date": 1629072026,
        "volumeUSD": 960310596.7488791
    },
    {
        "tvlUSD": 1269169958.8083687,
        "date": 1629158426,
        "volumeUSD": 1481880894.033967
    },
    {
        "tvlUSD": 1314534518.1858506,
        "date": 1629244826,
        "volumeUSD": 1221856927.5226276
    },
    {
        "tvlUSD": 1411018316.21384,
        "date": 1629331226,
        "volumeUSD": 970284051.3265264
    },
    {
        "tvlUSD": 1457796137.5584295,
        "date": 1629417626,
        "volumeUSD": 859637417.3604997
    },
    {
        "tvlUSD": 1530757781.305403,
        "date": 1629504026,
        "volumeUSD": 623678135.4107243
    },
    {
        "tvlUSD": 1500621398.1555755,
        "date": 1629590426,
        "volumeUSD": 650668465.1220298
    },
    {
        "tvlUSD": 1519620049.2945323,
        "date": 1629676826,
        "volumeUSD": 848584946.2665178
    },
    {
        "tvlUSD": 1465358352.1892926,
        "date": 1629763226,
        "volumeUSD": 995025492.6059754
    },
    {
        "tvlUSD": 1501769921.3525674,
        "date": 1629849626,
        "volumeUSD": 893478037.7194552
    },
    {
        "tvlUSD": 1464805603.904756,
        "date": 1629936026,
        "volumeUSD": 889499502.9355332
    },
    {
        "tvlUSD": 1537415619.188189,
        "date": 1630022426,
        "volumeUSD": 768954440.8494643
    },
    {
        "tvlUSD": 1591830502.5688136,
        "date": 1630108826,
        "volumeUSD": 383972213.14821154
    },
    {
        "tvlUSD": 1568567738.8827052,
        "date": 1630195226,
        "volumeUSD": 621640178.0607275
    },
    {
        "tvlUSD": 1514595659.8624861,
        "date": 1630281626,
        "volumeUSD": 892114197.0835152
    },
    {
        "tvlUSD": 1502206480.441029,
        "date": 1630368026,
        "volumeUSD": 1216988862.585859
    },
    {
        "tvlUSD": 1446336219.8424368,
        "date": 1630454426,
        "volumeUSD": 1282611140.482897
    },
    {
        "tvlUSD": 1438937083.0233047,
        "date": 1630540826,
        "volumeUSD": 984043400.900124
    },
    {
        "tvlUSD": 1453761937.800671,
        "date": 1630627226,
        "volumeUSD": 994707529.2746639
    },
    {
        "tvlUSD": 1497932027.139594,
        "date": 1630713626,
        "volumeUSD": 718722660.0872056
    },
    {
        "tvlUSD": 1510241566.2990565,
        "date": 1630800026,
        "volumeUSD": 686845415.7178841
    },
    {
        "tvlUSD": 1489865035.4921055,
        "date": 1630886426,
        "volumeUSD": 709548633.1625795
    },
    {
        "tvlUSD": 997205060.8317447,
        "date": 1630972826,
        "volumeUSD": 1813177573.119992
    },
    {
        "tvlUSD": 1125913786.125922,
        "date": 1631059226,
        "volumeUSD": 1391674008.3260794
    },
    {
        "tvlUSD": 1203649621.6687078,
        "date": 1631145626,
        "volumeUSD": 866823332.0955509
    },
    {
        "tvlUSD": 1109622828.8422701,
        "date": 1631232026,
        "volumeUSD": 972112913.8823069
    },
    {
        "tvlUSD": 1184328494.9057035,
        "date": 1631318426,
        "volumeUSD": 640146242.7927525
    },
    {
        "tvlUSD": 1208125511.5645704,
        "date": 1631404826,
        "volumeUSD": 603001441.9163616
    },
    {
        "tvlUSD": 1117366039.4179966,
        "date": 1631491226,
        "volumeUSD": 915497169.7089605
    },
    {
        "tvlUSD": 1210367308.7541754,
        "date": 1631577626,
        "volumeUSD": 590204519.9531807
    },
    {
        "tvlUSD": 1250065101.5308,
        "date": 1631664026,
        "volumeUSD": 580278139.4554362
    },
    {
        "tvlUSD": 1245381091.041825,
        "date": 1631750426,
        "volumeUSD": 833185958.6225139
    },
    {
        "tvlUSD": 1227975419.4084988,
        "date": 1631836826,
        "volumeUSD": 723269570.2520907
    },
    {
        "tvlUSD": 1271235930.4715436,
        "date": 1631923226,
        "volumeUSD": 513209126.73060375
    },
    {
        "tvlUSD": 1278540735.9407022,
        "date": 1632009626,
        "volumeUSD": 552041350.1238074
    },
    {
        "tvlUSD": 1027956398.4808943,
        "date": 1632096026,
        "volumeUSD": 1708420454.8744483
    },
    {
        "tvlUSD": 972598329.3548754,
        "date": 1632182426,
        "volumeUSD": 1686801402.363989
    },
    {
        "tvlUSD": 1167894413.8965387,
        "date": 1632268826,
        "volumeUSD": 1049004383.2276261
    },
    {
        "tvlUSD": 1217583357.0961337,
        "date": 1632355226,
        "volumeUSD": 779569791.0036138
    },
    {
        "tvlUSD": 1110939977.1183603,
        "date": 1632441626,
        "volumeUSD": 1174983037.8511937
    },
    {
        "tvlUSD": 1167794470.4286532,
        "date": 1632528026,
        "volumeUSD": 901352695.6199778
    },
    {
        "tvlUSD": 1168260917.2469692,
        "date": 1632614426,
        "volumeUSD": 1182348797.9679058
    },
    {
        "tvlUSD": 1200184272.2147546,
        "date": 1632700826,
        "volumeUSD": 908180736.1886704
    },
    {
        "tvlUSD": 1149843757.284575,
        "date": 1632787226,
        "volumeUSD": 832030500.4578112
    },
    {
        "tvlUSD": 1189602300.9580755,
        "date": 1632873626,
        "volumeUSD": 657924543.1169856
    },
    {
        "tvlUSD": 1211163122.121699,
        "date": 1632960026,
        "volumeUSD": 864467335.099986
    },
    {
        "tvlUSD": 1277248740.528122,
        "date": 1633046426,
        "volumeUSD": 944005385.076408
    },
    {
        "tvlUSD": 1318775753.1432474,
        "date": 1633132826,
        "volumeUSD": 656825383.6842319
    },
    {
        "tvlUSD": 1321369371.0963824,
        "date": 1633219226,
        "volumeUSD": 660096589.3406131
    },
    {
        "tvlUSD": 1284508904.2424734,
        "date": 1633305626,
        "volumeUSD": 846018985.396946
    },
    {
        "tvlUSD": 1356321092.9051888,
        "date": 1633392026,
        "volumeUSD": 627869535.6043164
    },
    {
        "tvlUSD": 1309446871.0165854,
        "date": 1633478426,
        "volumeUSD": 1065899453.5505099
    },
    {
        "tvlUSD": 1343438641.202921,
        "date": 1633564826,
        "volumeUSD": 845653096.6428568
    },
    {
        "tvlUSD": 1372063544.1840174,
        "date": 1633651226,
        "volumeUSD": 709255117.9869555
    },
    {
        "tvlUSD": 1426540577.1474159,
        "date": 1633737626,
        "volumeUSD": 424464518.4159565
    },
    {
        "tvlUSD": 1348353099.5123632,
        "date": 1633824026,
        "volumeUSD": 782455556.7961178
    },
    {
        "tvlUSD": 1346304806.3788023,
        "date": 1633910426,
        "volumeUSD": 932597446.6173713
    },
    {
        "tvlUSD": 1317262362.5730965,
        "date": 1633996826,
        "volumeUSD": 1054289621.6084902
    },
    {
        "tvlUSD": 1358760817.770568,
        "date": 1634083226,
        "volumeUSD": 910819850.3779314
    },
    {
        "tvlUSD": 1400719038.1961517,
        "date": 1634169626,
        "volumeUSD": 977423553.633833
    },
    {
        "tvlUSD": 1449558761.3755016,
        "date": 1634256026,
        "volumeUSD": 1327224392.7173808
    },
    {
        "tvlUSD": 1485020573.7167022,
        "date": 1634342426,
        "volumeUSD": 919178956.4416182
    },
    {
        "tvlUSD": 1539764733.7192698,
        "date": 1634428826,
        "volumeUSD": 807695349.9447736
    },
    {
        "tvlUSD": 1517588570.1060565,
        "date": 1634515226,
        "volumeUSD": 996762577.4494333
    },
    {
        "tvlUSD": 1573971637.6989477,
        "date": 1634601626,
        "volumeUSD": 1031832875.7319943
    },
    {
        "tvlUSD": 1637398314.2887738,
        "date": 1634688026,
        "volumeUSD": 1122732874.9423752
    },
    {
        "tvlUSD": 1500513425.510058,
        "date": 1634774426,
        "volumeUSD": 1997473512.6372707
    },
    {
        "tvlUSD": 1570166362.7298567,
        "date": 1634860826,
        "volumeUSD": 1228295517.8077195
    },
    {
        "tvlUSD": 1608765096.9691474,
        "date": 1634947226,
        "volumeUSD": 892614967.692706
    },
    {
        "tvlUSD": 1628168323.3912787,
        "date": 1635033626,
        "volumeUSD": 829878898.5572481
    },
    {
        "tvlUSD": 1679363935.1585581,
        "date": 1635120026,
        "volumeUSD": 850130057.2066778
    },
    {
        "tvlUSD": 1600050749.7919147,
        "date": 1635206426,
        "volumeUSD": 1215618282.0510235
    },
    {
        "tvlUSD": 1464001605.0842388,
        "date": 1635292826,
        "volumeUSD": 1663609674.9851775
    },
    {
        "tvlUSD": 1619687946.8467155,
        "date": 1635379226,
        "volumeUSD": 1458132143.7454247
    },
    {
        "tvlUSD": 1722276654.540524,
        "date": 1635465626,
        "volumeUSD": 1023605929.1811296
    },
    {
        "tvlUSD": 1712499073.1609066,
        "date": 1635552026,
        "volumeUSD": 666647791.8045729
    },
    {
        "tvlUSD": 1746433915.89111,
        "date": 1635638426,
        "volumeUSD": 695516887.0907837
    },
    {
        "tvlUSD": 1747696891.1805155,
        "date": 1635724826,
        "volumeUSD": 789431023.4638683
    },
    {
        "tvlUSD": 1782655261.3460026,
        "date": 1635811226,
        "volumeUSD": 926970089.3501575
    },
    {
        "tvlUSD": 1797369973.6134846,
        "date": 1635897626,
        "volumeUSD": 998674941.6402065
    },
    {
        "tvlUSD": 1777991367.5496552,
        "date": 1635984026,
        "volumeUSD": 814797479.504281
    },
    {
        "tvlUSD": 1817772644.3973732,
        "date": 1636070426,
        "volumeUSD": 624084202.6432338
    },
    {
        "tvlUSD": 1837480362.1304133,
        "date": 1636156826,
        "volumeUSD": 659220889.0093879
    },
    {
        "tvlUSD": 1923974382.6061752,
        "date": 1636243226,
        "volumeUSD": 565697408.7400663
    },
    {
        "tvlUSD": 1928467247.183626,
        "date": 1636329626,
        "volumeUSD": 1052772055.2649323
    },
    {
        "tvlUSD": 1915752882.8193552,
        "date": 1636416026,
        "volumeUSD": 866831248.6855849
    },
    {
        "tvlUSD": 1792581899.4711215,
        "date": 1636502426,
        "volumeUSD": 1959669606.7106519
    },
    {
        "tvlUSD": 1928585287.0255117,
        "date": 1636588826,
        "volumeUSD": 1089484464.3608994
    },
    {
        "tvlUSD": 1873242935.980207,
        "date": 1636675226,
        "volumeUSD": 1460305265.1575694
    },
    {
        "tvlUSD": 1968019811.150035,
        "date": 1636761626,
        "volumeUSD": 634053989.5254922
    },
    {
        "tvlUSD": 1965596397.6545281,
        "date": 1636848026,
        "volumeUSD": 851462986.4825531
    },
    {
        "tvlUSD": 1933832684.236101,
        "date": 1636934426,
        "volumeUSD": 1044675283.3336523
    },
    {
        "tvlUSD": 1794718807.5391068,
        "date": 1637020826,
        "volumeUSD": 1894724232.7753677
    },
    {
        "tvlUSD": 2078839853.563433,
        "date": 1637107226,
        "volumeUSD": 1367900150.8738542
    },
    {
        "tvlUSD": 1971496721.2088332,
        "date": 1637193626,
        "volumeUSD": 1903731098.5048041
    },
    {
        "tvlUSD": 2145427177.5352404,
        "date": 1637280026,
        "volumeUSD": 1346666124.8899229
    },
    {
        "tvlUSD": 2221647744.737366,
        "date": 1637366426,
        "volumeUSD": 921659946.197692
    },
    {
        "tvlUSD": 2182682002.876773,
        "date": 1637452826,
        "volumeUSD": 962579667.1704875
    },
    {
        "tvlUSD": 2096064014.6185112,
        "date": 1637539226,
        "volumeUSD": 1678532161.642103
    },
    {
        "tvlUSD": 2154103794.816101,
        "date": 1637625626,
        "volumeUSD": 1475086074.2710967
    },
    {
        "tvlUSD": 2167248980.127527,
        "date": 1637712026,
        "volumeUSD": 1384287936.6953304
    },
    {
        "tvlUSD": 2223692882.9629455,
        "date": 1637798426,
        "volumeUSD": 1317003095.7192764
    },
    {
        "tvlUSD": 1916433511.3649626,
        "date": 1637884826,
        "volumeUSD": 2465408552.1924796
    },
    {
        "tvlUSD": 2091491502.8142545,
        "date": 1637971226,
        "volumeUSD": 1062504959.912052
    },
    {
        "tvlUSD": 2086350747.3492928,
        "date": 1638057626,
        "volumeUSD": 1464265378.5321376
    },
    {
        "tvlUSD": 2207390776.5076723,
        "date": 1638144026,
        "volumeUSD": 1372867406.0198457
    },
    {
        "tvlUSD": 2034852088.8406603,
        "date": 1638230426,
        "volumeUSD": 2561680183.89923
    },
    {
        "tvlUSD": 2090430458.6288288,
        "date": 1638316826,
        "volumeUSD": 1828599094.5718982
    },
    {
        "tvlUSD": 2106344357.1380312,
        "date": 1638403226,
        "volumeUSD": 1930561838.7069428
    },
    {
        "tvlUSD": 2084070602.828494,
        "date": 1638489626,
        "volumeUSD": 2459404054.7812886
    },
    {
        "tvlUSD": 1871237982.9316833,
        "date": 1638576026,
        "volumeUSD": 3565504209.644127
    },
    {
        "tvlUSD": 2083451901.6207564,
        "date": 1638662426,
        "volumeUSD": 2230023962.1564775
    },
    {
        "tvlUSD": 2070156193.3090298,
        "date": 1638748826,
        "volumeUSD": 2922526270.6045027
    },
    {
        "tvlUSD": 2216653868.7601,
        "date": 1638835226,
        "volumeUSD": 1571562326.0897
    },
    {
        "tvlUSD": 2249846009.079358,
        "date": 1638921626,
        "volumeUSD": 1530439853.1081147
    },
    {
        "tvlUSD": 2118771566.5378861,
        "date": 1639008026,
        "volumeUSD": 2291742154.506241
    },
    {
        "tvlUSD": 2029320052.458445,
        "date": 1639094426,
        "volumeUSD": 2598866045.8486476
    },
    {
        "tvlUSD": 2171757252.204253,
        "date": 1639180826,
        "volumeUSD": 1692732627.168725
    },
    {
        "tvlUSD": 2299847447.9045196,
        "date": 1639267226,
        "volumeUSD": 1095732766.006177
    },
    {
        "tvlUSD": 2071811453.162839,
        "date": 1639353626,
        "volumeUSD": 1965796270.758721
    },
    {
        "tvlUSD": 2078863647.809629,
        "date": 1639440026,
        "volumeUSD": 1584441880.0259068
    },
    {
        "tvlUSD": 2030468819.992993,
        "date": 1639526426,
        "volumeUSD": 2638311923.9826097
    },
    {
        "tvlUSD": 2128073471.7181747,
        "date": 1639612826,
        "volumeUSD": 1520260037.169325
    },
    {
        "tvlUSD": 2056902203.206174,
        "date": 1639699226,
        "volumeUSD": 2063141544.2971573
    },
    {
        "tvlUSD": 2179563557.541049,
        "date": 1639785626,
        "volumeUSD": 1408850405.5822618
    },
    {
        "tvlUSD": 2211238495.024731,
        "date": 1639872026,
        "volumeUSD": 1280315695.829675
    },
    {
        "tvlUSD": 2188955584.204867,
        "date": 1639958426,
        "volumeUSD": 1724316550.101933
    },
    {
        "tvlUSD": 2298992334.0847607,
        "date": 1640044826,
        "volumeUSD": 1166500995.2470772
    },
    {
        "tvlUSD": 2319843961.9888325,
        "date": 1640131226,
        "volumeUSD": 940431320.3335261
    },
    {
        "tvlUSD": 2282268232.6113777,
        "date": 1640217626,
        "volumeUSD": 1271552934.9935062
    },
    {
        "tvlUSD": 2286398890.526026,
        "date": 1640304026,
        "volumeUSD": 949355074.6410755
    },
    {
        "tvlUSD": 2308405804.7424297,
        "date": 1640390426,
        "volumeUSD": 842033821.9349909
    },
    {
        "tvlUSD": 2307668614.1190195,
        "date": 1640476826,
        "volumeUSD": 812253285.838068
    },
    {
        "tvlUSD": 2312193530.9919267,
        "date": 1640563226,
        "volumeUSD": 720118167.0131928
    },
    {
        "tvlUSD": 2129662868.0677686,
        "date": 1640649626,
        "volumeUSD": 1595130768.8704796
    },
    {
        "tvlUSD": 2142986017.1544023,
        "date": 1640736026,
        "volumeUSD": 992301918.5517402
    },
    {
        "tvlUSD": 2187034809.120006,
        "date": 1640822426,
        "volumeUSD": 836288932.1994326
    },
    {
        "tvlUSD": 2151954593.794406,
        "date": 1640908826,
        "volumeUSD": 892093657.2480499
    },
    {
        "tvlUSD": 2207587375.329182,
        "date": 1640995226,
        "volumeUSD": 652644505.0087047
    },
    {
        "tvlUSD": 2239368879.643402,
        "date": 1641081626,
        "volumeUSD": 679067152.6019163
    },
    {
        "tvlUSD": 2217555738.254002,
        "date": 1641168026,
        "volumeUSD": 795009655.4278961
    },
    {
        "tvlUSD": 2204479516.2106247,
        "date": 1641254426,
        "volumeUSD": 1096703755.2223775
    },
    {
        "tvlUSD": 2047496304.060091,
        "date": 1641340826,
        "volumeUSD": 1570531516.903305
    },
    {
        "tvlUSD": 2015576822.5269535,
        "date": 1641427226,
        "volumeUSD": 1444399022.0223615
    },
    {
        "tvlUSD": 1786299717.0129747,
        "date": 1641513626,
        "volumeUSD": 1812930434.9929974
    },
    {
        "tvlUSD": 1895766647.5946126,
        "date": 1641600026,
        "volumeUSD": 1525826089.7465212
    },
    {
        "tvlUSD": 1971820591.2814083,
        "date": 1641686426,
        "volumeUSD": 844631306.5785006
    },
    {
        "tvlUSD": 1900639192.6633668,
        "date": 1641772826,
        "volumeUSD": 1757570280.421631
    },
    {
        "tvlUSD": 2048986744.9232066,
        "date": 1641859226,
        "volumeUSD": 1115729633.6828728
    },
    {
        "tvlUSD": 1931090110.1697164,
        "date": 1641945626,
        "volumeUSD": 1102069529.677394
    },
    {
        "tvlUSD": 1886452778.0576458,
        "date": 1642032026,
        "volumeUSD": 1333637689.6600597
    },
    {
        "tvlUSD": 1935887248.2777684,
        "date": 1642118426,
        "volumeUSD": 987024354.925627
    },
    {
        "tvlUSD": 1995281824.84488,
        "date": 1642204826,
        "volumeUSD": 640533367.1981546
    },
    {
        "tvlUSD": 1993376932.9820452,
        "date": 1642291226,
        "volumeUSD": 660848732.1028126
    },
    {
        "tvlUSD": 1927818885.59846,
        "date": 1642377626,
        "volumeUSD": 1098430171.581661
    },
    {
        "tvlUSD": 2063936145.2597785,
        "date": 1642464026,
        "volumeUSD": 1197539232.281032
    },
    {
        "tvlUSD": 2037695000.011067,
        "date": 1642550426,
        "volumeUSD": 1225505130.884025
    },
    {
        "tvlUSD": 1985857857.110675,
        "date": 1642636826,
        "volumeUSD": 1326550862.0051866
    },
    {
        "tvlUSD": 1435796968.2320073,
        "date": 1642723226,
        "volumeUSD": 3721056931.492716
    },
    {
        "tvlUSD": 1518973997.3630223,
        "date": 1642809626,
        "volumeUSD": 2312141864.9207706
    },
    {
        "tvlUSD": 1665078793.2718792,
        "date": 1642896026,
        "volumeUSD": 2027192927.2460482
    },
    {
        "tvlUSD": 1544159539.0835905,
        "date": 1642982426,
        "volumeUSD": 2783327527.4795814
    },
    {
        "tvlUSD": 1730156300.0356424,
        "date": 1643068826,
        "volumeUSD": 1378260369.9965103
    },
    {
        "tvlUSD": 1645568970.4701238,
        "date": 1643155226,
        "volumeUSD": 2319864585.518402
    },
    {
        "tvlUSD": 1731438169.9732757,
        "date": 1643241626,
        "volumeUSD": 1735648967.1333146
    },
    {
        "tvlUSD": 1841347520.653503,
        "date": 1643328026,
        "volumeUSD": 1377847677.8621976
    },
    {
        "tvlUSD": 1939744833.3207164,
        "date": 1643414426,
        "volumeUSD": 936353636.9856138
    },
    {
        "tvlUSD": 1974323992.0590396,
        "date": 1643500826,
        "volumeUSD": 814067200.4291083
    },
    {
        "tvlUSD": 2006325769.9918344,
        "date": 1643587226,
        "volumeUSD": 1101191789.544892
    },
    {
        "tvlUSD": 2057908974.3123856,
        "date": 1643673626,
        "volumeUSD": 1346368084.451032
    },
    {
        "tvlUSD": 2061057126.759039,
        "date": 1643760026,
        "volumeUSD": 1232404094.5186813
    },
    {
        "tvlUSD": 2087786535.8249607,
        "date": 1643846426,
        "volumeUSD": 1154772715.252378
    },
    {
        "tvlUSD": 2132138942.4820182,
        "date": 1643932826,
        "volumeUSD": 1246722310.8703098
    },
    {
        "tvlUSD": 2343156358.744659,
        "date": 1644019226,
        "volumeUSD": 1160589496.3493865
    },
    {
        "tvlUSD": 2410123208.8146086,
        "date": 1644105626,
        "volumeUSD": 836823596.6788019
    },
    {
        "tvlUSD": 2413023554.6453924,
        "date": 1644192026,
        "volumeUSD": 1195359645.7724147
    },
    {
        "tvlUSD": 2393118793.3026958,
        "date": 1644278426,
        "volumeUSD": 1214023143.980915
    },
    {
        "tvlUSD": 2480210786.3788195,
        "date": 1644364826,
        "volumeUSD": 1098363226.5967188
    },
    {
        "tvlUSD": 2360700713.2139063,
        "date": 1644451226,
        "volumeUSD": 1761020665.841772
    },
    {
        "tvlUSD": 2307479126.323217,
        "date": 1644537626,
        "volumeUSD": 1805644045.5174747
    },
    {
        "tvlUSD": 2387894976.618051,
        "date": 1644624026,
        "volumeUSD": 1133041131.0606673
    },
    {
        "tvlUSD": 2426509010.8505797,
        "date": 1644710426,
        "volumeUSD": 706132822.8671355
    },
    {
        "tvlUSD": 2480267785.7507763,
        "date": 1644796826,
        "volumeUSD": 951382175.7566385
    },
    {
        "tvlUSD": 2579380865.543128,
        "date": 1644883226,
        "volumeUSD": 1202901780.6192245
    },
    {
        "tvlUSD": 2673942925.956788,
        "date": 1644969626,
        "volumeUSD": 1193819793.501414
    },
    {
        "tvlUSD": 2581947265.170326,
        "date": 1645056026,
        "volumeUSD": 1564703374.8326097
    },
    {
        "tvlUSD": 2534868399.694409,
        "date": 1645142426,
        "volumeUSD": 1271105025.7746074
    },
    {
        "tvlUSD": 2483603889.025623,
        "date": 1645228826,
        "volumeUSD": 723569281.0046808
    },
    {
        "tvlUSD": 2388261602.493381,
        "date": 1645315226,
        "volumeUSD": 1295790837.9042907
    },
    {
        "tvlUSD": 2287556782.1650176,
        "date": 1645401626,
        "volumeUSD": 2014675589.3210342
    },
    {
        "tvlUSD": 2358494973.955277,
        "date": 1645488026,
        "volumeUSD": 1441539533.4633083
    },
    {
        "tvlUSD": 2428628742.267095,
        "date": 1645574426,
        "volumeUSD": 1254821393.9701984
    },
    {
        "tvlUSD": 2216386748.932958,
        "date": 1645660826,
        "volumeUSD": 3037272001.6869483
    },
    {
        "tvlUSD": 2404176756.910411,
        "date": 1645747226,
        "volumeUSD": 1911677252.1464784
    },
    {
        "tvlUSD": 2492620083.6586604,
        "date": 1645833626,
        "volumeUSD": 1054640253.0199033
    },
    {
        "tvlUSD": 2392988336.410313,
        "date": 1645920026,
        "volumeUSD": 1731757714.3266075
    },
    {
        "tvlUSD": 2494970722.4618616,
        "date": 1646006426,
        "volumeUSD": 1791855422.0748243
    },
    {
        "tvlUSD": 2687961403.3701982,
        "date": 1646092826,
        "volumeUSD": 1657534640.654288
    },
    {
        "tvlUSD": 2660031913.501969,
        "date": 1646179226,
        "volumeUSD": 1737764910.2946672
    },
    {
        "tvlUSD": 2681291187.8309383,
        "date": 1646265626,
        "volumeUSD": 1086324635.1726484
    },
    {
        "tvlUSD": 2524543656.7088447,
        "date": 1646352026,
        "volumeUSD": 1596386714.156453
    },
    {
        "tvlUSD": 2650869435.0263042,
        "date": 1646438426,
        "volumeUSD": 652106007.123919
    },
    {
        "tvlUSD": 2600451749.1103506,
        "date": 1646524826,
        "volumeUSD": 832934049.0751016
    },
    {
        "tvlUSD": 2535784586.268626,
        "date": 1646611226,
        "volumeUSD": 1272884664.344079
    },
    {
        "tvlUSD": 2570726147.967325,
        "date": 1646697626,
        "volumeUSD": 1399760596.519786
    },
    {
        "tvlUSD": 2671702855.161103,
        "date": 1646784026,
        "volumeUSD": 1159921265.9643767
    },
    {
        "tvlUSD": 2626654016.515045,
        "date": 1646870426,
        "volumeUSD": 1046176304.9414569
    },
    {
        "tvlUSD": 2631903606.784548,
        "date": 1646956826,
        "volumeUSD": 1037451813.1894946
    },
    {
        "tvlUSD": 2695712071.408199,
        "date": 1647043226,
        "volumeUSD": 480689885.8670905
    },
    {
        "tvlUSD": 2642093855.7744174,
        "date": 1647129626,
        "volumeUSD": 729938323.2293919
    },
    {
        "tvlUSD": 2650278281.1388936,
        "date": 1647216026,
        "volumeUSD": 905109636.5618988
    },
    {
        "tvlUSD": 2625749905.1599483,
        "date": 1647302426,
        "volumeUSD": 927735274.8049471
    },
    {
        "tvlUSD": 2628053918.6549115,
        "date": 1647388826,
        "volumeUSD": 1526396900.2069683
    },
    {
        "tvlUSD": 2683707897.579574,
        "date": 1647475226,
        "volumeUSD": 983322273.3940535
    },
    {
        "tvlUSD": 2727054557.4133873,
        "date": 1647561626,
        "volumeUSD": 1270911864.6795027
    },
    {
        "tvlUSD": 2762355324.1015224,
        "date": 1647648026,
        "volumeUSD": 793286660.4171536
    },
    {
        "tvlUSD": 2729365343.215004,
        "date": 1647734426,
        "volumeUSD": 901001640.8482748
    },
    {
        "tvlUSD": 2658813665.182692,
        "date": 1647820826,
        "volumeUSD": 976304873.5722984
    },
    {
        "tvlUSD": 2710064933.6490955,
        "date": 1647907226,
        "volumeUSD": 1165506626.0643363
    },
    {
        "tvlUSD": 2659207632.883644,
        "date": 1647993626,
        "volumeUSD": 989003795.2668866
    },
    {
        "tvlUSD": 2685151162.4046407,
        "date": 1648080026,
        "volumeUSD": 1563004660.4661486
    },
    {
        "tvlUSD": 2711666169.8339767,
        "date": 1648166426,
        "volumeUSD": 1246768354.377501
    },
    {
        "tvlUSD": 2795546837.3821983,
        "date": 1648252826,
        "volumeUSD": 604948382.9733973
    },
    {
        "tvlUSD": 2814842759.4196963,
        "date": 1648339226,
        "volumeUSD": 845142205.556081
    },
    {
        "tvlUSD": 2782373330.7621226,
        "date": 1648425626,
        "volumeUSD": 1246175326.0975785
    },
    {
        "tvlUSD": 2783070744.268046,
        "date": 1648512026,
        "volumeUSD": 1334487032.7114034
    },
    {
        "tvlUSD": 2835754309.763023,
        "date": 1648598426,
        "volumeUSD": 1167257204.4483294
    },
    {
        "tvlUSD": 2775749197.60483,
        "date": 1648684826,
        "volumeUSD": 1207546233.5191193
    },
    {
        "tvlUSD": 2820247904.4839215,
        "date": 1648771226,
        "volumeUSD": 1285300656.0887315
    },
    {
        "tvlUSD": 2846753247.881313,
        "date": 1648857626,
        "volumeUSD": 1143235885.0111544
    },
    {
        "tvlUSD": 2894431627.8028975,
        "date": 1648944026,
        "volumeUSD": 864149608.5742855
    },
    {
        "tvlUSD": 2885680762.5693913,
        "date": 1649030426,
        "volumeUSD": 1139776826.7623112
    },
    {
        "tvlUSD": 2895655708.8754983,
        "date": 1649116826,
        "volumeUSD": 1113147937.8087728
    },
    {
        "tvlUSD": 2758237092.331359,
        "date": 1649203226,
        "volumeUSD": 1938345820.5225325
    },
    {
        "tvlUSD": 2874762334.9221063,
        "date": 1649289626,
        "volumeUSD": 1251705749.504803
    },
    {
        "tvlUSD": 2788795017.7985625,
        "date": 1649376026,
        "volumeUSD": 1464848047.7088342
    },
    {
        "tvlUSD": 2924800301.5184617,
        "date": 1649462426,
        "volumeUSD": 724901694.1387686
    },
    {
        "tvlUSD": 2895975298.8527317,
        "date": 1649548826,
        "volumeUSD": 637970959.5812124
    },
    {
        "tvlUSD": 2675108984.6796513,
        "date": 1649635226,
        "volumeUSD": 2158789175.1834793
    },
    {
        "tvlUSD": 2758871819.3226724,
        "date": 1649721626,
        "volumeUSD": 1637002069.5645802
    },
    {
        "tvlUSD": 2808989648.9816456,
        "date": 1649808026,
        "volumeUSD": 1186758127.4106445
    },
    {
        "tvlUSD": 2840579427.8969865,
        "date": 1649894426,
        "volumeUSD": 1024870255.826102
    },
    {
        "tvlUSD": 2811247463.9634213,
        "date": 1649980826,
        "volumeUSD": 733668832.5313674
    },
    {
        "tvlUSD": 2864272218.4993787,
        "date": 1650067226,
        "volumeUSD": 326962124.511908
    },
    {
        "tvlUSD": 2773919124.1523347,
        "date": 1650153626,
        "volumeUSD": 897489326.3322748
    },
    {
        "tvlUSD": 2802566006.8837585,
        "date": 1650240026,
        "volumeUSD": 1075370132.3509574
    },
    {
        "tvlUSD": 2855466043.272305,
        "date": 1650326426,
        "volumeUSD": 834550692.1314799
    },
    {
        "tvlUSD": 2854785230.7855973,
        "date": 1650412826,
        "volumeUSD": 1004741608.3595713
    },
    {
        "tvlUSD": 2805708277.2218323,
        "date": 1650499226,
        "volumeUSD": 1077130357.7042327
    },
    {
        "tvlUSD": 2814828016.2416215,
        "date": 1650585626,
        "volumeUSD": 869354987.2708304
    },
    {
        "tvlUSD": 2852755337.5788546,
        "date": 1650672026,
        "volumeUSD": 382549123.0574638
    },
    {
        "tvlUSD": 2842922178.3135595,
        "date": 1650758426,
        "volumeUSD": 470288829.8995264
    },
    {
        "tvlUSD": 2849315925.6918697,
        "date": 1650844826,
        "volumeUSD": 1110928613.0834732
    },
    {
        "tvlUSD": 2768857002.992113,
        "date": 1650931226,
        "volumeUSD": 1092626948.8188148
    },
    {
        "tvlUSD": 2896296393.3711,
        "date": 1651017626,
        "volumeUSD": 880349349.317761
    },
    {
        "tvlUSD": 2861002240.4603558,
        "date": 1651104026,
        "volumeUSD": 1120379497.6880987
    },
    {
        "tvlUSD": 2804103574.0949974,
        "date": 1651190426,
        "volumeUSD": 998530637.1139485
    },
    {
        "tvlUSD": 2802131689.402956,
        "date": 1651276826,
        "volumeUSD": 1058783052.0074531
    },
    {
        "tvlUSD": 2859661503.9215536,
        "date": 1651363226,
        "volumeUSD": 1067788508.203417
    },
    {
        "tvlUSD": 2879189542.522255,
        "date": 1651449626,
        "volumeUSD": 1155834615.5189505
    },
    {
        "tvlUSD": 2869214935.264794,
        "date": 1651536026,
        "volumeUSD": 1108493250.0998943
    },
    {
        "tvlUSD": 2911013153.897671,
        "date": 1651622426,
        "volumeUSD": 1295662363.8915234
    },
    {
        "tvlUSD": 2817770023.862069,
        "date": 1651708826,
        "volumeUSD": 1533101828.165156
    },
    {
        "tvlUSD": 2828437705.842488,
        "date": 1651795226,
        "volumeUSD": 1832369335.9971256
    },
    {
        "tvlUSD": 2951491210.012463,
        "date": 1651881626,
        "volumeUSD": 867127968.8717363
    },
    {
        "tvlUSD": 2812357350.6040316,
        "date": 1651968026,
        "volumeUSD": 1678348451.1064196
    },
    {
        "tvlUSD": 2388312884.45484,
        "date": 1652054426,
        "volumeUSD": 2906342207.9795628
    },
    {
        "tvlUSD": 2459134284.5134034,
        "date": 1652140826,
        "volumeUSD": 2469483019.03847
    },
    {
        "tvlUSD": 1972572498.6913044,
        "date": 1652227226,
        "volumeUSD": 4156223561.6533775
    },
    {
        "tvlUSD": 1694880966.0567045,
        "date": 1652313626,
        "volumeUSD": 4352129734.730329
    },
    {
        "tvlUSD": 2115538113.7909458,
        "date": 1652400026,
        "volumeUSD": 2043166917.6428208
    },
    {
        "tvlUSD": 2255584263.5877886,
        "date": 1652486426,
        "volumeUSD": 1228970447.6544976
    },
    {
        "tvlUSD": 2333397637.2760305,
        "date": 1652572826,
        "volumeUSD": 937984180.6501204
    },
    {
        "tvlUSD": 2226449487.68546,
        "date": 1652659226,
        "volumeUSD": 1835677032.381413
    },
    {
        "tvlUSD": 2358444077.1478505,
        "date": 1652745626,
        "volumeUSD": 1076693862.6550224
    },
    {
        "tvlUSD": 2255970821.485074,
        "date": 1652832026,
        "volumeUSD": 1275480686.3192973
    },
    {
        "tvlUSD": 2484369213.851607,
        "date": 1652918426,
        "volumeUSD": 1540562960.6424255
    },
    {
        "tvlUSD": 2589866949.6535444,
        "date": 1653004826,
        "volumeUSD": 1219344057.6688697
    },
    {
        "tvlUSD": 2652421948.900086,
        "date": 1653091226,
        "volumeUSD": 587444271.2955307
    },
    {
        "tvlUSD": 2689941642.5092754,
        "date": 1653177626,
        "volumeUSD": 651586891.6003066
    },
    {
        "tvlUSD": 2617242202.7981644,
        "date": 1653264026,
        "volumeUSD": 1154688613.5136168
    },
    {
        "tvlUSD": 2667989092.3384094,
        "date": 1653350426,
        "volumeUSD": 843508322.765552
    },
    {
        "tvlUSD": 2638721411.336375,
        "date": 1653436826,
        "volumeUSD": 1016587055.9756162
    },
    {
        "tvlUSD": 2470878399.8001804,
        "date": 1653523226,
        "volumeUSD": 2087475640.635502
    },
    {
        "tvlUSD": 2485582686.664398,
        "date": 1653609626,
        "volumeUSD": 1612664616.83323
    },
    {
        "tvlUSD": 2632983609.244071,
        "date": 1653696026,
        "volumeUSD": 953622579.7200521
    },
    {
        "tvlUSD": 2673335161.2896914,
        "date": 1653782426,
        "volumeUSD": 514424177.3147441
    },
    {
        "tvlUSD": 2668786853.929812,
        "date": 1653868826,
        "volumeUSD": 1424020969.88664
    },
    {
        "tvlUSD": 2735600412.4290023,
        "date": 1653955226,
        "volumeUSD": 1054862861.8595564
    },
    {
        "tvlUSD": 2651428236.1087484,
        "date": 1654041626,
        "volumeUSD": 1199057563.1493902
    },
    {
        "tvlUSD": 2712892690.2724113,
        "date": 1654128026,
        "volumeUSD": 992165795.6306852
    },
    {
        "tvlUSD": 2707353003.211633,
        "date": 1654214426,
        "volumeUSD": 829386230.9623302
    },
    {
        "tvlUSD": 2768319498.8893676,
        "date": 1654300826,
        "volumeUSD": 569165735.7830799
    },
    {
        "tvlUSD": 2808009997.8830533,
        "date": 1654387226,
        "volumeUSD": 571974450.7027239
    },
    {
        "tvlUSD": 2853590711.7119894,
        "date": 1654473626,
        "volumeUSD": 909580807.8546197
    },
    {
        "tvlUSD": 2813287978.067275,
        "date": 1654560026,
        "volumeUSD": 1426336575.457398
    },
    {
        "tvlUSD": 2918335755.867527,
        "date": 1654646426,
        "volumeUSD": 1098143855.8228383
    },
    {
        "tvlUSD": 2942083035.4646497,
        "date": 1654732826,
        "volumeUSD": 639139714.1489795
    },
    {
        "tvlUSD": 2845704981.5327606,
        "date": 1654819226,
        "volumeUSD": 1265172855.2972732
    },
    {
        "tvlUSD": 2681315790.0733714,
        "date": 1654905626,
        "volumeUSD": 1332713243.8280604
    },
    {
        "tvlUSD": 2563120792.8713236,
        "date": 1654992026,
        "volumeUSD": 1573130152.2887611
    },
    {
        "tvlUSD": 2121820262.98678,
        "date": 1655078426,
        "volumeUSD": 3603773608.3819103
    },
    {
        "tvlUSD": 2162491317.031955,
        "date": 1655164826,
        "volumeUSD": 2565669859.570125
    },
    {
        "tvlUSD": 2153225483.925165,
        "date": 1655251226,
        "volumeUSD": 2948744592.58433
    },
    {
        "tvlUSD": 2353559665.5560274,
        "date": 1655337626,
        "volumeUSD": 1564863669.736199
    },
    {
        "tvlUSD": 2447760887.3691893,
        "date": 1655424026,
        "volumeUSD": 1279685477.949577
    },
    {
        "tvlUSD": 2268985804.467822,
        "date": 1655510426,
        "volumeUSD": 1748308120.1524262
    },
    {
        "tvlUSD": 2348568843.0569916,
        "date": 1655596826,
        "volumeUSD": 1679393189.516067
    },
    {
        "tvlUSD": 2397207504.8243904,
        "date": 1655683226,
        "volumeUSD": 1428171140.0634565
    },
    {
        "tvlUSD": 2469613469.7373524,
        "date": 1655769626,
        "volumeUSD": 1161671300.2684305
    },
    {
        "tvlUSD": 2457284167.7013297,
        "date": 1655856026,
        "volumeUSD": 1017234004.580086
    },
    {
        "tvlUSD": 2577186765.4178834,
        "date": 1655942426,
        "volumeUSD": 1046300430.3801208
    },
    {
        "tvlUSD": 2656918710.965927,
        "date": 1656028826,
        "volumeUSD": 991545245.6986133
    },
    {
        "tvlUSD": 2714871694.3840423,
        "date": 1656115226,
        "volumeUSD": 585983013.0555121
    },
    {
        "tvlUSD": 2704629646.190422,
        "date": 1656201626,
        "volumeUSD": 614769775.60113
    },
    {
        "tvlUSD": 2716499499.812571,
        "date": 1656288026,
        "volumeUSD": 592280118.5479758
    },
    {
        "tvlUSD": 2661713805.157374,
        "date": 1656374426,
        "volumeUSD": 758586934.0564826
    },
    {
        "tvlUSD": 2584184514.333293,
        "date": 1656460826,
        "volumeUSD": 953320431.2188321
    },
    {
        "tvlUSD": 2570541719.745221,
        "date": 1656547226,
        "volumeUSD": 1061608476.1184348
    },
    {
        "tvlUSD": 2605238787.2822604,
        "date": 1656633626,
        "volumeUSD": 1191803515.2615027
    },
    {
        "tvlUSD": 2702505343.587177,
        "date": 1656720026,
        "volumeUSD": 570216110.1702889
    },
    {
        "tvlUSD": 2732716771.441391,
        "date": 1656806426,
        "volumeUSD": 521848297.32590705
    },
    {
        "tvlUSD": 2760985162.388487,
        "date": 1656892826,
        "volumeUSD": 818389672.4686211
    },
    {
        "tvlUSD": 2735877794.493621,
        "date": 1656979226,
        "volumeUSD": 1050668787.5619421
    },
    {
        "tvlUSD": 2758797602.490516,
        "date": 1657065626,
        "volumeUSD": 1069111134.4091238
    },
    {
        "tvlUSD": 2787268370.114995,
        "date": 1657152026,
        "volumeUSD": 845010276.5258014
    },
    {
        "tvlUSD": 2738972472.0076413,
        "date": 1657238426,
        "volumeUSD": 1096762554.4793904
    },
    {
        "tvlUSD": 2839916690.1821246,
        "date": 1657324826,
        "volumeUSD": 485005320.3710405
    },
    {
        "tvlUSD": 2703834428.9189324,
        "date": 1657411226,
        "volumeUSD": 651257996.9358499
    },
    {
        "tvlUSD": 2623416760.3770456,
        "date": 1657497626,
        "volumeUSD": 751776599.6632723
    },
    {
        "tvlUSD": 2593006054.2467976,
        "date": 1657584026,
        "volumeUSD": 788462886.0513372
    },
    {
        "tvlUSD": 2587336743.091187,
        "date": 1657670426,
        "volumeUSD": 986939577.0681496
    },
    {
        "tvlUSD": 2658002560.6795096,
        "date": 1657756826,
        "volumeUSD": 883826408.7398747
    },
    {
        "tvlUSD": 2681862466.4091883,
        "date": 1657843226,
        "volumeUSD": 1118913499.5780098
    },
    {
        "tvlUSD": 2754034208.1783676,
        "date": 1657929626,
        "volumeUSD": 1043707893.2733295
    },
    {
        "tvlUSD": 2924112711.664471,
        "date": 1658016026,
        "volumeUSD": 1013596912.8033736
    },
    {
        "tvlUSD": 3097507959.953189,
        "date": 1658102426,
        "volumeUSD": 1531277368.8997877
    },
    {
        "tvlUSD": 3060700065.194364,
        "date": 1658188826,
        "volumeUSD": 1711881208.9236884
    },
    {
        "tvlUSD": 3057766035.0861683,
        "date": 1658275226,
        "volumeUSD": 1443713681.2891147
    },
    {
        "tvlUSD": 3068724190.7074857,
        "date": 1658361626,
        "volumeUSD": 1174111160.031432
    },
    {
        "tvlUSD": 3244850364.1613646,
        "date": 1658448026,
        "volumeUSD": 999167872.683258
    },
    {
        "tvlUSD": 3311580992.7903323,
        "date": 1658534426,
        "volumeUSD": 812771830.5001329
    },
    {
        "tvlUSD": 3338795371.177486,
        "date": 1658620826,
        "volumeUSD": 890028503.633959
    },
    {
        "tvlUSD": 3235584960.4057865,
        "date": 1658707226,
        "volumeUSD": 1185508458.5900366
    },
    {
        "tvlUSD": 3367740894.150873,
        "date": 1658793626,
        "volumeUSD": 1127548947.4696293
    },
    {
        "tvlUSD": 3437376303.9677043,
        "date": 1658880026,
        "volumeUSD": 1379913454.7664416
    },
    {
        "tvlUSD": 3453727089.628558,
        "date": 1658966426,
        "volumeUSD": 1587544559.524809
    },
    {
        "tvlUSD": 3483479971.050142,
        "date": 1659052826,
        "volumeUSD": 1282573216.0623946
    },
    {
        "tvlUSD": 3521448480.8546667,
        "date": 1659139226,
        "volumeUSD": 841103714.3255589
    },
    {
        "tvlUSD": 3515743983.874753,
        "date": 1659225626,
        "volumeUSD": 712054916.6060734
    },
    {
        "tvlUSD": 3485768004.3959312,
        "date": 1659312026,
        "volumeUSD": 996963116.7239199
    },
    {
        "tvlUSD": 3322182818.563367,
        "date": 1659398426,
        "volumeUSD": 1326788879.364186
    },
    {
        "tvlUSD": 3376072900.0950074,
        "date": 1659484826,
        "volumeUSD": 839678512.1359489
    },
    {
        "tvlUSD": 3410196643.6316347,
        "date": 1659571226,
        "volumeUSD": 818442088.9673431
    },
    {
        "tvlUSD": 3499128910.285195,
        "date": 1659657626,
        "volumeUSD": 1046916044.7383614
    },
    {
        "tvlUSD": 3549218476.8002515,
        "date": 1659744026,
        "volumeUSD": 502906976.255197
    },
    {
        "tvlUSD": 3551543470.027008,
        "date": 1659830426,
        "volumeUSD": 494839594.845162
    },
    {
        "tvlUSD": 3422592435.462857,
        "date": 1659916826,
        "volumeUSD": 1043467193.5655762
    },
    {
        "tvlUSD": 3395008393.6426954,
        "date": 1660003226,
        "volumeUSD": 1019958077.9730603
    },
    {
        "tvlUSD": 3463340699.410275,
        "date": 1660089626,
        "volumeUSD": 1367615720.4443538
    },
    {
        "tvlUSD": 3428267743.247529,
        "date": 1660176026,
        "volumeUSD": 1491941556.8211298
    },
    {
        "tvlUSD": 3327554368.8591213,
        "date": 1660262426,
        "volumeUSD": 849717578.9715722
    },
    {
        "tvlUSD": 3334788849.1170826,
        "date": 1660348826,
        "volumeUSD": 904791847.3759903
    },
    {
        "tvlUSD": 3294941925.146011,
        "date": 1660435226,
        "volumeUSD": 755065162.195561
    },
    {
        "tvlUSD": 3280284151.4668827,
        "date": 1660521626,
        "volumeUSD": 1254880720.0687377
    },
    {
        "tvlUSD": 3309856410.592323,
        "date": 1660608026,
        "volumeUSD": 890830297.9292022
    },
    {
        "tvlUSD": 3242923715.1834173,
        "date": 1660694426,
        "volumeUSD": 1227402032.2516582
    },
    {
        "tvlUSD": 3273145927.472503,
        "date": 1660780826,
        "volumeUSD": 733707509.1270413
    },
    {
        "tvlUSD": 3064490561.757353,
        "date": 1660867226,
        "volumeUSD": 1601814162.285086
    },
    {
        "tvlUSD": 3046530024.9489827,
        "date": 1660953626,
        "volumeUSD": 1141134708.8567758
    },
    {
        "tvlUSD": 3078725034.037739,
        "date": 1661040026,
        "volumeUSD": 918758383.2902243
    },
    {
        "tvlUSD": 2990713420.5633082,
        "date": 1661126426,
        "volumeUSD": 994864328.8088484
    },
    {
        "tvlUSD": 3018843374.792723,
        "date": 1661212826,
        "volumeUSD": 1332445420.8910928
    },
    {
        "tvlUSD": 3052598535.631458,
        "date": 1661299226,
        "volumeUSD": 873902910.6606069
    },
    {
        "tvlUSD": 3118317938.561766,
        "date": 1661385626,
        "volumeUSD": 653607802.7606837
    },
    {
        "tvlUSD": 2960323832.051852,
        "date": 1661472026,
        "volumeUSD": 1190880682.1673553
    },
    {
        "tvlUSD": 3013458574.7978487,
        "date": 1661558426,
        "volumeUSD": 828236646.5732113
    },
    {
        "tvlUSD": 2966562355.5872855,
        "date": 1661644826,
        "volumeUSD": 797794828.2430816
    },
    {
        "tvlUSD": 3093825081.7015023,
        "date": 1661731226,
        "volumeUSD": 931492474.3564607
    },
    {
        "tvlUSD": 3084957094.677742,
        "date": 1661817626,
        "volumeUSD": 1155387153.2555985
    },
    {
        "tvlUSD": 3049602851.624129,
        "date": 1661904026,
        "volumeUSD": 1272797383.9122179
    },
    {
        "tvlUSD": 3102529682.1540933,
        "date": 1661990426,
        "volumeUSD": 943159407.4974179
    },
    {
        "tvlUSD": 3133648554.38093,
        "date": 1662076826,
        "volumeUSD": 1007767654.5406164
    },
    {
        "tvlUSD": 3180778200.122001,
        "date": 1662163226,
        "volumeUSD": 496969320.4773746
    },
    {
        "tvlUSD": 3128435543.625758,
        "date": 1662249626,
        "volumeUSD": 478388248.98204285
    },
    {
        "tvlUSD": 3066941397.4235396,
        "date": 1662336026,
        "volumeUSD": 661461026.1457926
    },
    {
        "tvlUSD": 2938563505.8576636,
        "date": 1662422426,
        "volumeUSD": 1140434728.80514
    },
    {
        "tvlUSD": 2944342380.5214844,
        "date": 1662508826,
        "volumeUSD": 1103711770.7370536
    },
    {
        "tvlUSD": 2987463959.270287,
        "date": 1662595226,
        "volumeUSD": 801096531.8177975
    },
    {
        "tvlUSD": 3030218426.551502,
        "date": 1662681626,
        "volumeUSD": 882936820.9098916
    },
    {
        "tvlUSD": 3090594114.33931,
        "date": 1662768026,
        "volumeUSD": 682818988.6081303
    },
    {
        "tvlUSD": 3012996459.2827935,
        "date": 1662854426,
        "volumeUSD": 563316409.7175266
    },
    {
        "tvlUSD": 2950765490.7112308,
        "date": 1662940826,
        "volumeUSD": 878836733.3557179
    },
    {
        "tvlUSD": 2771747884.3880825,
        "date": 1663027226,
        "volumeUSD": 1499972511.012376
    },
    {
        "tvlUSD": 2772645227.592295,
        "date": 1663113626,
        "volumeUSD": 1006889815.98013
    },
    {
        "tvlUSD": 2725321172.8420086,
        "date": 1663200026,
        "volumeUSD": 1218240496.2011967
    },
    {
        "tvlUSD": 2806478101.8864784,
        "date": 1663286426,
        "volumeUSD": 732242588.9924132
    },
    {
        "tvlUSD": 2879145604.488681,
        "date": 1663372826,
        "volumeUSD": 373613177.8996359
    },
    {
        "tvlUSD": 2804844269.042993,
        "date": 1663459226,
        "volumeUSD": 458996401.18864167
    },
    {
        "tvlUSD": 2874673648.9141383,
        "date": 1663545626,
        "volumeUSD": 782849247.3688972
    },
    {
        "tvlUSD": 2827013367.5553226,
        "date": 1663632026,
        "volumeUSD": 682213842.424797
    },
    {
        "tvlUSD": 2704754672.079814,
        "date": 1663718426,
        "volumeUSD": 933321385.2013565
    },
    {
        "tvlUSD": 2858314483.770319,
        "date": 1663804826,
        "volumeUSD": 751409105.8249605
    },
    {
        "tvlUSD": 2863740105.904813,
        "date": 1663891226,
        "volumeUSD": 783777402.4276761
    },
    {
        "tvlUSD": 2910759932.160955,
        "date": 1663977626,
        "volumeUSD": 467426405.65676945
    },
    {
        "tvlUSD": 2899650571.9812083,
        "date": 1664064026,
        "volumeUSD": 361833123.05761665
    },
    {
        "tvlUSD": 2918593982.67056,
        "date": 1664150426,
        "volumeUSD": 659080813.5671808
    },
    {
        "tvlUSD": 2906735561.0424495,
        "date": 1664236826,
        "volumeUSD": 824818334.2182509
    },
    {
        "tvlUSD": 2893949551.873832,
        "date": 1664323226,
        "volumeUSD": 904152552.6041116
    },
    {
        "tvlUSD": 2944623284.961047,
        "date": 1664409626,
        "volumeUSD": 693293598.2152965
    },
    {
        "tvlUSD": 2905923202.6518993,
        "date": 1664496026,
        "volumeUSD": 893080756.8598434
    },
    {
        "tvlUSD": 2962402974.875984,
        "date": 1664582426,
        "volumeUSD": 439109576.8017687
    },
    {
        "tvlUSD": 2905788238.926913,
        "date": 1664668826,
        "volumeUSD": 366759363.81059206
    },
    {
        "tvlUSD": 2931406402.8549056,
        "date": 1664755226,
        "volumeUSD": 687897607.2795725
    },
    {
        "tvlUSD": 2952777785.4587784,
        "date": 1664841626,
        "volumeUSD": 567491729.0241594
    },
    {
        "tvlUSD": 2927210077.05314,
        "date": 1664928026,
        "volumeUSD": 526606538.7295947
    },
    {
        "tvlUSD": 2899852016.0536623,
        "date": 1665014426,
        "volumeUSD": 829016396.6876241
    },
    {
        "tvlUSD": 2935506717.319064,
        "date": 1665100826,
        "volumeUSD": 448694749.6166093
    },
    {
        "tvlUSD": 2936823101.883309,
        "date": 1665187226,
        "volumeUSD": 222136886.84035376
    },
    {
        "tvlUSD": 2939403181.633249,
        "date": 1665273626,
        "volumeUSD": 235451846.7870334
    },
    {
        "tvlUSD": 2895088604.3301973,
        "date": 1665360026,
        "volumeUSD": 409495446.63932854
    },
    {
        "tvlUSD": 2874879162.3549047,
        "date": 1665446426,
        "volumeUSD": 515166359.6737446
    },
    {
        "tvlUSD": 2830107896.0675564,
        "date": 1665532826,
        "volumeUSD": 254473826.9071851
    },
    {
        "tvlUSD": 2459962042.388774,
        "date": 1665619226,
        "volumeUSD": 803897455.581015
    },
    {
        "tvlUSD": 2480220639.9819984,
        "date": 1665705626,
        "volumeUSD": 782117774.299311
    },
    {
        "tvlUSD": 2528908732.413182,
        "date": 1665792026,
        "volumeUSD": 343661738.1396375
    },
    {
        "tvlUSD": 2541382999.175831,
        "date": 1665878426,
        "volumeUSD": 254866604.2153171
    },
    {
        "tvlUSD": 2520205402.515642,
        "date": 1665964826,
        "volumeUSD": 671970960.8404362
    },
    {
        "tvlUSD": 2511003499.7639174,
        "date": 1666051226,
        "volumeUSD": 610371719.9477043
    },
    {
        "tvlUSD": 2383349003.8798923,
        "date": 1666137626,
        "volumeUSD": 435391188.0173174
    },
    {
        "tvlUSD": 2383909183.460238,
        "date": 1666224026,
        "volumeUSD": 409772486.974655
    },
    {
        "tvlUSD": 2397714612.546187,
        "date": 1666310426,
        "volumeUSD": 587036640.7129117
    },
    {
        "tvlUSD": 2430769429.925637,
        "date": 1666396826,
        "volumeUSD": 242991321.05746028
    },
    {
        "tvlUSD": 2444363124.4274507,
        "date": 1666483226,
        "volumeUSD": 408076907.4841852
    },
    {
        "tvlUSD": 2423577340.996113,
        "date": 1666569626,
        "volumeUSD": 628288265.6478722
    },
    {
        "tvlUSD": 2400655792.336627,
        "date": 1666656026,
        "volumeUSD": 1374323912.7186894
    },
    {
        "tvlUSD": 2440855430.2610064,
        "date": 1666742426,
        "volumeUSD": 1277118545.545397
    },
    {
        "tvlUSD": 2446949321.068932,
        "date": 1666828826,
        "volumeUSD": 1015609277.5242933
    },
    {
        "tvlUSD": 2416017259.3352757,
        "date": 1666915226,
        "volumeUSD": 1054520684.9955096
    },
    {
        "tvlUSD": 2416375709.278814,
        "date": 1667001626,
        "volumeUSD": 1091778723.014478
    },
    {
        "tvlUSD": 2511485780.5636873,
        "date": 1667088026,
        "volumeUSD": 733732238.2286717
    },
    {
        "tvlUSD": 2460220654.642725,
        "date": 1667174426,
        "volumeUSD": 1053484624.3934289
    },
    {
        "tvlUSD": 2494436820.1268277,
        "date": 1667260826,
        "volumeUSD": 597022521.1767565
    },
    {
        "tvlUSD": 2348529163.460553,
        "date": 1667347226,
        "volumeUSD": 1337228524.0792994
    },
    {
        "tvlUSD": 2433679638.82251,
        "date": 1667433626,
        "volumeUSD": 558993719.8272688
    },
    {
        "tvlUSD": 2509084018.787423,
        "date": 1667520026,
        "volumeUSD": 1263798407.0678046
    },
    {
        "tvlUSD": 2584190921.67375,
        "date": 1667606426,
        "volumeUSD": 494973101.64104307
    },
    {
        "tvlUSD": 2591323687.968874,
        "date": 1667692826,
        "volumeUSD": 584564864.2638543
    },
    {
        "tvlUSD": 2656121054.4753575,
        "date": 1667779226,
        "volumeUSD": 1038066007.0440981
    },
    {
        "tvlUSD": 2216195919.3480587,
        "date": 1667865626,
        "volumeUSD": 3585003740.352808
    },
    {
        "tvlUSD": 1919856347.956949,
        "date": 1667952026,
        "volumeUSD": 4474306002.555418
    },
    {
        "tvlUSD": 2130791654.455018,
        "date": 1668038426,
        "volumeUSD": 4110244261.917099
    },
    {
        "tvlUSD": 2264332618.81042,
        "date": 1668124826,
        "volumeUSD": 2071648392.8890667
    },
    {
        "tvlUSD": 2317609285.3941236,
        "date": 1668211226,
        "volumeUSD": 1171834107.8754635
    },
    {
        "tvlUSD": 2348583896.0966916,
        "date": 1668297626,
        "volumeUSD": 838348215.3955564
    },
    {
        "tvlUSD": 2326357028.9905415,
        "date": 1668384026,
        "volumeUSD": 1348978857.3158915
    },
    {
        "tvlUSD": 2355666228.1938515,
        "date": 1668470426,
        "volumeUSD": 899569309.7223747
    },
    {
        "tvlUSD": 2344813184.4631286,
        "date": 1668556826,
        "volumeUSD": 928747388.2396516
    },
    {
        "tvlUSD": 2348386008.869596,
        "date": 1668643226,
        "volumeUSD": 617406133.2052999
    },
    {
        "tvlUSD": 2375857654.4242206,
        "date": 1668729626,
        "volumeUSD": 447310656.7458017
    },
    {
        "tvlUSD": 2389343715.725271,
        "date": 1668816026,
        "volumeUSD": 283108009.0633413
    },
    {
        "tvlUSD": 2313831963.254103,
        "date": 1668902426,
        "volumeUSD": 709981487.1073118
    },
    {
        "tvlUSD": 2272019353.1159863,
        "date": 1668988826,
        "volumeUSD": 1211434383.9874349
    },
    {
        "tvlUSD": 2350862673.5710936,
        "date": 1669075226,
        "volumeUSD": 986171612.9489564
    },
    {
        "tvlUSD": 2127553511.645175,
        "date": 1669161626,
        "volumeUSD": 782505741.9739548
    },
    {
        "tvlUSD": 2155260947.705372,
        "date": 1669248026,
        "volumeUSD": 587246254.8330688
    },
    {
        "tvlUSD": 2150924602.393075,
        "date": 1669334426,
        "volumeUSD": 553367999.341821
    },
    {
        "tvlUSD": 2142465687.275827,
        "date": 1669420826,
        "volumeUSD": 423691564.5829189
    },
    {
        "tvlUSD": 2086866674.7858794,
        "date": 1669507226,
        "volumeUSD": 314236387.46007353
    },
    {
        "tvlUSD": 2062144884.6498432,
        "date": 1669593626,
        "volumeUSD": 560674660.1952097
    },
    {
        "tvlUSD": 2091090645.3242207,
        "date": 1669680026,
        "volumeUSD": 463403051.52837175
    },
    {
        "tvlUSD": 2035368587.184208,
        "date": 1669766426,
        "volumeUSD": 1029008129.9132518
    },
    {
        "tvlUSD": 2081141661.433267,
        "date": 1669852826,
        "volumeUSD": 493726182.1067959
    },
    {
        "tvlUSD": 2094645474.3683674,
        "date": 1669939226,
        "volumeUSD": 505121348.2832901
    },
    {
        "tvlUSD": 2071262939.2067583,
        "date": 1670025626,
        "volumeUSD": 338734846.84895587
    },
    {
        "tvlUSD": 2097898786.9892268,
        "date": 1670112026,
        "volumeUSD": 341899270.1823315
    },
    {
        "tvlUSD": 2074584600.0742455,
        "date": 1670198426,
        "volumeUSD": 489148751.10245377
    },
    {
        "tvlUSD": 2106188593.1958377,
        "date": 1670284826,
        "volumeUSD": 569581982.4616877
    },
    {
        "tvlUSD": 2099549645.1467094,
        "date": 1670371226,
        "volumeUSD": 416674279.4829722
    },
    {
        "tvlUSD": 2148803929.5247097,
        "date": 1670457626,
        "volumeUSD": 480126998.9374291
    },
    {
        "tvlUSD": 2160155395.3757734,
        "date": 1670544026,
        "volumeUSD": 466175599.9821989
    },
    {
        "tvlUSD": 2176973061.4978595,
        "date": 1670630426,
        "volumeUSD": 223316622.89842597
    },
    {
        "tvlUSD": 2177387068.9776015,
        "date": 1670716826,
        "volumeUSD": 230020502.80643928
    },
    {
        "tvlUSD": 2175199557.040664,
        "date": 1670803226,
        "volumeUSD": 419265512.71987015
    },
    {
        "tvlUSD": 2167394095.5224886,
        "date": 1670889626,
        "volumeUSD": 1049709222.0763106
    },
    {
        "tvlUSD": 2184071301.6536365,
        "date": 1670976026,
        "volumeUSD": 730660500.7251393
    },
    {
        "tvlUSD": 2149679525.2245197,
        "date": 1671062426,
        "volumeUSD": 560468811.1460141
    },
    {
        "tvlUSD": 2035648843.5514426,
        "date": 1671148826,
        "volumeUSD": 1044869601.9671439
    },
    {
        "tvlUSD": 2066041495.5215716,
        "date": 1671235226,
        "volumeUSD": 539930281.1316581
    },
    {
        "tvlUSD": 2082181311.0492911,
        "date": 1671321626,
        "volumeUSD": 254032373.60454097
    },
    {
        "tvlUSD": 2049222574.4166725,
        "date": 1671408026,
        "volumeUSD": 465952995.3210056
    },
    {
        "tvlUSD": 2072851583.0140285,
        "date": 1671494426,
        "volumeUSD": 543195085.9422638
    },
    {
        "tvlUSD": 2131135240.768549,
        "date": 1671580826,
        "volumeUSD": 329857789.18001455
    },
    {
        "tvlUSD": 2088953888.7224321,
        "date": 1671667226,
        "volumeUSD": 385427126.1501339
    },
    {
        "tvlUSD": 2106294807.2418375,
        "date": 1671753626,
        "volumeUSD": 399797270.2341555
    },
    {
        "tvlUSD": 2118764251.8995178,
        "date": 1671840026,
        "volumeUSD": 117473704.07058842
    },
    {
        "tvlUSD": 2105487446.4268315,
        "date": 1671926426,
        "volumeUSD": 318090204.6621726
    },
    {
        "tvlUSD": 2148276795.889381,
        "date": 1672012826,
        "volumeUSD": 245250962.0086858
    },
    {
        "tvlUSD": 2103600853.4545646,
        "date": 1672099226,
        "volumeUSD": 384393455.17984635
    },
    {
        "tvlUSD": 2081037190.3444068,
        "date": 1672185626,
        "volumeUSD": 477506031.93616015
    },
    {
        "tvlUSD": 2102648157.0784016,
        "date": 1672272026,
        "volumeUSD": 355807637.8002287
    },
    {
        "tvlUSD": 2111766899.1461024,
        "date": 1672358426,
        "volumeUSD": 228319197.96264714
    },
    {
        "tvlUSD": 2064280935.4507241,
        "date": 1672444826,
        "volumeUSD": 235720797.61577812
    },
    {
        "tvlUSD": 2084929244.1195853,
        "date": 1672531226,
        "volumeUSD": 213935510.40625975
    },
    {
        "tvlUSD": 2082851002.6289215,
        "date": 1672617626,
        "volumeUSD": 302311422.17756224
    },
    {
        "tvlUSD": 2111473824.7847843,
        "date": 1672704026,
        "volumeUSD": 224715498.0522646
    },
    {
        "tvlUSD": 2088001814.6572907,
        "date": 1672790426,
        "volumeUSD": 591224903.3190174
    },
    {
        "tvlUSD": 2119982788.4832695,
        "date": 1672876826,
        "volumeUSD": 329565125.1431183
    },
    {
        "tvlUSD": 2124424727.4730992,
        "date": 1672963226,
        "volumeUSD": 508356980.3054224
    },
    {
        "tvlUSD": 2132109970.495781,
        "date": 1673049626,
        "volumeUSD": 194548956.03531042
    },
    {
        "tvlUSD": 2134827798.8275027,
        "date": 1673136026,
        "volumeUSD": 348794839.64057136
    },
    {
        "tvlUSD": 2083763618.2276735,
        "date": 1673222426,
        "volumeUSD": 839501034.4170777
    },
    {
        "tvlUSD": 2116480653.1873634,
        "date": 1673308826,
        "volumeUSD": 509387909.6811704
    },
    {
        "tvlUSD": 2119134013.1192198,
        "date": 1673395226,
        "volumeUSD": 598135971.1537551
    },
    {
        "tvlUSD": 2102446565.9121914,
        "date": 1673481626,
        "volumeUSD": 1144105850.5373793
    },
    {
        "tvlUSD": 2151779106.326878,
        "date": 1673568026,
        "volumeUSD": 1138176736.0764277
    },
    {
        "tvlUSD": 2082394069.9613428,
        "date": 1673654426,
        "volumeUSD": 1427016889.8864586
    },
    {
        "tvlUSD": 2140043201.454879,
        "date": 1673740826,
        "volumeUSD": 749554550.4228003
    },
    {
        "tvlUSD": 2143957673.0288618,
        "date": 1673827226,
        "volumeUSD": 956939534.621677
    },
    {
        "tvlUSD": 2178761143.377804,
        "date": 1673913626,
        "volumeUSD": 800246043.9240414
    },
    {
        "tvlUSD": 2093512355.4893382,
        "date": 1674000026,
        "volumeUSD": 1391457817.5984726
    },
    {
        "tvlUSD": 2258215558.1959996,
        "date": 1674086426,
        "volumeUSD": 661073593.6433861
    },
    {
        "tvlUSD": 2304833360.8514957,
        "date": 1674172826,
        "volumeUSD": 763417367.5409187
    },
    {
        "tvlUSD": 2286152934.14318,
        "date": 1674259226,
        "volumeUSD": 980449811.8855027
    },
    {
        "tvlUSD": 2239852931.3826632,
        "date": 1674345626,
        "volumeUSD": 747409231.5183585
    },
    {
        "tvlUSD": 2216957790.834321,
        "date": 1674432026,
        "volumeUSD": 772965097.8362261
    },
    {
        "tvlUSD": 2200517054.3892403,
        "date": 1674518426,
        "volumeUSD": 849208027.4313921
    },
    {
        "tvlUSD": 2201246667.1996684,
        "date": 1674604826,
        "volumeUSD": 1350789991.9104092
    },
    {
        "tvlUSD": 2236450762.119398,
        "date": 1674691226,
        "volumeUSD": 823433282.5320127
    },
    {
        "tvlUSD": 2227069802.4239173,
        "date": 1674777626,
        "volumeUSD": 1063820707.9564393
    },
    {
        "tvlUSD": 2233628446.6470513,
        "date": 1674864026,
        "volumeUSD": 549884357.4827117
    },
    {
        "tvlUSD": 2258786924.1421247,
        "date": 1674950426,
        "volumeUSD": 716980831.0799748
    },
    {
        "tvlUSD": 2203145488.4970336,
        "date": 1675036826,
        "volumeUSD": 917050560.5053012
    },
    {
        "tvlUSD": 2239692227.274338,
        "date": 1675123226,
        "volumeUSD": 599541089.5618753
    },
    {
        "tvlUSD": 2255525792.763941,
        "date": 1675209626,
        "volumeUSD": 867640522.9195127
    },
    {
        "tvlUSD": 2234561946.62787,
        "date": 1675296026,
        "volumeUSD": 1239574957.5699606
    },
    {
        "tvlUSD": 2298253387.874906,
        "date": 1675382426,
        "volumeUSD": 838898805.475539
    },
    {
        "tvlUSD": 2317428328.430945,
        "date": 1675468826,
        "volumeUSD": 509421483.3660044
    },
    {
        "tvlUSD": 2291202828.9466143,
        "date": 1675555226,
        "volumeUSD": 711303241.0300934
    },
    {
        "tvlUSD": 2286330347.083134,
        "date": 1675641626,
        "volumeUSD": 701656828.2751195
    },
    {
        "tvlUSD": 2309338775.1743455,
        "date": 1675728026,
        "volumeUSD": 931725082.4866567
    },
    {
        "tvlUSD": 2300066952.3789115,
        "date": 1675814426,
        "volumeUSD": 829375240.4387593
    },
    {
        "tvlUSD": 2274872053.620605,
        "date": 1675900826,
        "volumeUSD": 1211512086.9343107
    },
    {
        "tvlUSD": 2282989409.1384287,
        "date": 1675987226,
        "volumeUSD": 999245344.4504162
    },
    {
        "tvlUSD": 2339144031.061533,
        "date": 1676073626,
        "volumeUSD": 570464018.3014183
    },
    {
        "tvlUSD": 2324095052.4808598,
        "date": 1676160026,
        "volumeUSD": 591785002.8643454
    },
    {
        "tvlUSD": 2265794984.036261,
        "date": 1676246426,
        "volumeUSD": 1122846038.376187
    },
    {
        "tvlUSD": 2301709050.732748,
        "date": 1676332826,
        "volumeUSD": 1061647704.685217
    },
    {
        "tvlUSD": 2391415429.736324,
        "date": 1676419226,
        "volumeUSD": 1043469083.1903715
    },
    {
        "tvlUSD": 2428842641.6823277,
        "date": 1676505626,
        "volumeUSD": 584111775.7345587
    }
]