import assets from "../../../assets";
import { CircledImageCtr } from "components/Logo";
import React from "react";
import styled from "styled-components";

const _renderSocialLinkIcon = (orgName) => {
  switch (orgName) {
    case "opensea":
      return assets.logos.openseaLogo;
    case "rarible":
      return assets.logos.raribleLogo;
    case "magiceden":
      return assets.logos.magicEdenLogo;
    case "oneplanet":
      return assets.logos.onelanetNFTLogo;
    case "nfttrade":
      return assets.logos.nftradeLogo;
    default:
      return assets.logos.openseaLogo;
  }
};
const SocialLinks = ({ links }) => {
  return (
    <SocialLinksCtr>
      {Object.keys(links).map(function (key, _) {
        return (
          <CircledImageCtr
            onClick={() => window.open(`${links[key]}`, "_blank")}
            size="20px"
            radius="100%"
            hover={true}
          >
            <img src={_renderSocialLinkIcon(key)} alt="" />
          </CircledImageCtr>
        );
      })}
    </SocialLinksCtr>
  );
};
const SocialLinksCtr = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  cursor: pointer;
`;

export default SocialLinks;
