import { useQuery } from "@apollo/client";
import { totalNFTMintClient } from "apollo/client";
import gql from "graphql-tag";
import { useMemo } from "react";
import { notEmpty } from "utils";

export const QUERY_TOTAL_NFT_MINT = gql`
  query records {
    records {
      date_time
      cumulative_mints
      count_mints
    }
  }
`;

interface TotalNFTMintResponse {
    records: {
        date_time: string;
        cumulative_mints: number;
        count_mints: number;
    }[];
}

/**
 * Fetch total and daily NFT mint
 */

export function useTotalNFTMint(): {
    loading: boolean;
    error: boolean;
    records:
    | { dateTime: string; cumulativeMints: number; countMints: number }[]
    | undefined;
} {
    const { loading, error, data } = useQuery<TotalNFTMintResponse>(
        QUERY_TOTAL_NFT_MINT,
        {
            client: totalNFTMintClient,
            fetchPolicy: "cache-first",
        }
    );

    const formattedData = useMemo(() => {
        if (data) {
            return data.records
                .map((p) => {
                    return {
                        dateTime: p.date_time,
                        cumulativeMints: p.cumulative_mints,
                        countMints: p.count_mints,
                    };
                })
                .filter(notEmpty);
        } else {
            return undefined;
        }
    }, [data]);

    return {
        loading: loading,
        error: Boolean(error),
        records: formattedData,
    };
}
