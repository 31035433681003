import assets from "assets";
import { GenericImageWrapper } from "components/Logo";
import Row from "components/Row";
import React from "react";
import styled, { keyframes } from "styled-components";
import { TEXT } from "theme/texts";

type stats = {
  id?: number;
  label: string;
  value: string | number;
};
type marqueeOptions = {
  elm_speed?: number;
  data: stats[];
};

const Marquee = ({ elm_speed, data }: marqueeOptions) => {
  return (
    <MarqueeCtr>
      <Row width="fit-content" gap="4px" marginRight={"20px"}>
        <TEXT.SmallBody fontSize={12}>Daily Prices</TEXT.SmallBody>
        <GenericImageWrapper
          size="12px"
          src={assets.icons.dailyPriceIcon}
          alt="Daily Prices"
        />
      </Row>
      <MarqueeBody>
        <MarqueeGroup speed={elm_speed ?? 20}>
          {data?.map((value, idx) => {
            return (
              <TEXT.SmallBody
                fontSize={12}
                fontWeight={500}
                key={idx}
                style={{ display: "inline-flex" }}
              >
                {value.label}:&nbsp;{" "}
                <TEXT.Primary fontSize={12} fontWeight={500}>
                  {value.value}
                </TEXT.Primary>
              </TEXT.SmallBody>
            );
          })}
        </MarqueeGroup>
        <MarqueeGroup speed={elm_speed ?? 20}>
          {data?.map((value, idx) => {
            return (
              <TEXT.SmallBody
                fontSize={12}
                fontWeight={500}
                key={idx}
                style={{ display: "inline-flex" }}
              >
                {value.label}:&nbsp;{" "}
                <TEXT.Primary fontSize={12} fontWeight={500}>
                  {value.value}
                </TEXT.Primary>
              </TEXT.SmallBody>
            );
          })}
        </MarqueeGroup>
      </MarqueeBody>
    </MarqueeCtr>
  );
};

const scrollX = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
`;

const MarqueeCtr = styled.div`
  display: flex;
  justify-content: center;
  margin: 0rem 0rem 1rem 0rem;
  padding: 0.8rem;
  gap: 1rem;
  width: 100%;
  border-bottom: 0.5px solid ${({ theme }) => theme.border2};
  border-top: 0.5px solid ${({ theme }) => theme.border2};
`;
const MarqueeBody = styled.div`
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
  user-select: none;
  width: 100%;
  max-width: 1200px;
  position: relative;
  mask-image: linear-gradient(
    to right,
    hsl(0 0% 0% / 0),
    hsl(0 0% 0% / 1) 10%,
    hsl(0 0% 0% / 1) 90%,
    hsl(0 0% 0% / 0)
  );
`;
const MarqueeGroup = styled.div<{ speed: number }>`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  white-space: nowrap;
  justify-content: space-around;
  min-width: 100%;
  animation: ${scrollX} ${(props) => `${props?.speed}s linear infinite;`};
  gap: 1.5rem;
  padding: 0 1rem;
`;
const BrandLogo = styled.img`
  object-fit: contain;
  width: 100px;
  max-height: 100%;
  padding: 0.5rem;
  background: ${(props) => props?.theme.bgBase};
  z-index: 100;
`;
export default Marquee;
