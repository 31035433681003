import { HideSmall } from "components/Hide";
import { LoadingRows } from "components/Loader";
import { AutoRow, RowBetween } from "components/Row";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import useTheme from "hooks/useTheme";
import {
    CartesianGrid,
    Cell,
    Label,
    Legend,
    Line,
    LineChart,
    Pie,
    PieChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import { ChartGlobalProps } from "types/chart";
import { getRelativeTimeWindowName, getTickFormattedData } from "utils/date";
import { formatAmount } from "utils/numbers";
import {
    ChartCustomTooltip,
    ChartRenderLegend,
    StyledChartFillGradient,
} from "../components";
import { CHART_DEFAULT_HEIGHT, ChartSubWrapper } from "../styled";
import useMatchBreakpoints from "hooks/useMatchBreakpoints";
import {
    ReactElement,
    JSXElementConstructor,
    ReactFragment,
    ReactPortal,
} from "react";

dayjs.extend(utc);

const CustomPieChart = ({
    data,
    series,
    height = 80,
    minHeight = 80,
    isTinyChart = false,
    ...rest
}: ChartGlobalProps) => {
    const theme = useTheme();
    const { isMobile } = useMatchBreakpoints();

    return (
        <ChartSubWrapper minHeight={minHeight} height={height} {...rest} >
            {data?.length === 0 ? (
                <LoadingRows>
                    <div />
                    <div />
                    <div />
                </LoadingRows>
            ) : (
                <ResponsiveContainer width="100%" height="100%" >
                    <PieChart width={0} height={0} >
                        <Pie
                            data={data}
                            // cx={120}
                            // cy={200}
                            stroke="none"
                            blendStroke={true}
                            innerRadius={20}
                            outerRadius={40}
                            fill={"green"}
                            paddingAngle={5}
                            dataKey="value"

                        >
                            {data.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={entry?.fill} />
                            ))}
                        </Pie>
                    </PieChart>
                </ResponsiveContainer>
            )}
        </ChartSubWrapper>
    );
};

export default CustomPieChart;
