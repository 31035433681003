import { useFetchGlobalChartData } from 'data/protocol/chart';
import { useEffect } from 'react';
import { useProtocolChartData } from './hooks';

export default function Updater(): null {
  console.log("protocaol updater called");
  // client for data fetching

  // const [protocolData, updateProtocolData] = useProtocolData()
  // const { data: fetchedProtocolData, error, loading } = useFetchProtocolData()

  const [chartData, updateChartData] = useProtocolChartData()
  const { data: fetchedChartData, error: chartError } = useFetchGlobalChartData()

  // const [transactions, updateTransactions] = useProtocolTransactions()

  // update overview data if available and not set
  // useEffect(() => {
  //   if (protocolData === undefined && fetchedProtocolData && !loading && !error) {
  //     updateProtocolData(fetchedProtocolData)
  //   }
  // }, [error, fetchedProtocolData, loading, protocolData, updateProtocolData])

  // update global chart data if available and not set
  useEffect(() => {
    if (chartData === undefined && fetchedChartData && !chartError) {
      updateChartData(fetchedChartData)
    }
  }, [chartData, chartError, fetchedChartData, updateChartData])

  // useEffect(() => {
  //   async function fetch() {
  //     const data = await fetchTopTransactions(client)
  //     if (data) {
  //       updateTransactions(data)
  //     }
  //   }
  //   if (!transactions) {
  //     fetch()
  //   }
  // }, [transactions, updateTransactions, client])

  return null
}
