import { useQuery } from '@apollo/client'
import { y00tsTradingGraph } from 'apollo/client'
import gql from 'graphql-tag'
import { useMemo } from 'react'
import { notEmpty } from 'utils'

export const QUERY_Y00TS_STATS = gql`
  query records {
    records {
        floor_price_eth,
        floor_price_matic,
        floor_price_usd,
        avg_price_eth,
        avg_price_matic,
        avg_price_usd,
        data_creation_date,
        sales_count,
        volume_eth,
        volume_matic,
        volume_usd
    }
  }
`

interface y00tsStatsData {
    records: {
        floor_price_matic: number
        volume_usd: number
        volume_eth: number
        volume_matic: number
        sales_count: number
        floor_price_usd: number
        floor_price_eth: number
        avg_price_eth: number
        avg_price_matic: number
        data_creation_date: String
        avg_price_usd: number
    }[]
}

/**
 * Fetch matic current price
 */

export function useZettaTradingGraph(): {
    loading: boolean
    error: boolean,
    records: {
        floor_price_matic: number
        volume_usd: number
        volume_eth: number
        volume_matic: number
        sales_count: number
        floor_price_usd: number
        floor_price_eth: number
        avg_price_eth: number
        avg_price_matic: number
        data_creation_date: String
        avg_price_usd: number
    }[] | undefined
} {
    const { loading, error, data } = useQuery<y00tsStatsData>(QUERY_Y00TS_STATS, {
        client: y00tsTradingGraph,
        fetchPolicy: 'cache-first',
    })
    const formattedData = useMemo(() => {
        if (data) {
            return data.records
                .map((p) => {
                    return {
                        floor_price_matic: p.floor_price_matic,
                        volume_usd: p.volume_usd,
                        volume_eth: p.volume_eth,
                        volume_matic: p.volume_matic,
                        sales_count: p.sales_count,
                        floor_price_usd: p.floor_price_usd,
                        floor_price_eth: p.floor_price_eth,
                        avg_price_eth: p.avg_price_eth,
                        avg_price_matic: p.avg_price_matic,
                        data_creation_date: p.data_creation_date,
                        avg_price_usd: p.avg_price_usd,
                    }
                })
                .filter(notEmpty)
        } else {
            return undefined
        }
    }, [data])

    return {
        loading: loading,
        error: Boolean(error),
        records: formattedData,
    }
}
