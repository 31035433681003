export const appRoutes = {
  marketOverview: "/market-overview",
  collections: "/collections",
  drops: "/drops",
  yootsDashabord: "/yoots-dashboard"
};

export const marketOverviewPageElementIDs = {
  nftMarketCap: "nft-market-cap",
  nftVolume: "nft-volume",
  nftWallets: "nft-wallets",
  nftCollections: "nft-collections",
  nftMints: "nft-mints",
};

export const yootsDashboardElementIDs = {
  listingsAndSalesTable: "listings-and-sales-table"
};


export const profilePageElementIDs = {
  nftMarketCap: "nft-market-cap",
};


export function getScrollToElementPath(appRoute: any, elementId: any): string {
  // here, %23 === #
  var path = ``;
  switch (appRoute) {
    case appRoutes.marketOverview:
      switch (elementId) {
        case marketOverviewPageElementIDs.nftMarketCap:
          path = `${window.location.href}%23${appRoutes.marketOverview}/%23/${marketOverviewPageElementIDs.nftMarketCap}`;
          break;
        case marketOverviewPageElementIDs.nftVolume:
          path = `${window.location.href}%23${appRoutes.marketOverview}/%23/${marketOverviewPageElementIDs.nftVolume}`;
          break;
        case marketOverviewPageElementIDs.nftWallets:
          path = `${window.location.href}%23${appRoutes.marketOverview}/%23/${marketOverviewPageElementIDs.nftWallets}`;
          break;
        default:
          path = `${window.location.href}`;
          break;
      }
      break;
    default:
      path = `${window.location.href}`;
      break;
  }
  return path;
}
