import { ChartDayData } from "../../types/index";
import { useState, useEffect } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import weekOfYear from "dayjs/plugin/weekOfYear";
import gql from "graphql-tag";
import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { blockClient, client } from "apollo/client";
import { useDerivedProtocolTVLHistory } from "./derived";
import { chartTestData } from "data/chartTestingData";

// format dayjs with the libraries that we need
dayjs.extend(utc);
dayjs.extend(weekOfYear);
const ONE_DAY_UNIX = 24 * 60 * 60;

const GLOBAL_CHART = gql`
  query dayDatas($startTime: Int!, $skip: Int!) {
    dayDatas(
      first: 1000
      skip: $skip
      subgraphError: allow
      where: { date_gt: $startTime }
      orderBy: date
      orderDirection: asc
    ) {
      id
      date
      volumeUSD
      tvlUSD
    }
  }
`;

interface ChartResults {
  dayDatas: {
    date: number;
    volumeUSD: string;
    tvlUSD: string;
  }[];
}

async function fetchChartData(client: ApolloClient<NormalizedCacheObject>) {
  let data: {
    date: number;
    volumeUSD: string;
    tvlUSD: string;
  }[] = [];
  const startTimestamp = 1619170975;
  const endTimestamp = dayjs.utc().unix();

  let error = false;
  let skip = 0;
  let allFound = false;

  try {
    while (!allFound) {
      const {
        data: chartResData,
        error,
        loading,
      } = await client.query<ChartResults>({
        query: GLOBAL_CHART,
        variables: {
          startTime: startTimestamp,
          skip,
        },
        fetchPolicy: "cache-first",
      });
      if (!loading) {
        skip += 1000;
        if (chartResData.dayDatas.length < 1000 || error) {
          allFound = true;
        }
        if (chartResData) {
          data = data.concat(chartResData.dayDatas);
        }
      }
    }
  } catch {
    error = true;
  }

  if (data) {
    const formattedExisting = data.reduce(
      (accum: { [date: number]: ChartDayData }, dayData) => {
        const roundedDate = parseInt((dayData.date / ONE_DAY_UNIX).toFixed(0));
        accum[roundedDate] = {
          date: dayData.date,
          volumeUSD: parseFloat(dayData.volumeUSD),
          tvlUSD: parseFloat(dayData.tvlUSD),
        };
        return accum;
      },
      {}
    );

    const firstEntry =
      formattedExisting[parseInt(Object.keys(formattedExisting)[0])];

    // fill in empty days ( there will be no day datas if no trades made that day )
    let timestamp = firstEntry?.date ?? startTimestamp;
    let latestTvl = firstEntry?.tvlUSD ?? 0;
    while (timestamp < endTimestamp - ONE_DAY_UNIX) {
      const nextDay = timestamp + ONE_DAY_UNIX;
      const currentDayIndex = parseInt((nextDay / ONE_DAY_UNIX).toFixed(0));
      if (
        !Object.keys(formattedExisting).includes(currentDayIndex.toString())
      ) {
        formattedExisting[currentDayIndex] = {
          date: nextDay,
          volumeUSD: 0,
          tvlUSD: latestTvl,
        };
      } else {
        latestTvl = formattedExisting[currentDayIndex].tvlUSD;
      }
      timestamp = nextDay;
    }

    return {
      data: Object.values(formattedExisting),
      error: false,
    };
  } else {
    return {
      data: undefined,
      error,
    };
  }
}

/**
 * Fetch historic chart data
 */
export function useFetchGlobalChartData(): {
  error: boolean;
  data: ChartDayData[] | undefined;
} {
  const [data, setData] = useState<ChartDayData[] | undefined>();
  const [error, setError] = useState(false);

  const derivedData = useDerivedProtocolTVLHistory();

  const shouldUserDerivedData = true;
  const indexedData = data;

  // @TODO: remove this once we have fix for mainnet TVL issue
  const formattedData = chartTestData as unknown as ChartDayData[]; //shouldUserDerivedData ? derivedData : indexedData

  useEffect(() => {
    async function fetch() {
      const { data, error } = await fetchChartData(blockClient);
      console.log(data, error);
      if (data && !error) {
        setData(data);
      } else if (error) {
        setError(true);
      }
    }
    if (!indexedData && !error && !shouldUserDerivedData) {
      fetch();
    }
  }, [data, error, client, indexedData, shouldUserDerivedData]);

  return {
    error,
    data: formattedData,
  };
}
