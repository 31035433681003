import { useQuery } from '@apollo/client'
import { totalNFTBuyerClient, totalDailyNFTSellerClient, totalNFTCollectionsClient, totalNFTWalletsClient } from 'apollo/client'
import gql from 'graphql-tag'
import { useMemo } from 'react'
import { notEmpty } from 'utils'

export const QUERY_TOTAL_NFT_WALLETS = gql`
  query records { 
    records {
        date_time
        cumulative_nft_wallet_count
    }
  }
  
`

interface TotalNFTWallets {
    records: {
        date_time: string
        cumulative_nft_wallet_count: number
    }[]
}

/**
 * Fetch daily NFT buyer
 */

export function useTotalNFTWallets(): {
    loading: boolean
    error: boolean,
    records: { dateTime: string, nftCumulativeWalletsCount: number }[] | undefined
} {
    const { loading, error, data } = useQuery<TotalNFTWallets>(QUERY_TOTAL_NFT_WALLETS, {
        client: totalNFTWalletsClient,
        fetchPolicy: 'cache-first',
    })
    const formattedData = useMemo(() => {
        if (data) {
            return data.records
                .map((p) => {
                    return {
                        dateTime: p.date_time,
                        nftCumulativeWalletsCount: p.cumulative_nft_wallet_count
                    }
                })
                .filter(notEmpty)
        } else {
            return undefined
        }
    }, [data])

    return {
        loading: loading,
        error: Boolean(error),
        records: formattedData,
    }
}
