import React from "react";
import { NavLink } from "react-router-dom";
import { useToGetUserInfo } from "store/user/hooks";
import styled from "styled-components";
import { TEXT } from "theme/texts";
import { appRoutes } from "utils/path";

export const NavConfig = [
  {
    label: "Market Overview",
    url: appRoutes.marketOverview,
  },
  {
    label: "Collections",
    url: appRoutes.collections,
  },
  {
    label: "Drops",
    url: appRoutes.drops,
  },
];

const AppNav = () => {
  const { wallet } = useToGetUserInfo();
  return (
    <AppNavCtr>
      {NavConfig.map((element) => {
        return (
          <StyledNavLink
            to={element.url}
            onClick={ () => {}}
          >
            <TEXT.SmallBody paddingX={16} lineHeight={4.6} fontWeight={500}>
              {element.label}
            </TEXT.SmallBody>
            <div className="_bottom" />
          </StyledNavLink>
        );
      })}
    </AppNavCtr>
  );
};

const StyledNavLink = styled(NavLink)<{ active?: boolean }>`
  color: ${({ active, theme }) => (active ? theme.body : theme.disabledVar2)};
  text-decoration: none;
  align-items: center;
  &.active {
    color: ${({ theme }) => theme.body};
    ._bottom {
      width: auto;
      height: 4px;
      background: ${({ theme }) => theme.btnPrimary};
    }
  }
`;

const AppNavCtr = styled.div`
  display: flex;
  gap: 0.5rem;
  /* margin-left: 126px; */
`;

export default AppNav;
