import numbro from 'numbro'

// using a currency library here in case we want to add more in future
export const formatDollarAmount = (num: number | undefined, digits = 2, round = true) => {
  if (num === 0) return '$0.00'
  if (!num) return '-'
  if (num < 0.001 && digits <= 3) {
    return '<$0.001'
  }

  return numbro(num).formatCurrency({
    average: round,
    mantissa: num > 1000 ? 2 : digits,
    abbreviations: {
      million: 'M',
      billion: 'B',
    },
  })
}

// using a currency library here in case we want to add more in future
export const formatAmount = (num: number | undefined, digits = 2) => {
  if (num === 0) return '0'
  if (!num) return '-'
  if (num < 0.001) {
    return '<0.001'
  }
  /* if the number is greater than 1 trillion, we want to use exponential notation */
  if (num > 1000000000000) {
    return numbro(num).format({
      average: true,
      mantissa: digits,
      exponential: true,
      optionalMantissa: true,
      // optionalMantissa: true,
      abbreviations: {
        million: 'M',
        billion: 'B',
        trillion: 'T',
      },
    })
  }

  return numbro(num).format({
    average: true,
    mantissa: digits,
    // optionalMantissa: true,


    abbreviations: {
      million: 'M',
      billion: 'B',
    },
  })
}


// using a currency library here in case we want to add more in future
export const formatAmountNumber = (num: number | undefined, digits = 2) => {
  if (num === 0) return '0'
  if (!num) return '-'
  if (num < 0.001) {
    return '<0.001'
  }
  if (num.toString().length < 6) {
    return `${num}`
  }
  return numbro(num).format({
    mantissa: digits,
    thousandSeparated: true,
  })
}
