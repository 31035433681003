import assets from "assets";
import React, { useMemo } from "react";
import {
  createGlobalStyle,
  css,
  DefaultTheme,
  ThemeProvider as StyledComponentsThemeProvider,
} from "styled-components";
import { useIsDarkMode } from "../store/user/hooks";
import { colors } from "./colors";

export const MEDIA_WIDTHS = {
  upToExtraSmall: 600,
  upToSmall: 720,
  upToMedium: 960,
  upToLarge: 1280,
  upToExtraLarge: 1600,
};

const mediaWidthTemplates: {
  [width in keyof typeof MEDIA_WIDTHS]: typeof css;
} = Object.keys(MEDIA_WIDTHS).reduce((accumulator, size) => {
  (accumulator as any)[size] = (a: any, b: any, c: any) => css`
    @media (max-width: ${(MEDIA_WIDTHS as any)[size]}px) {
      ${css(a, b, c)}
    }
  `;
  return accumulator;
}, {}) as any;

export function theme(darkMode: boolean): DefaultTheme {
  return {
    ...colors(darkMode),

    grids: {
      sm: 8,
      md: 12,
      lg: 24,
      xl: 28,
    },
    borderRadius: {
      container: "8px",
      card: "8px",
      search: "8px",
      input: "8px",
      button: "8px",
    },

    // media queries
    mediaWidth: mediaWidthTemplates,

    // css snippets
    flexColumnNoWrap: css`
      display: flex;
      flex-flow: column nowrap;
    `,

    flexRowNoWrap: css`
      display: flex;
      flex-flow: row nowrap;
    `,
  };
}

export default function ThemeProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const darkMode = useIsDarkMode();

  const themeObject = useMemo(() => theme(darkMode), [darkMode]);

  return (
    <StyledComponentsThemeProvider theme={themeObject}>
      {children}
    </StyledComponentsThemeProvider>
  );
}

export const FixedGlobalStyle = createGlobalStyle`
 @font-face {
    font-family: contentFont;
    font-weight: 400;
    src: url(${assets.fonts.contentFont});
  }
  @font-face {
    font-family: subtitleFont;
    font-weight: 500;
    src: url(${assets.fonts.subtitleFont});
  }
  @font-face {
    font-family: titleFont;
    font-weight: 700;
    src: url(${assets.fonts.titleFont});
  }
  @font-face {
    font-family: GeneralSans;
    font-weight: 400;
    src: url(${assets.fonts.GeneralSans});
  }
  @font-face {
    font-family: Intern500;
    font-weight: 500;
    src: url(${assets.fonts.GeneralSans});
  }
 
  :root {
    //fonts
    --ff-content: contentFont, sans-serif;
    --ff-subtitle: subtitleFont, sans-serif;
    --ff-title: titleFont, sans-serif;
    --fs-s: clamp(0.8rem, 1vw, 0.7rem);
    --fs-r: clamp(1rem, 2vw, 1.25rem);
    --fs-m: clamp(1.25rem, 2vw, 1.5rem);
    --fs-l: clamp(2rem, 3vw, 2.125rem);

  }
html, input, textarea, button, div, .small {
  font-family: var(--ff-content); //font family update to Roobert;
  font-display: fallback;
}
@supports (font-variation-settings: normal) {
  html, input, textarea, button {
    font-family: var(--ff-content); //font family update to Roobert;
  }
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: 'contentFont';
}

 a {
   color: ${colors(false).aTag}; 
 }
 
 * {
  box-sizing: border-box;
}

button {
  user-select: none;
  cursor: pointer;
}

html {
  font-size: 16px;
  font-variant: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-feature-settings: 'ss01' on, 'ss02' on, 'cv01' on, 'cv03' on;
  
}

.recharts-wrapper .recharts-cartesian-grid-horizontal line:first-child{
      stroke-opacity: 0;
}

`;

export const ThemedGlobalStyle = createGlobalStyle`
html {
  color: ${({ theme }) => theme.header};
  background-color: ${({ theme }) => theme.bgBase};
}

.three-line-legend-dark {
	width: 100%;
	height: 70px;
	position: absolute;
	padding: 8px;
	font-size: 12px;
	color: white;
	background-color: transparent;
	text-align: left;
	z-index: 10;
  pointer-events: none;
}

.tv-lightweight-charts{
  width: 100% !important;
  
  & > * {
    width: 100% !important;
  }
}

body {
  min-height: 100vh;
  background-position: 0 -30vh;
  background-repeat: no-repeat;
  font-family: var(--ff-content); //font family update to Roobert
}

//app styled classes
  .activeTab {
    color: ${({ theme }) => theme.white};
  }
  .activeCardTab{
    background: ${(props) => props.theme.disabledVar3} !important;
    border: 1px solid ${({ theme }) => theme.border} !important;
    font-weight:bolder !important;
    border-radius: 0.25rem;
  }
  .success_status{
    color:${(props) => props.theme.success} !important;
  }
   .warning_status{
    color:${(props) => props.theme.warning} !important;
  }
`;
