import assets from "assets";
import { AutoColumn } from "components/Column";
import { OverviewDataCards } from "components/DataCard";
import { HideMedium, ShowMedium } from "components/Hide";
import QuestionHelper from "components/QuestionHelper";
import Row, { ResponsiveRow, ResponsiveRowWrap } from "components/Row";
import { useTotalNFTSeller } from "data/nft/totalDailyNFTSeller";
import { useTotalNFTMint } from "data/nft/totalNFTMint";
import { useTotalNFTBuyer } from "data/nft/totalNFTbuyer";
import { useMaticPrice } from "data/prices/maticCurrentPrice";
import { useAverageAndMedianPrices } from "data/prices/medianPrices";
import useMatchBreakpoints from "hooks/useMatchBreakpoints";
import { PageWrapper } from "pages/styled";
import React, { useEffect } from "react";
import styled from "styled-components";
import { TEXT } from "theme/texts";
import NFTMarketCapGraph from "./NFTMarketCapGraph";
import TotalNFTCollectionsGraph from "./TotalNFTCollectionsGraph";
import TotalNFTMintsGraph from "./TotalNFTMintsGraph";
import TotalNFTVolumeGraph from "./TotalNFTVolumeGraph";
import TotalNFTWalletsGraph from "./TotalNFTWalletsGraph";
// import { useUniqueOwners } from "data/nft/uniqueOwners";
import { useUniqueNFTOwners } from "data/nft/UniqueNFTOwners";
import { formatAmount } from "utils/numbers";
import Marquee from "components/Marquee";
import { useDailyNFTContractInteractions } from "data/nft/totalDailyContractInteractions";
import { marketOverviewPageElementIDs } from "utils/path";
import { StyledCommonCard } from "components/Card";
import { HorizontalDivider } from "components/Divider/inde";
import { ResponsiveRowReverseByDesktop } from "./styled";
import { useDailyWallet } from "data/nft/dailyWallet";

import { useMaticPrices } from "data/prices/meanAndCurrentPrice";

const ResponsiveSubHeader = styled.div`
  width: 100%;
  min-width: 1000px;
  height: 45px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 14px 126px;
  border-bottom: 0.5px solid ${({ theme }) => theme.border2};
  border-top: 0.5px solid ${({ theme }) => theme.border2};
  justify-content: space-around;
  ${({ theme }) => theme.mediaWidth.upToMedium`
      padding: 14px 16px;
  `};
`;

const LogoWrapper = styled.div`
  padding-top: 2px;
  padding-right: 100px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
     padding-right: 6px;
  `}
`;

const Wrapper = styled.div`
  width: 100%;
`;

const WrapperGrid = styled(ResponsiveRowWrap)`
   {
    grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  }
`;

export const GraphResponsiveStyledCard = styled(StyledCommonCard)`
   {
    padding: 0rem;
    display: flex;
    flex-direction: column;
    margin-top: 48px;
    ${({ theme }) => theme.mediaWidth.upToMedium`
       margin-top:34px;
  `}
  }
`;

export const ResponsiveGraphHeader = styled(TEXT.MediumHeader)`
   {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    ${({ theme }) => theme.mediaWidth.upToMedium`
      font-size:16px!important;
  `}
  }
`;

export default function MarketOverview() {
  const refNFTMarketGraph: React.RefObject<HTMLElement> =
    React.useRef<HTMLElement>(null);
  const refTotalVolumeGraph: React.RefObject<HTMLElement> =
    React.useRef<HTMLElement>(null);
  const refTotalNFTWalletsGraph: React.RefObject<HTMLElement> =
    React.useRef<HTMLElement>(null);
  const refNFTCollectionsGraph: React.RefObject<HTMLElement> =
    React.useRef<HTMLElement>(null);
  const refNFTMintsGraph: React.RefObject<HTMLElement> =
    React.useRef<HTMLElement>(null);

  useEffect(() => {
    scrollToElement();
  }, []);
  //const executeScroll = (ref: React.RefObject<HTMLElement>) => ref.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })

  // const maticPriceData = useAverageAndMedianPrices();
  // const maticCurrentPrice = useMaticCurrentPrice();
  const uniqueOwners = useUniqueNFTOwners();
  const totalNFTMints = useTotalNFTMint();
  const reverseTotalNFTMints = [...(totalNFTMints.records ?? [])]?.reverse();
  const totalNFTBuyer = useTotalNFTBuyer();
  const reverseTotalNFTBuyer = [...(totalNFTBuyer.records ?? [])]?.reverse();
  const totalNFTSeller = useTotalNFTSeller();
  const reverseTotalNFTSeller = [...(totalNFTSeller.records ?? [])]?.reverse();
  const { isMobile, isTablet, isDesktop } = useMatchBreakpoints();
  const nFTContractInteractions = useDailyNFTContractInteractions();
  const reverseNFTContractInteractions = [
    ...(nFTContractInteractions.records ?? []),
  ]?.reverse();
  // const totalNFTCollections = useTotalNFTCollections();
  const dailyWallet = useDailyWallet();
  const maticPrices = useMaticPrices();
  const maticCurrentPrice = useMaticPrice();

  const subheader_data = [
    {
      label: "Mean Matic Price",
      value:
        // maticPriceData !== undefined && maticPriceData.records !== undefined
        //   ? maticPriceData?.records[0]?.avgPriceUsd?.toFixed(2) + " USD"
        //   : "",
        maticPrices && maticPrices?.data && maticPrices?.data?.mean
          ? maticPrices?.data?.mean + " USD"
          : "0",
    },
    {
      label: "Median Matic Price",
      value:
        // maticPriceData !== undefined && maticPriceData.records !== undefined
        //   ? maticPriceData?.records[0]?.medianPriceUsd?.toFixed(2) + " USD"
        //   : "",
        maticPrices && maticPrices?.data && maticPrices?.data?.median
          ? maticPrices?.data?.median + " USD"
          : "0",
    },
    {
      label: "Current Matic Price",
      value:
        // maticCurrentPrice !== undefined &&
        //   maticCurrentPrice.records !== undefined
        //   ? maticCurrentPrice?.records[0]?.currentPrice?.toFixed(2) + "USD"
        //   : "0",
        maticCurrentPrice &&
        maticCurrentPrice?.data &&
        maticCurrentPrice?.data?.price
          ? maticCurrentPrice?.data?.price + " USD"
          : "0",
    },
    {
      label: "Unique Owners (>1 NFT)",
      value: (uniqueOwners && uniqueOwners?.data && formatAmount(Number(uniqueOwners?.data))) || 0,
    },
  ];
  const overviewCardsData = [
    {
      title: "Daily NFT Mints",
      tooltip: "NFTs minted over 24 hours",
      count:
        totalNFTMints !== undefined && totalNFTMints.records !== undefined
          ? reverseTotalNFTMints[0].countMints
          : 0,
      priceChange:
        totalNFTMints !== undefined && totalNFTMints.records !== undefined
          ? ((reverseTotalNFTMints[1].countMints -
              reverseTotalNFTMints[2].countMints) *
              100) /
            reverseTotalNFTMints[2].countMints
          : 0,
    },
    {
      title: "Daily NFT buyers",
      tooltip: "Wallets that bought an NFT over 24 hours",
      count:
        totalNFTBuyer !== undefined &&
        reverseTotalNFTBuyer !== undefined &&
        reverseTotalNFTBuyer.length !== 0
          ? reverseTotalNFTBuyer[0].nftBuyerCount
          : 0,
      priceChange:
        totalNFTBuyer !== undefined &&
        reverseTotalNFTBuyer !== undefined &&
        reverseTotalNFTBuyer.length > 2
          ? ((reverseTotalNFTBuyer[1].nftBuyerCount -
              reverseTotalNFTBuyer[2].nftBuyerCount) *
              100) /
            reverseTotalNFTBuyer[2].nftBuyerCount
          : 0,
    },
    {
      title: "Daily NFT Sellers",
      tooltip: "Wallets that sold an NFT over 24 hours",
      count:
        totalNFTSeller !== undefined &&
        reverseTotalNFTSeller !== undefined &&
        reverseTotalNFTSeller.length !== 0
          ? reverseTotalNFTSeller[0].nftSellerCount
          : 0,
      priceChange:
        totalNFTSeller !== undefined &&
        reverseTotalNFTSeller !== undefined &&
        reverseTotalNFTSeller.length > 2
          ? ((reverseTotalNFTSeller[1].nftSellerCount -
              reverseTotalNFTSeller[2].nftSellerCount) *
              100) /
            reverseTotalNFTSeller[2].nftSellerCount
          : 0,
    },
    {
      title: "Daily New Wallets",
      tooltip: "Daily new wallets that interacted with NFTs over 24 hours",
      count:
        dailyWallet && dailyWallet?.data?.newWallets
          ? dailyWallet?.data?.newWallets
          : 0,
      priceChange:
        dailyWallet && dailyWallet?.data?.percentage
          ? dailyWallet?.data?.percentage
          : 0,
    },
  ];

  function scrollToElement() {
    let currentLocation = window.location.href;
    const hasElementAnchor = currentLocation.includes("/#");
    if (hasElementAnchor) {
      const anchorElementId = `${currentLocation.substring(
        currentLocation.lastIndexOf("#") + 2
      )}`;
      const anchorComment = document.getElementById(anchorElementId);
      if (anchorComment) {
        anchorComment.scrollIntoView({ behavior: "smooth" });
      }
    }
  }

  return (
    <Wrapper>
      <Marquee data={subheader_data} />
      <PageWrapper>
        <WrapperGrid justifyContent={"space-around"} gap="1.1rem">
          {overviewCardsData.map((value, index) => {
            return (
              <OverviewDataCards
                key={index}
                title={value.title}
                tooltip={value.tooltip}
                count={value.count}
                priceChange={value.priceChange}
              />
            );
          })}
        </WrapperGrid>
        {/* <ThemedBackgroundGlobal backgroundColor={"green"} /> */}

        <AutoColumn gap="16px">
          {/* Total NFT Volume, and Sales Graph*/}
          <GraphResponsiveStyledCard
            id={marketOverviewPageElementIDs.nftVolume}
            ref={refTotalVolumeGraph}
          >
            <TotalNFTVolumeGraph refTotalVolumeGraph={refTotalVolumeGraph} />
          </GraphResponsiveStyledCard>
          {/* Total NFT Mint Graph*/}
          <GraphResponsiveStyledCard
            id={marketOverviewPageElementIDs.nftMints}
            ref={refNFTMintsGraph}
          >
            <TotalNFTMintsGraph refNFTMintsGraph={refNFTMintsGraph} />
          </GraphResponsiveStyledCard>
          {/* Total NFT Wallets Graph*/}
          <GraphResponsiveStyledCard
            id={marketOverviewPageElementIDs.nftWallets}
            ref={refTotalNFTWalletsGraph}
          >
            <TotalNFTWalletsGraph
              refTotalNFTWalletsGraph={refTotalNFTWalletsGraph}
            />
          </GraphResponsiveStyledCard>
          {/* Total NFT Collections & NFT Market Cap Graph */}
          <ResponsiveRowReverseByDesktop width={"100%"} gap="26px">
            <GraphResponsiveStyledCard
              id={marketOverviewPageElementIDs.nftCollections}
              ref={refNFTCollectionsGraph}
            >
              <TotalNFTCollectionsGraph
                refNFTCollectionsGraph={refNFTCollectionsGraph}
              />
            </GraphResponsiveStyledCard>
            <GraphResponsiveStyledCard
              id={marketOverviewPageElementIDs.nftMarketCap}
              ref={refNFTMarketGraph}
            >
              <NFTMarketCapGraph refNFTMarketGraph={refNFTMarketGraph} />
            </GraphResponsiveStyledCard>
          </ResponsiveRowReverseByDesktop>
        </AutoColumn>
      </PageWrapper>
    </Wrapper>
  );
}
