import { configureStore } from "@reduxjs/toolkit";
import pools from "./pools/reducer";
import protocol from "./protocol/reducer";
import user from "./user/reducer";

// const PERSISTED_KEYS: string[] = ['user']
const store = configureStore({
  reducer: {
    user,
    pools,
    protocol,
  },
  // middleware: [...getDefaultMiddleware({ thunk: false, immutableCheck: false }), save({ states: PERSISTED_KEYS })],
  // preloadedState: load({ states: PERSISTED_KEYS }),
});

export default store;

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
