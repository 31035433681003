import {
  SecondaryButton,
  SmallOptionButton
} from "components/Button";
import LineAreaChart from "components/Chart/LineAreaChart";
import { AutoColumn } from "components/Column";
import { GenericImageWrapper } from "components/Logo";
import Percent from "components/Percent";
import { useTotalNFTCollections } from "data/nft/totalNFTCollections";
import useTheme from "hooks/useTheme";
import React, { useEffect, useMemo, useState } from "react";
import { ExternalLink } from "react-feather";
import { GenericChartSeries, TimeWindow } from "types";
import { formatAmount } from "utils/numbers";
import { ChartWrapper, ListButtonWrapper } from "./styled";

import assets from "assets";
import { HorizontalDivider } from "components/Divider/inde";
import GraphTableTopHeader from "components/HeaderComponent/GraphTableTopHeader";
import { ShowMedium } from "components/Hide";
import Row, { RowBetween } from "components/Row";
import dayjs from "dayjs";
import useMatchBreakpoints from "hooks/useMatchBreakpoints";
import useTakeScreenshot from "hooks/useTakeSnapshot";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { TEXT } from "theme/texts";
import getTimewindowFilteredData from "utils/chart";
import {
  appRoutes,
  getScrollToElementPath,
  marketOverviewPageElementIDs,
} from "utils/path";

const ResponsiveColumn = styled.div<{}>`
  display: flex;
  flex-direction: column;
  padding-top: 6px;
  gap: 6px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
  `}
`;

const twitterShareScrollPath = getScrollToElementPath(
  appRoutes.marketOverview,
  marketOverviewPageElementIDs.nftCollections
);
const snapshotDownloadIconId = "snapshot-nft-collections-download-icon";
const snapshotBrandLogoId = "snapshot-nft-collections-brand-logo";

const TopLeftComponent = ({
  timeWindow,
  setTimeWindow,
  filteredCollectionValues,
  isMobileView,
}: {
  timeWindow: TimeWindow;
  setTimeWindow: React.Dispatch<React.SetStateAction<TimeWindow>>;
  filteredCollectionValues: { countValue: number; percentValue: number };
  isMobileView: boolean;
}) => {
  const navigate = useNavigate();
  return (
    <Row width={"100%"} justify={"space-between"} align="start">
      <AutoColumn>
        <ResponsiveColumn>
          <TEXT.LargeHeader fontSize={isMobileView ? 40 : 72}>
            {formatAmount(
              filteredCollectionValues.countValue
            ).toLocaleUpperCase()}
          </TEXT.LargeHeader>
          {timeWindow !== TimeWindow.all ? (
            <Percent
              value={filteredCollectionValues.percentValue}
              fontSize={isMobileView ? "12px" : "24px"}
              transparentBorder={true}
            />
          ) : (
            <TEXT.Body fontSize={isMobileView ? 12 : 24}>&nbsp;</TEXT.Body>
          )}
        </ResponsiveColumn>
        <RowBetween gap="20px">
          <ShowMedium>
            <SecondaryButton
              width={"fit-content"}
              marginY={"1.4rem"}
              onClick={() => navigate("/collections")}
              active={false}
            >
              <TEXT.Body fontSize={isMobileView ? 14 : 16}>
                See all collections
              </TEXT.Body>{" "}
              &nbsp;{" "}
              <ExternalLink
                width={isMobileView ? 12 : 18}
                height={isMobileView ? 12 : 18}
              />
            </SecondaryButton>
          </ShowMedium>
        </RowBetween>
      </AutoColumn>
      <ListButtonWrapper marginTop="20px">
        {[
          TimeWindow.threeMonth,
          TimeWindow.sixMonth,
          TimeWindow.oneYear,
          TimeWindow.all,
        ].map((option, index) => {
          return (
            <SmallOptionButton
              key={index}
              active={timeWindow === option}
              onClick={() => setTimeWindow(option)}
            >
              {option === "custom" ? (
                <GenericImageWrapper
                  src={assets.icons.calendarIcon}
                  size="18px"
                />
              ) : (
                option
              )}
            </SmallOptionButton>
          );
        })}
      </ListButtonWrapper>
    </Row>
  );
};

function TotalNFTCollectionsGraph({
  refNFTCollectionsGraph,
}: {
  refNFTCollectionsGraph: React.RefObject<HTMLElement>;
}) {
  const theme = useTheme();
  const [volumeHover, setVolumeHover] = useState<number | undefined>();
  const [liquidityHover, setLiquidityHover] = useState<number | undefined>();
  const [leftLabel, setLeftLabel] = useState<string | undefined>();
  const [rightLabel, setRightLabel] = useState<string | undefined>();
  const [timeWindow, setTimeWindow] = useState(TimeWindow.all);
  const totalNFTCollections = useTotalNFTCollections();
  const reverseTotalNFTCollections = [
    ...(totalNFTCollections.records ?? []),
  ].reverse();
  const { isMobile } = useMatchBreakpoints();

  const downloadScreenshot = useTakeScreenshot({
    ref: refNFTCollectionsGraph,
    elementIdsTohide: [snapshotDownloadIconId],
    elementIdsToShow: [snapshotBrandLogoId],
  });

  useEffect(() => {
    setLiquidityHover(undefined);
    setVolumeHover(undefined);
  }, []);

  /**
   * @notice
   * @dev 'formattedVolumeData' function format data according to graph plot format
   * @dev 'getTransformedVolumeData' function filter chart data according to selected timewindow
   * @dev 'formattedChartSeries' change according to requirement
   * @param timewindow is selected option out of provided time options to user for filtering chart data
   * @param time  for X-axis
   * @param value... for Y-axis it must be same as @param datakey of config variable 'formattedChartSeries'
   */
  const formattedChartData = useMemo(() => {
    if (totalNFTCollections && totalNFTCollections.records) {
      return getTimewindowFilteredData(
        totalNFTCollections.records,
        timeWindow
      ).map((record) => {
        return {
          time: record.dateTime,
          valueNFTCollection: record.nftCumulativeCollectionCount,
        };
      });
    } else {
      return [];
    }
  }, [totalNFTCollections, timeWindow]);
  console.log(timeWindow);
  /**
   * @notice
   * @dev 'formattedChartSeries'  must change according to requirement
   * Add according requirement (This will plot line graph with provided color and data)
   */
  const formattedChartSeries: GenericChartSeries[] = [
    {
      dataKey: "valueNFTCollection",
      name: "NFT Collections",
      color: theme.graphLines,
    },
  ];

  const filteredTotalCount = useMemo(() => {
    let countValue = 0;
    let percentValue = 0;
    if (reverseTotalNFTCollections.length === 0) {
      return { countValue, percentValue };
    }
    switch (timeWindow) {
      case TimeWindow.threeMonth: {
        const beforeThreeMonthData = reverseTotalNFTCollections.find(
          (element) =>
            dayjs(element.dateTime).isBefore(
              dayjs().subtract(3, "month"),
              "month"
            )
        );
        const beforeSixMonthData = reverseTotalNFTCollections.find((element) =>
          dayjs(element.dateTime).isBefore(
            dayjs().subtract(6, "month"),
            "month"
          )
        );
        const lastThreeMonthCount =
          reverseTotalNFTCollections[1].nftCumulativeCollectionCount -
          (beforeThreeMonthData?.nftCumulativeCollectionCount ?? 0);
        const lastSecondThreeMonthCount =
          (beforeThreeMonthData?.nftCumulativeCollectionCount ?? 0) -
          (beforeSixMonthData?.nftCumulativeCollectionCount ?? 0);
        countValue =
          reverseTotalNFTCollections[0].nftCumulativeCollectionCount -
          (beforeThreeMonthData?.nftCumulativeCollectionCount ?? 0);
        percentValue =
          ((lastThreeMonthCount - lastSecondThreeMonthCount) * 100) /
          (lastSecondThreeMonthCount ?? 1);
        break;
      }
      case TimeWindow.sixMonth: {
        const beforeSixMonthData = reverseTotalNFTCollections.find((element) =>
          dayjs(element.dateTime).isBefore(
            dayjs().subtract(6, "month"),
            "month"
          )
        );
        const beforeTwelveMonthData = reverseTotalNFTCollections.find(
          (element) =>
            dayjs(element.dateTime).isBefore(
              dayjs().subtract(12, "month"),
              "month"
            )
        );
        const lastSixMonthCount =
          reverseTotalNFTCollections[1].nftCumulativeCollectionCount -
          (beforeSixMonthData?.nftCumulativeCollectionCount ?? 0);
        const lastSecondSixMonthCount =
          (beforeSixMonthData?.nftCumulativeCollectionCount ?? 0) -
          (beforeTwelveMonthData?.nftCumulativeCollectionCount ?? 0);
        countValue =
          reverseTotalNFTCollections[0].nftCumulativeCollectionCount -
          (beforeSixMonthData?.nftCumulativeCollectionCount ?? 0);
        percentValue =
          ((lastSixMonthCount - lastSecondSixMonthCount) * 100) /
          (lastSecondSixMonthCount ?? 1);

        percentValue =
          ((reverseTotalNFTCollections[1].nftCollectionsCount -
            (beforeSixMonthData?.nftCollectionsCount ?? 0)) *
            100) /
          (beforeSixMonthData?.nftCollectionsCount ?? 1);
        break;
      }
      case TimeWindow.oneYear: {
        const beforeOneYearData = reverseTotalNFTCollections.find((element) =>
          dayjs(element.dateTime).isBefore(dayjs().subtract(1, "year"), "month")
        );
        const beforeTwoYearData = reverseTotalNFTCollections.find((element) =>
          dayjs(element.dateTime).isBefore(dayjs().subtract(2, "year"), "month")
        );
        const lastOneYearCount =
          reverseTotalNFTCollections[1].nftCumulativeCollectionCount -
          (beforeOneYearData?.nftCumulativeCollectionCount ?? 0);
        const lastSecondYearCount =
          (beforeOneYearData?.nftCumulativeCollectionCount ?? 0) -
          (beforeTwoYearData?.nftCumulativeCollectionCount ?? 0);
        countValue =
          reverseTotalNFTCollections[0].nftCumulativeCollectionCount -
          (beforeOneYearData?.nftCumulativeCollectionCount ?? 0);
        percentValue =
          ((lastOneYearCount - lastSecondYearCount) * 100) /
          (lastSecondYearCount ?? 1);
        break;
      }
      case TimeWindow.all:
        countValue = reverseTotalNFTCollections[0].nftCumulativeCollectionCount;
        percentValue =
          ((reverseTotalNFTCollections[1].nftCollectionsCount -
            reverseTotalNFTCollections[2].nftCollectionsCount) *
            100) /
          reverseTotalNFTCollections[2].nftCollectionsCount;
        break;
      default:
        countValue = reverseTotalNFTCollections[0].nftCumulativeCollectionCount;
        percentValue =
          ((reverseTotalNFTCollections[1].nftCollectionsCount -
            reverseTotalNFTCollections[2].nftCollectionsCount) *
            100) /
          reverseTotalNFTCollections[2].nftCollectionsCount;
        break;
    }
    return { countValue, percentValue };
  }, [totalNFTCollections, timeWindow]);

  return (
    <>
      <GraphTableTopHeader
        title={"Total NFT Collections"}
        tooltip="Number of NFT collections minted "
        downloadScreenshot={downloadScreenshot}
        isMobileView={isMobile}
        snapshotBrandLogoId={snapshotBrandLogoId}
        snapshotDownloadIconId={snapshotDownloadIconId}
        tweetText={`https://twitter.com/intent/tweet?text=💜 Polygon NFT insights:  @LayerEhq here: ${twitterShareScrollPath} `} />
      <HorizontalDivider />
      <ChartWrapper>
        <LineAreaChart
          data={formattedChartData}
          series={formattedChartSeries}
          setValue={setVolumeHover}
          setLabel={setRightLabel}
          value={volumeHover}
          label={rightLabel}
          activeWindow={timeWindow}
          topLeft={
            <TopLeftComponent
              timeWindow={timeWindow}
              setTimeWindow={setTimeWindow}
              filteredCollectionValues={filteredTotalCount}
              isMobileView={isMobile}
            />
          }
          showXAxis={false}
          showYAxis={false}
          showCartesian={false}
          isTinyChart={true}
        />
      </ChartWrapper>
    </>
  );
}

export default TotalNFTCollectionsGraph;
