import { useQuery } from '@apollo/client'
import { totalNFTBuyerClient, totalDailyNFTSellerClient, totalNFTCollectionsClient } from 'apollo/client'
import gql from 'graphql-tag'
import { useMemo } from 'react'
import { notEmpty } from 'utils'

export const QUERY_TOTAL_NFT_COLLECTIONs = gql`
  query records { 
    records {
        date_time
    count_collections
     cumulative_collections
    }
  }
  
`

interface TotalNFTCollections {
    records: {
        date_time: string
        count_collections: number
        cumulative_collections: number
    }[]
}

/**
 * Fetch daily NFT buyer
 */

export function useTotalNFTCollections(): {
    loading: boolean
    error: boolean,
    records: { dateTime: string, nftCollectionsCount: number, nftCumulativeCollectionCount: number }[] | undefined
} {
    const { loading, error, data } = useQuery<TotalNFTCollections>(QUERY_TOTAL_NFT_COLLECTIONs, {
        client: totalNFTCollectionsClient,
        fetchPolicy: 'cache-first',
    })
    const formattedData = useMemo(() => {
        if (data) {
            return data.records
                .map((p) => {
                    return {
                        dateTime: p.date_time,
                        nftCollectionsCount: p.count_collections,
                        nftCumulativeCollectionCount: p.cumulative_collections
                    }
                })
                .filter(notEmpty)
        } else {
            return undefined
        }
    }, [data])

    return {
        loading: loading,
        error: Boolean(error),
        records: formattedData,
    }
}
