import assets from "assets";
import styled from "styled-components";

const LoaderWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  display: grid;
  place-items: center;
  background: ${(props) => props.theme.bgBase};
`;
const LoaderCtr = styled.div`
  position: relative;
  display: grid;
  place-items: center;
  padding: .5rem;
`;
const Spinner = styled.div`
  @keyframes spinner-anim {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  position: absolute;
  width: 100%;
  height: 100%;
  border: 1px solid;
  top: 0;
  left: 0;
  border: 3px solid transparent;
  border-radius: 50%;
  border-right-color: rgba(255, 255, 255, 0.7);
  animation: spinner-anim 0.8s linear infinite;
`;
const BrandLogoCtr = styled.div`
  width: 50px;
  height: 50px;
  overflow: hidden;
  position: relative;
  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
`;
const BrandLogo = () => (
  <BrandLogoCtr>
    <img src={assets.logos.surfaceboardLogoCircled} alt="" />
  </BrandLogoCtr>
);

const NewLoader = () => {
  return (
    <LoaderWrapper>
      <LoaderCtr>
        <BrandLogo />
        <Spinner />
      </LoaderCtr>
    </LoaderWrapper>
  );
};

export default NewLoader;
