import styled from "styled-components";
import { Box } from "rebass/styled-components";

const Card = styled(Box) <{
  width?: string;
  padding?: string;
  border?: string;
  borderRadius?: string;
}>`
  width: ${({ width }) => width ?? "100%"};
  border-radius: 16px;
  padding: 1rem;
  padding: ${({ padding }) => padding};
  border: ${({ border }) => border};
  border-radius: ${({ borderRadius }) => borderRadius};
`;
export default Card;

export const LightCard = styled(Card)`
  border: 1px solid ${({ theme }) => theme.bgBase};
  background-color: ${({ theme }) => theme.bgBase};
`;

export const LightGreyCard = styled(Card)`
  background-color: ${({ theme }) => theme.bgBase};
`;

export const GreyCard = styled(Card)`
  background-color: ${({ theme }) => theme.grey};
`;

export const DarkGreyCard = styled(Card)`
  background-color: ${({ theme }) => theme.bgBase};
`;

export const OutlineCard = styled(Card)`
  border: 1px solid ${({ theme }) => theme.bgBase};
`;
export const OutlineCardVar2 = styled(Card)`
  border: 1px solid ${({ theme }) => theme.disabled};
  position: relative;
  overflow: hidden;
  border-radius: 0;
  &::before {
    content: "";
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    background: ${({ theme }) => theme.activeRadialGradient};
    opacity: 0;
    -webkit-transition: all 500ms ease-out;
    -moz-transition: all 500ms ease-out;
    -o-transition: all 500ms ease-out;
    transition: all 500ms ease-out;
    z-index: -1;
  }
  &:hover {
    background: none;
  }
  &:hover::before {
    opacity: 1;
  }
`;

export const BlueCard = styled(Card)`
  background-color: ${({ theme }) => theme.blue};
  color: ${({ theme }) => theme.header};
  border-radius: 12px;
`;

export const ScrollableX = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const GreyBadge = styled(Card)`
  width: fit-content;
  border-radius: 8px;
  background: ${({ theme }) => theme.grey};
  color: ${({ theme }) => theme.header};
  padding: 4px 6px;
  font-weight: 400;
`;
export const RadialGradientCard = styled(Card)`
  background: ${({ theme }) => theme.radialGradient};
  :hover,
  :focus {
    background: ${({ theme }) => theme.activeRadialGradient};
  }
`;

export const StyledCommonCard = styled(Card)`
  border-radius: 0px !important;
  border: 1px solid ${({ theme }) => theme.border};
  background: ${({ theme }) => theme.bgMiddle};
  :hover,
  :focus {
    background: ${({ theme }) => theme.activeRadialGradient};
  }
`;
