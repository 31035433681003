import styled from "styled-components";

export const HorizontalDivider = styled.div<{
  topSpace?: string;
  bottomSpace?: string;
}>`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.border};
  padding-top: ${({ topSpace }) => topSpace ?? "0px"};
  margin-bottom: ${({ bottomSpace }) => bottomSpace ?? "0px"};
`;
