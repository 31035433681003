import React, { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { formatAmount } from "utils/numbers";
import GainLossPercentComponent from "components/Percent";
import { ChevronDown, ChevronUp } from "react-feather";
import { CircledImageCtr } from "components/Logo";
import assets from "assets";
import { useCurrencyUnit } from "store/user/hooks";
import { backgroundClip } from "html2canvas/dist/types/css/property-descriptors/background-clip";
import { DataTableWrapper } from "pages/YootsDashboard/component";

const TableHeadItem = ({ item, selectedItem }: any) => {
    return (
        <th
            className="_heading"
            onClick={item?.action ? item?.action : () => { }}
            style={item?.action ? { cursor: "pointer" } : {}}
        >
            <span>
                {item.heading}
                {item?.action ? (
                    item?.sort_dir === "asec" && item?.sort_item === selectedItem ? (
                        <ChevronUp size={"1rem"} />
                    ) : (
                        <ChevronDown size={"1rem"} />
                    )
                ) : null}
            </span>
        </th>
    );
};

const TableDataItem = ({ item, column }: any) => {
    const navigate = useNavigate();
    const currency = useCurrencyUnit();
    return (
        <tr className="_tableData" key={item.id}>
            {column.map((colItem: any, idx: number) => {
                if (colItem.value === "no") {
                    return (
                        <td key={idx} className="_dataCell">
                            {item[`${colItem.value}`]}
                        </td>
                    );
                } else if (
                    colItem.value === "floor_change" ||
                    colItem.value === "sales_change" ||
                    colItem.value === "volume_change"
                ) {
                    return (
                        <td key={idx} className={"_dataCell"}>
                            <GainLossPercentComponent
                                value={parseFloat(item[`${colItem.value}`])}
                                formatFig={true}
                                fontSize={".8rem"}
                                upDownIcon={false}
                                noDecoration={true}
                            />
                        </td>
                    );
                } else
                    return (
                        <td
                            key={idx}
                            className="_dataCell"
                        >
                            {colItem.value === "name" ? (
                                <div className="profileName">
                                    <CircledImageCtr size="36px" radius=".25rem">
                                        <img
                                            src={
                                                item[`${colItem.logo}`] ||
                                                assets.icons.defaultCollectionIcon
                                            }
                                            alt={item[`${colItem.value}`]}
                                        />
                                    </CircledImageCtr>
                                    <span>{item[`${colItem.value}`]}</span>
                                </div>
                            ) : colItem.value === "floor" ? (
                                <span>
                                    {formatAmount(
                                        parseFloat(item[`${colItem.value}`])
                                    ).toUpperCase()}
                                </span>
                            ) : (
                                <span className="currencyLogo">
                                    {colItem.value?.includes("in_") && (
                                        <CircledImageCtr size="15px">
                                            <img
                                                src={currency?.currency?.logo}
                                                alt="currency"
                                                style={{ objectFit: "contain" }}
                                            />
                                        </CircledImageCtr>
                                    )}

                                    {item[`${colItem.value}`] === undefined ||
                                        item[`${colItem.value}`] === null
                                        ? 0
                                        : colItem.value === "sales"
                                            ? formatAmount(
                                                parseFloat(item[`${colItem.value}`]),
                                                0
                                            ).toUpperCase()
                                            : formatAmount(
                                                parseFloat(item[`${colItem.value}`])
                                            ).toUpperCase()}
                                </span>
                            )}
                        </td>
                    );
            })}
        </tr>
    );
};
const DataTable = ({ data, column, sortItemLabel }: any) => {
    return (
        <DataTableWrapper>
            <table className="_table">
                <thead>
                    <tr className="_headings">
                        {column.map((item: any, idx: any) => (
                            <TableHeadItem
                                item={item}
                                key={idx}
                                selectedItem={sortItemLabel}
                            />
                        ))}
                    </tr>
                </thead>
                <tbody className="_tableBody">
                    {data.map((item: any, idx: any) => {
                        return (
                            <TableDataItem item={item} column={column} key={idx} id={idx} />
                        );
                    })}
                </tbody>
            </table>
        </DataTableWrapper>
    );
};

export default DataTable;