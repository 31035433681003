import { AutoRow, ResponsiveRow } from 'components/Row';
import { PageWrapper } from 'pages/styled';
import React from 'react'
import styled from 'styled-components'
import { GraphResponsiveStyledCard } from '../component';
import OverviewDataCards from './OverviewDataCards';

export default function OverviewSection() {

    const refGraph: React.RefObject<HTMLElement> =
        React.useRef<HTMLElement>(null);

    return (
        <Wrapper>
            <OverviewDataCards />
        </Wrapper>
    )
}

const Wrapper = styled.div`
  padding: 1rem 0rem;
`;