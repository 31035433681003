import assets from "assets";
import { SmallOptionButton } from "components/Button";
import BarChart from "components/Chart/BarChart";
import LineChart from "components/Chart/LineChart";
import { AutoColumn } from "components/Column";
import YearMonthPickerComponent from "components/DateTime/YearMonthPickerComponent";
import { HorizontalDivider } from "components/Divider/inde";
import BasicCheckboxDropdown, {
  checkboxDropdownOptions,
} from "components/DropDown/BasicCheckboxDropdown";
import GraphTableTopHeader from "components/HeaderComponent/GraphTableTopHeader";
import { HideSmall, ShowSmall } from "components/Hide";
import { GenericImageWrapper } from "components/Logo";
import Row, { RowBetween } from "components/Row";
import { TabularNumsText } from "components/shared";
import { Currencies, TypeCurrency } from "constant/currency";
import { useTotalNFTVolumeAndSales } from "data/nft/totalNFTVolumeAndSales";
import dayjs from "dayjs";
import useMatchBreakpoints from "hooks/useMatchBreakpoints";
import useTakeScreenshot from "hooks/useTakeSnapshot";
import useTheme from "hooks/useTheme";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useCurrencyUnit } from "store/user/hooks";
import { TEXT } from "theme/texts";
import { GenericChartSeries, TimeWindow, TradeActionWindow } from "types";
import getTimewindowFilteredData from "utils/chart";
import { formatAmount, formatDollarAmount } from "utils/numbers";
import {
  appRoutes,
  getScrollToElementPath,
  marketOverviewPageElementIDs,
} from "utils/path";
import {
  ChartTopRightSectionWrapper,
  ChartWrapper,
  ListButtonWrapper,
} from "./styled";

const twitterShareScrollPath = getScrollToElementPath(
  appRoutes.marketOverview,
  marketOverviewPageElementIDs.nftVolume
);
const snapshotDownloadIconId = "snapshot-nft-volume-sales-download-icon";
const snapshotBrandLogoId = "snapshot-nft-volume-sales-brand-logo";

const TopRightComponent = ({
  timeWindow,
  setTimeWindow,
  tradeActionWindow,
  setTradeActionWindow,
  showBarGraph,
  setShowBarGraph,
  handleChangeDropdownMenu,
  handleChangeDropdown,
  isMobileView,
  downloadScreenshot,
  selectedCustomTime,
  onCalendarChange,
}: {
  timeWindow: TimeWindow;
  setTimeWindow: React.Dispatch<React.SetStateAction<TimeWindow>>;
  tradeActionWindow: TradeActionWindow;
  setTradeActionWindow: React.Dispatch<React.SetStateAction<TradeActionWindow>>;
  showBarGraph: boolean;
  setShowBarGraph: React.Dispatch<React.SetStateAction<boolean>>;
  handleChangeDropdownMenu: (mainId: number) => void;
  handleChangeDropdown: (
    mainId: number,
    subId: number,
    checked: boolean
  ) => void;
  isMobileView: boolean;
  downloadScreenshot: () => void;
  selectedCustomTime: { year: number; month: number } | undefined;
  onCalendarChange: (selectedYear: number, selectedMonth: number) => void;
}) => {
  return (
    <ChartTopRightSectionWrapper key="top-right">
      <HideSmall>
        <BasicCheckboxDropdown
          data={defaultAvailableOptions}
          setMainOption={handleChangeDropdownMenu}
          setOptions={handleChangeDropdown}
        />
      </HideSmall>
      <ListButtonWrapper>
        {[TradeActionWindow.sale, TradeActionWindow.volume].map(
          (option, index) => {

            return (
              <SmallOptionButton
                key={index}
                active={tradeActionWindow === option}
                onClick={() => setTradeActionWindow(option)}
              >
                {option}
              </SmallOptionButton>
            );
          }
        )}
      </ListButtonWrapper>
      <ListButtonWrapper>
        {[
          TimeWindow.threeMonth,
          TimeWindow.sixMonth,
          TimeWindow.oneYear,
          TimeWindow.all,
          TimeWindow.custom,
        ].map((option, index) => {
          return (
            <SmallOptionButton
              key={index}
              active={timeWindow === option}
              onClick={() => setTimeWindow(option)}
            >
              {option === "custom" ? (
                <YearMonthPickerComponent
                  year={
                    selectedCustomTime
                      ? selectedCustomTime.year
                      : dayjs().year()
                  }
                  month={
                    selectedCustomTime
                      ? selectedCustomTime.month
                      : dayjs().month()
                  }
                  onChange={onCalendarChange}
                  isMobileView={isMobileView}
                />
              ) : (
                option
              )}
            </SmallOptionButton>
          );
        })}
      </ListButtonWrapper>
    </ChartTopRightSectionWrapper>
  );
};

const TopLeftComponent = ({
  isMobileView,
  filteredTotalCount,
  selectedCurrency,
  handleChangeDropdownMenu,
  handleChangeDropdown,
}: {
  isMobileView: boolean;
  filteredTotalCount: number;
  selectedCurrency: TypeCurrency;
  handleChangeDropdownMenu: (mainId: number) => void;
  handleChangeDropdown: (
    mainId: number,
    subId: number,
    checked: boolean
  ) => void;
}) => {
  const totalLabel = `${formatAmount(filteredTotalCount).toUpperCase()}`;
  return (
    <RowBetween key="top-left" width={isMobileView ? "100%" : "auto"}>
      <AutoColumn gap="10px">
        <Row gap="8px">
          <GenericImageWrapper
            src={selectedCurrency.logo}
            size={isMobileView ? "26px" : "28px"}
          />
          {isMobileView ? (
            <TEXT.SmallHeader fontSize={24}>
              <TabularNumsText>{`${totalLabel}`}</TabularNumsText>
            </TEXT.SmallHeader>
          ) : (
            <TEXT.LargeHeader>
              <TabularNumsText>{`${totalLabel}`}</TabularNumsText>
            </TEXT.LargeHeader>
          )}
        </Row>
      </AutoColumn>
      <ShowSmall>
        <BasicCheckboxDropdown
          data={defaultAvailableOptions}
          setMainOption={handleChangeDropdownMenu}
          setOptions={handleChangeDropdown}
        />
      </ShowSmall>
    </RowBetween>
  );
};

export type graphProps = {
  refTotalVolumeGraph: React.RefObject<HTMLElement>;
};

type typeChartData = {
  dateTime: string;
  project: string;
  salesCount: number;
  volumeUSD: number;
  volumeInMatic: number;
  volumeInWETH: number;
};

type typeProjectData = {
  [x: string]: {
    [y: string]: {
      dateTime: string;
      salesCount: number;
      volumeUSD: number;
      volumeInMatic: number;
      volumeInWETH: number;
    };
  };
};

const getCurrencyAccordingData = (
  currency: TypeCurrency,
  projectData: typeProjectData,
  project: string,
  time: string
) => {
  switch (currency.id) {
    case Currencies.WETH:
      return projectData[project][time].volumeInWETH;
    case Currencies.MATIC:
      return projectData[project][time].volumeInMatic;
    case Currencies.USD:
      return projectData[project][time].volumeUSD;
    default:
      return projectData[project][time].volumeInWETH;
  }
};

const getMarketPlaceFilteredData = (
  day: typeChartData,
  projectData: typeProjectData,
  currency: TypeCurrency,
  selectedDropDownOption: checkboxDropdownOptions,
  options: checkboxDropdownOptions[],
  tradeActionWindow: TradeActionWindow
) => {
  if (selectedDropDownOption.id === 1) {
    var totalVolume = 0,
      totalSalesCount = 0;
    // total of all marketplaces
    (options.filter((option) => option.subOptions.length !== 0) ?? []).forEach(
      (filteredOption) => {
        filteredOption.subOptions.forEach((subOption, index) => {
          if (
            projectData[subOption.value.toLowerCase()][day.dateTime] !==
            undefined
          ) {
            totalVolume =
              totalVolume +
              getCurrencyAccordingData(
                currency,
                projectData,
                subOption.value.toLowerCase(),
                day.dateTime
              );
            totalSalesCount =
              totalSalesCount +
              projectData[subOption.value.toLowerCase()][day.dateTime]
                .salesCount;
          }
        });
      }
    );

    //
    return {
      time: day.dateTime,
      ecosystem:
        tradeActionWindow === TradeActionWindow.volume
          ? totalVolume
          : totalSalesCount,
    };
  } else {
    let dataObj: Record<string, any> = { time: day.dateTime };

    selectedDropDownOption.subOptions.forEach((subOption, index) => {
      if (subOption.checked) {
        dataObj[subOption.value] = projectData[subOption.value.toLowerCase()][
          day.dateTime
        ]
          ? tradeActionWindow === TradeActionWindow.volume
            ? getCurrencyAccordingData(
              currency,
              projectData,
              subOption.value.toLowerCase(),
              day.dateTime
            )
            : projectData[subOption.value.toLowerCase()][day.dateTime]
              .salesCount
          : 0;
      }
    });
    return dataObj;
  }
};

function TotalNFTVolumeGraph({ refTotalVolumeGraph }: graphProps) {
  const theme = useTheme();
  const [volumeHover, setVolumeHover] = useState<number | undefined>();
  const [liquidityHover, setLiquidityHover] = useState<number | undefined>();
  const [leftLabel, setLeftLabel] = useState<string | undefined>();
  const [rightLabel, setRightLabel] = useState<string | undefined>();
  const [timeWindow, setTimeWindow] = useState(TimeWindow.all);
  const [tradeActionWindow, setTradeActionWindow] = useState(
    TradeActionWindow.volume
  );

  const [showBarGraph, setShowBarGraph] = useState(true);

  const [options, setOptions] = useState(defaultAvailableOptions);
  console.log(options)
  const { isMobile } = useMatchBreakpoints();
  const downloadScreenshot = useTakeScreenshot({
    ref: refTotalVolumeGraph,
    elementIdsTohide: [snapshotDownloadIconId],
    elementIdsToShow: [snapshotBrandLogoId],
  });
  const { currency } = useCurrencyUnit();

  useEffect(() => {
    console.debug("currency updated in TotalNFTVolumeGraph", currency);
  }, [currency]);

  const totalNFTVolumeAndSalesData = useTotalNFTVolumeAndSales();
  const reverseTotalNFTVolumeAndSalesData = [
    ...(totalNFTVolumeAndSalesData.records ?? []),
  ].reverse();


  const totalNFTVolumeAndSalesFomattedData = useCallback(() => {
    var data: typeProjectData = {};
    reverseTotalNFTVolumeAndSalesData &&
      reverseTotalNFTVolumeAndSalesData.forEach((day) => {
        if (!data[day.project]) {
          data[day.project] = {};
        }
        data[day.project][day.dateTime] = {
          dateTime: day.dateTime,
          salesCount: day.salesCount,
          volumeUSD: day.volumeUSD,
          volumeInMatic: day.volumeInMatic,
          volumeInWETH: day.volumeInWETH,
        };
      });
    return data;
  }, [totalNFTVolumeAndSalesData]);

  // Calendar actions
  const [selectedCustomTime, setSelectedCustomTime] = useState<
    { year: number; month: number } | undefined
  >(undefined);

  const onCalendarChange = (year: number, month: number) => {
    setSelectedCustomTime({ year: year, month: month });
  };
  //

  function handleChangeDropdownMenu(mainId: number) {
    let temp = [...options];
    options.forEach((option, index) => {
      if (mainId - 1 === index) {
        temp[mainId - 1].selected = true;
      } else {
        temp[index].selected = false;
      }
    });
    setOptions(temp);
  }

  const selectedDropDownOption = useMemo(() => {
    return options.find((option) => option.selected) ?? options[0];
  }, [options]);

  function handleChangeDropdown(
    mainId: number,
    subId: number,
    checked: boolean
  ) {
    let temp = [...options];
    if (
      !checked &&
      temp[mainId - 1].subOptions.filter((value) => value.checked).length === 1
    ) {
      // Show message popup
      return false;
    }
    temp[mainId - 1].subOptions[subId - 1].checked = checked;
    setOptions(temp);
  }

  useEffect(() => {
    setLiquidityHover(undefined);
    setVolumeHover(undefined);
  }, []);

  const formattedChartData = useMemo(() => {
    const projectData = totalNFTVolumeAndSalesFomattedData();
    if (
      totalNFTVolumeAndSalesData &&
      reverseTotalNFTVolumeAndSalesData &&
      reverseTotalNFTVolumeAndSalesData.length !== 0
    ) {
      const totalData = getTimewindowFilteredData(
        reverseTotalNFTVolumeAndSalesData,
        timeWindow,
        selectedCustomTime
      ).map((day: any) => {
        return getMarketPlaceFilteredData(
          day,
          projectData,
          currency,
          selectedDropDownOption,
          options,
          tradeActionWindow
        );
      });
      const distinctData: any[] = [];
      totalData.forEach((singleData) => {
        if (
          distinctData.find((dData) => dData.time === singleData.time) ===
          undefined
        ) {
          distinctData.push(singleData);
        }
      });
      return distinctData;
    } else {
      return [];
    }
  }, [totalNFTVolumeAndSalesData, timeWindow, tradeActionWindow, options]);

  /**
   * @notice
   * @dev 'formattedChartSeries'  must change according to requirement
   * Add according requirement (This will plot line graph with provided color and data)
   */
  const defaultSeries: GenericChartSeries[] = [
    { dataKey: "ecosystem", name: "Ecosystem", color: "#8247E5" },
    { dataKey: "opensea", name: "Opensea", color: "#0057FF" },
    { dataKey: "magicEden", name: "Magic Eden", color: "#8247E5" },
    { dataKey: "onePlanet", name: "One Planet", color: "#25C7EE" },
  ];
  const formattedChartSeries: GenericChartSeries[] = useMemo(() => {
    if (selectedDropDownOption.id === 1) {
      return defaultSeries.find((value) => value.dataKey === "ecosystem")
        ? [
          defaultSeries.find((value) => value.dataKey === "ecosystem") ??
          defaultSeries[1],
        ]
        : [];
    } else {
      const dataObj: GenericChartSeries[] = [];
      selectedDropDownOption.subOptions.forEach((subOption, index) => {
        if (
          subOption.checked &&
          defaultSeries.find((value) => value.dataKey === subOption.value) !==
          undefined
        ) {
          dataObj.push(
            defaultSeries.find((value) => value.dataKey === subOption.value) ??
            defaultSeries[1]
          );
        }
      });
      return dataObj;
    }
    return [];
  }, [options]);

  const filteredTotalCount: number = useMemo(() => {
    if (
      totalNFTVolumeAndSalesData &&
      reverseTotalNFTVolumeAndSalesData &&
      reverseTotalNFTVolumeAndSalesData.length !== 0
    ) {
      console.debug(
        "getTimewindowFilteredData",
        getTimewindowFilteredData(
          reverseTotalNFTVolumeAndSalesData,
          timeWindow,
          selectedCustomTime
        )
      );
      console.debug("formattedChartData", formattedChartData);
      let previousToCurrentTimeTotal = 0;
      formattedChartData.forEach((singleData) => {
        Object.keys(singleData).forEach((valueKey: any) => {
          if (valueKey !== "time" && !isNaN(singleData[valueKey])) {
            previousToCurrentTimeTotal =
              previousToCurrentTimeTotal + singleData[valueKey] ?? 0;
          }
        });
      });
      return previousToCurrentTimeTotal;
    } else {
      return 0;
    }
  }, [totalNFTVolumeAndSalesData, timeWindow]);

  const yAxisTitle =
    tradeActionWindow === TradeActionWindow.volume ? "NFT Volume" : "NFT Sales";
  return (
    <>
      <GraphTableTopHeader
        title={" Total NFT Volume, and Sales"}
        tooltip="NFT sales, and volume across all marketplaces"
        showBarGraph={showBarGraph}
        setShowBarGraph={setShowBarGraph}
        downloadScreenshot={downloadScreenshot}
        isMobileView={isMobile}
        snapshotBrandLogoId={snapshotBrandLogoId}
        snapshotDownloadIconId={snapshotDownloadIconId}
        tweetText={`https://twitter.com/intent/tweet?text=💜 Polygon NFT insights: NFT Volume%0A📆 for: ${timeWindow}%0A🎯 ${formatDollarAmount(
          125440000
        )}%0A📊 increased by: 20﹪ %0A%0A🔗Check out the detailed chart in Surfaceboard by @LayerEhq here: ${twitterShareScrollPath} `} />
      <HorizontalDivider />
      {
        showBarGraph ? (
          <ChartWrapper id="TotalNFTVolumeGraph" >
            <BarChart
              data={formattedChartData}
              series={formattedChartSeries}
              yAxisLabel={yAxisTitle}
              setValue={setVolumeHover}
              setLabel={setRightLabel}
              value={volumeHover}
              label={rightLabel}
              activeWindow={timeWindow}
              topRight={
                <TopRightComponent
                  timeWindow={timeWindow}
                  setTimeWindow={setTimeWindow}
                  tradeActionWindow={tradeActionWindow}
                  setTradeActionWindow={setTradeActionWindow}
                  showBarGraph={showBarGraph}
                  setShowBarGraph={setShowBarGraph}
                  handleChangeDropdownMenu={handleChangeDropdownMenu}
                  handleChangeDropdown={handleChangeDropdown}
                  isMobileView={isMobile}
                  downloadScreenshot={downloadScreenshot}
                  selectedCustomTime={selectedCustomTime}
                  onCalendarChange={onCalendarChange}
                />
              }
              topLeft={
                <TopLeftComponent
                  isMobileView={isMobile}
                  filteredTotalCount={filteredTotalCount}
                  selectedCurrency={currency}
                  handleChangeDropdownMenu={handleChangeDropdownMenu}
                  handleChangeDropdown={handleChangeDropdown}
                />
              }
              showLegend={true}
            />
          </ChartWrapper >
        ) : (
          <ChartWrapper id="TotalNFTVolumeGraph">
            <LineChart
              data={formattedChartData}
              series={formattedChartSeries}
              yAxisLabel={yAxisTitle}
              setValue={setVolumeHover}
              setLabel={setRightLabel}
              value={volumeHover}
              label={rightLabel}
              activeWindow={timeWindow}
              topRight={
                <TopRightComponent
                  timeWindow={timeWindow}
                  setTimeWindow={setTimeWindow}
                  tradeActionWindow={tradeActionWindow}
                  setTradeActionWindow={setTradeActionWindow}
                  showBarGraph={showBarGraph}
                  setShowBarGraph={setShowBarGraph}
                  handleChangeDropdownMenu={handleChangeDropdownMenu}
                  handleChangeDropdown={handleChangeDropdown}
                  isMobileView={isMobile}
                  downloadScreenshot={downloadScreenshot}
                  selectedCustomTime={selectedCustomTime}
                  onCalendarChange={onCalendarChange}
                />
              }
              topLeft={
                <TopLeftComponent
                  isMobileView={isMobile}
                  filteredTotalCount={filteredTotalCount}
                  selectedCurrency={currency}
                  handleChangeDropdownMenu={handleChangeDropdownMenu}
                  handleChangeDropdown={handleChangeDropdown}
                />
              }
              showLegend={true}
            />
          </ChartWrapper>
        )
      }
    </>
  );
}

export default TotalNFTVolumeGraph;

const defaultAvailableOptions: checkboxDropdownOptions[] = [
  {
    id: 1,
    name: "Ecosystem",
    icon: assets.icons.ecosystemIcon,
    selected: false,
    subOptions: [],
  },
  {
    id: 2,
    name: "Marketplaces",
    icon: assets.icons.marketplacesIcon,
    selected: true,
    subOptions: [
      {
        id: 1,
        name: "Opensea",
        value: "opensea",
        checked: true,
      },
      {
        id: 2,
        name: "Magic Eden",
        value: "magicEden",
        checked: true,
      },
      {
        id: 3,
        name: "One Planet",
        value: "onePlanet",
        checked: true,
      },
    ],
  },
];
