export const cardsData = [
    {
      title: "Top Wallets by Volume",
      endpoint: `${process.env.REACT_APP_API_URL1}/aggregations/tradeLeaderboard?sortBy=approx_profitability_usd`,
      query: "",
      toolTip: "Top Wallets by Volume",
      type: "profit",
    },
    {
      title: "Top Wallets by NFTs Sold",
      endpoint: `${process.env.REACT_APP_API_URL1}/aggregations/tradeLeaderboard?sortBy=count_sold&limit=25`,
      query: "",
      toolTip: "Top Wallets by NFTs Sold",
      type: "sold",
    },
    {
      title: "Top Wallets by NFTs Bought",
      endpoint: `${process.env.REACT_APP_API_URL1}/aggregations/tradeLeaderboard?sortBy=count_bought&limit=25`,
      toolTip: "Top Wallets by NFTs Bought",
      type: "bought",
    },
    {
      title: "Top Wallets by NFTs Held",
      endpoint: `${process.env.REACT_APP_API_URL1}/aggregations/tradeLeaderboard?sortBy=nfts_held_currently&limit=25`,
      toolTip: "Top Wallets by Amount Spent",
      type: "held",
    },
  ];