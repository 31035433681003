import { useQuery } from "@apollo/client";
import { nftMarketCap } from "apollo/client";
import gql from "graphql-tag";
import { useMemo } from "react";
import { notEmpty } from "utils";

export const QUERY_NFT_MARKET_CAP = gql`
  query records {
    records(
      filter: { category: { in: ["Brands", "Entertainment / Media", "Art"] } }
      orderBy: date_time
      orderDirection: desc
    ) {
      date_time
      category
      market_cap_usd
      market_cap_in_matic
      market_cap_in_weth
    }
  }
`;

interface NFTMarketCap {
  records: {
    date_time: string;
    category: string;
    market_cap_usd: number;
    market_cap_in_matic: number;
    market_cap_in_weth: number;
  }[];
}

/**
 * Fetch NFT Market Cap data
 */

export function useNFTMarketCap(): {
  loading: boolean;
  error: boolean;
  records:
    | {
        dateTime: string;
        category: string;
        marketCapUSD: number;
        marketCapInMatic: number;
        marketCapInWETH: number;
      }[]
    | undefined;
} {
  const { loading, error, data } = useQuery<NFTMarketCap>(
    QUERY_NFT_MARKET_CAP,
    {
      client: nftMarketCap,
      fetchPolicy: "cache-first",
    }
  );

  const formattedData = useMemo(() => {
    if (data) {
      return data.records
        .map((p) => {
          return {
            dateTime: p.date_time,
            category: p.category,
            marketCapUSD: p.market_cap_usd,
            marketCapInMatic: p.market_cap_in_matic,
            marketCapInWETH: p.market_cap_in_weth,
          };
        })
        .filter(notEmpty);
    } else {
      return undefined;
    }
  }, [data]);

  return {
    loading: loading,
    error: Boolean(error),
    records: formattedData,
  };
}
