import axios from "axios";
import { useMemo, useState } from "react";


interface PriceData {
    mean: number;
    median: number;
    current: number;
    change: number;
}

interface api {
    loading: boolean;
    error: boolean;
    data: PriceData;
}

const getPrices = async () => {
    try {
        const { data } = await axios.get(
            process.env.REACT_APP_API_URL + "/marketOverview/mean"
        )
        return data;
    }
    catch (error) {
        return error;
    }
}

export function useMaticPrices() {
    const [api, setAPIData] = useState<api>({
        loading: true,
        error: false,
        data: { mean: 0, median: 0, current: 0, change: 0 } || undefined
    });
    


    useMemo(async () => {
        const data = await getPrices();
        setAPIData({
            loading: false,
            error: false,
            data: data
        })
    }, [])
    return api;
}
