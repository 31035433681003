import styled from "styled-components";

export const StyledInput = styled.input`
  position: relative;
  display: flex;
  align-items: center;
  white-space: nowrap;
  background: none;
  padding: 10px;
  border: 0.85342px solid ${({ theme }) => theme.border};
  width: 100%;
  font-size: 16px;
  outline: none;
  color: ${({ theme }) => theme.body};

  ::placeholder {
    color: ${({ theme }) => theme.subBody};
    font-size: 16px;
    ::focus {
      padding: 10px;
    }
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 14px;
    ::placeholder {
    color: ${({ theme }) => theme.subBody};
    font-size: 12px;
    ::focus {
      padding: 10px;
    }
  }
  `};
`;

const TextField = ({ className, ...props }: any) => (
  <StyledInput className={className} {...props} />
);

export default TextField;
